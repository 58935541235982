
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import axios from "axios";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
// import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DescriptionIcon from "@material-ui/icons/Description";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// // core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import ButtonM from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
//*libreria para reproducir video
import ReactPlayer from "react-player";

import "./blink.css";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStylestable = makeStyles(styles2);
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminNavbarLinks() {
  const classes2 = useStylestable();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const history = useHistory();
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [dataPerfil, setdataPerfil] = React.useState({});
  const [contador, setContador] = React.useState("");
  const [openVideo, setOpenVideo] = React.useState(false);
  const [doBlink, setDoBlink] = React.useState(true);

  const [alertas, setAlertas] = React.useState([]);
  const [colorAlertas, setColorAlertas] = React.useState("secondary");
  const [colorCampana, setColorCampana] = React.useState("gray");
  const [openAlerta, setOpenAlerta] = React.useState(false);
  const [contenidoAlerta, setContenidoAlerta] = React.useState("");
  const [contadorLectura, setContadorLectura] = React.useState(0);
  const [openEliminar, setOpenEliminar] = React.useState(false);

  const [colorSemaforo, setColorSemaforo] = React.useState("success");
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  function getAlertas() {
    let token = sessionStorage.getItem("Token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/alertas`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise(function (resolve, reject) {
      axios(config).then(
        (res) => {
          var result = res.data;
          console.log("Processing Request");
          console.log("Alertas", res.data);
          //no secuencial
          let espera = [];
          const myObjects = res.data;
          for (var key in myObjects) {
            if (myObjects.hasOwnProperty(key)) {
              espera = [...espera, myObjects[key]];
            }
          }
          //fin no secuencial
          //contar no leidos
          let cont = 0;
          for (var i = 0; i < espera.length; i++) {
            if (espera[i].estatus === "1") {
              cont++;
            }
          }
          setContadorLectura(cont);
          //fin contar
          //ordenar
          espera.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return 1;
            }
            if (a.created_at > b.created_at) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          //fin ordenar
          setAlertas(espera);
          espera.length > 0
            ? setColorCampana("black")
            : setColorCampana("gray");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  React.useEffect(() => {
    getAlertas();
    let token = sessionStorage.getItem("Token");

    axios
      .get(`${process.env.REACT_APP_URL}api/auth/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("DATAUSER:", data);
        setContador(res.data.NumDocuments);
        const Roles = res.data.roles;
        // console.log("datauserAdmin", res.data.user);
        dispatch({
          type: "SET_USER",
          payload: res.data.user,
        });
        dispatch({
          type: "SET_PERMISSIONS",
          payload: res.data.permissions,
        });
        const custom = res.data.user.custom_assets;
        if (custom != null && custom.logo) {
          dispatch({
            type: "SET_LOGO",
            payload: custom.logo,
          });
        } else {
          dispatch({
            type: "SET_LOGO",
            payload: null,
          });
        }

        setdataPerfil(data.user);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleCloseclickprofile = () => {
    setOpenProfile(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    localStorage.removeItem("uri");
    setOpenProfile(null);
    localStorage.removeItem("Token");
    localStorage.removeItem("Video1");
    history.push("/");
  };

  const handleMiPerfil = () => {
    let uri = localStorage.getItem("uri");
    history.push(`/${uri}/user`);
  };

  const handleVideo = () => {
    setDoBlink(false);
    setOpenVideo(true);
  };

  function verAlerta(item) {
    setContenidoAlerta(item);
    setOpenAlerta(true);
    setOpenNotification(null);
    //marcar como leido
    if (item.estatus > 0) {
      let token = sessionStorage.getItem("Token");
      const config = {
        method: "put",
        url: `${process.env.REACT_APP_URL}api/alertas/${item._id}?estatus=0`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then((response) => {
          // Respuesta del servidor
          console.log("LEIDO", response.data);
          getAlertas();
        })
        .catch((error) => {
          // Error 😨
          console.log(error.response.data);
        });
    }
  }

  function AlertasList() {
    const todoAlertas = alertas.map((item, i) => (
      <React.Fragment>
        <hr />
        <MenuItem
          key={item._id}
          //onClick={handleCloseNotification}
          onClick={() => {
            verAlerta(item);
          }}
          className={classes.dropdownItem}
        >
          {item.estatus > 0 ? (
            <b>
              📧 {item.asunto}{" "}
              <font size="1">
                📅{moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}
              </font>{" "}
            </b>
          ) : (
            <React.Fragment>
              ✉️ {item.asunto}{" "}
              <font size="1">
                📅{moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}
              </font>
            </React.Fragment>
          )}
        </MenuItem>
      </React.Fragment>
    ));
    return (
      <React.Fragment>
        <IconButton
          aria-label="delete"
          onClick={() => {
            setOpenEliminar(true);
          }}
        >
          <Tooltip title={"Eliminar todas las alertas"} placement="top">
            <DeleteIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              fontSize="small"
              color="primary"
            />
          </Tooltip>
        </IconButton>
        <font size="1">
          Tiene <b>{alertas.length}</b>{" "}
          {alertas.length > 1 ? "alertas" : "alerta"} en el sistema
        </font>

        {todoAlertas}
      </React.Fragment>
    );
  }

  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search,
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search",
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div>{" "}
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div> */}

      {/* <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openProfile ? "profile-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleVideo}
        className={classes.buttonLink}
      >
        <Badge badgeContent={0} color="primary">
          {doBlink ? (
            <div class="blink">
              <Tooltip title="¡Nuevo proceso de carga!">
                <NewReleasesIcon
                  className={classes.icons}
                  style={{ color: "#fb8c00" }}
                />
              </Tooltip>
            </div>
          ) : (
            <Tooltip title="¡Nuevo proceso de carga!">
              <NewReleasesIcon
                className={classes.icons}
                style={{ color: "#fb8c00" }}
              />
            </Tooltip>
          )}
        </Badge>

        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Ayuda</p>
        </Hidden>
      </Button> */}

      <Button
        variant="contained"
        color={colorSemaforo}
        endIcon={<DescriptionIcon />}
        style={{
          marginRight: 10,
          maxWidth: "90px",
          maxHeight: "20px",
          minWidth: "30px",
          minHeight: "20px",
        }}
      >
        &nbsp;{contador ? contador.total : 0} / ∞
      </Button>

      <div className={classes.manager}>
        {/* CAMPANITA ALERTAS*/}
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
          style={{ color: colorCampana }}
        >
          <Badge badgeContent={contadorLectura} color={colorAlertas}>
            <NotificationsIcon className={classes.icons} />
          </Badge>

          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Mensajes</p>
          </Hidden>
        </Button>
        {dataPerfil.name}

        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Menu</p>
          </Hidden>
        </Button>

        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseclickprofile}>
                  <MenuList role="menu">
                    {/* <Divider light /> */}
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Cerrar sesión
                    </MenuItem>
                    <MenuItem
                      onClick={handleMiPerfil}
                      className={classes.dropdownItem}
                    >
                      Mi perfil
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>

        {/*NOTIFICACIONES*/}
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {alertas.length > 0 ? (
                      <AlertasList />
                    ) : (
                      <MenuItem
                        onClick={handleCloseNotification}
                        className={classes.dropdownItem}
                      >
                        No tiene ninguna alerta en el sistema
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      {/* dialogo para ver video de nuevo proceso*/}
      <div>
        <Dialog
          // fullWidth={true}
          maxWidth="md"
          open={openVideo}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"¡Conoce el nuevo proceso de carga!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <ReactPlayer
                style={{
                  marginTop: "-20px",
                }}
                url={require("assets/videos/nuevoProceso.mp4")}
                width="100%"
                controls
                playing={openVideo}
                volume="0.1"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonM color="secondary" onClick={() => setOpenVideo(false)}>
              Cerrar
            </ButtonM>
          </DialogActions>
        </Dialog>
      </div>
      {/* MODAL VER CONTENIDO DE LA ALERTA */}
      <Dialog
        // fullWidth={true}
        maxWidth="xs"
        open={openAlerta}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <p
                align="center"
                style={{ background: "#00acc1", color: "white" }}
              >
                <b>ALERTA DEL SISTEMA</b>
              </p>
              <b>Fecha:</b>{" "}
              {moment(contenidoAlerta.created_at).format("DD/MM/YYYY HH:mm:ss")}
              <br />
              <b>Asunto:</b> {contenidoAlerta.asunto}
              <hr />
              <div
                dangerouslySetInnerHTML={{ __html: contenidoAlerta.contenido }}
              />
              <br />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <ButtonM
            color="secondary"
            onClick={() => {
              let token = sessionStorage.getItem("Token");
              const config = {
                method: "delete",
                url: `${process.env.REACT_APP_URL}api/alertas/${contenidoAlerta._id}`,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                },
              };
              axios(config)
                .then((response) => {
                  // Respuesta del servidor
                  console.log("ELIMINADA", response.data);
                  getAlertas();
                })
                .catch((error) => {
                  // Error 😨
                  console.log(error.response.data);
                });
              setOpenAlerta(false);
              setContenidoAlerta("");
            }}
          >
            Eliminar
          </ButtonM>
          <ButtonM
            color="primary"
            onClick={() => {
              setOpenAlerta(false);
              setContenidoAlerta("");
            }}
          >
            Cerrar
          </ButtonM>
        </DialogActions>
      </Dialog>
      {/*FIN MODAL VER CONTENIDO ALERTA*/}

      {/*MODAL ELIMINAR ALERTAS*/}
      <Dialog
        // fullWidth={true}
        maxWidth="xs"
        open={openEliminar}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              ¿Está seguro de eliminar todas las alertas?
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <ButtonM
            color="secondary"
            onClick={() => {
              let token = sessionStorage.getItem("Token");
              const config = {
                method: "get",
                url: `${process.env.REACT_APP_URL}api/alertas/delete`,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                },
              };
              axios(config)
                .then((response) => {
                  // Respuesta del servidor
                  console.log("ELIMINADAS", response.data);
                  getAlertas();
                })
                .catch((error) => {
                  // Error 😨
                  console.log(error.response.data);
                });
              setOpenEliminar(false);
            }}
          >
            Eliminar
          </ButtonM>
          <ButtonM
            color="primary"
            onClick={() => {
              setOpenEliminar(false);
            }}
          >
            Cerrar
          </ButtonM>
        </DialogActions>
      </Dialog>
      {/*FIN MODAL ELIMINAR ALERTAS*/}
    </div>
  );
}
