/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import validator from "validator";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GestureIcon from "@material-ui/icons/Gesture";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublishIcon from "@material-ui/icons/Publish";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import pdflogo from "containers/NotificacionCertificada/pdflogo.png";
import { createWorker } from "tesseract.js";
import { loadModels, getFullFaceDescription } from "containers/FirmaURL/face";
import * as faceapi from "face-api.js";
import PaymentForm from "containers/Firma/Firma";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import stylesf from "containers/Firma/styles.module.css";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Hidden from "@material-ui/core/Hidden";
import SignaturePad from "react-signature-canvas";
// core components
import MaterialTable from "material-table";
import Snackbar1 from "components/Snackbar/Snackbar.js";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardContent from "@material-ui/core/CardContent";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import avatar from "assets/img/avataruser.png";
import visa from "assets/img/visa-logo.png";
import master from "assets/img/master-logo.png";
import american from "assets/img/american-logo.png";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
//options
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Backdrop from "@material-ui/core/Backdrop";
import ControlPanel from "components/controlPanel/ControlPanel";
import { Document, Page, Text } from "react-pdf";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//dialog
import DialogContentText from "@material-ui/core/DialogContentText";
//import from excel
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";
import formatofirmantes from "containers/Firma/formatofirmantes.xlsx";
import formatonotificados from "containers/Firma/formatonotificados.xlsx";
import "containers/Firma/stylesexcel.css";
//validacion
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropRotateIcon from "@material-ui/icons/CropRotate";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import Tooltip from "@material-ui/core/Tooltip";
import takepicture from "containers/FirmaURL/take-frente.jpg";
import takepicture1 from "containers/FirmaURL/take-reverso.jpg";
import scanpicture from "containers/FirmaURL/scan.gif";
import Corporaciones from "./Corporaciones.js";
import ClasesDocu from "./ClasesDocu";

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { DialogComp } from "components/Dialog/DialogComp";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
  Snackbar,
} from "@material-ui/core";
import "./UserProfile.css";
import { Alert } from "@material-ui/lab";
const useStylestable = makeStyles(styles2);

const regexRFC = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  marginPass: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  myRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.7),
      // width: '25ch',
    },
  },
  input: {
    display: "none",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  codigo: {
    marginTop: theme.spacing(-1),
  },
}));
//const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserProfile() {
  const cropperRef = useRef();
  const classes2 = useStylestable();
  const classes = useStyles();
  const { permissions } = useSelector((state) => state.auth);
  const { $loading } = useSelector((state) => state.app);
  const [ineFrente, setIneFrente] = useState("");
  const [loading, setLoading] = useState(true);
  const [openDialogUpdated, setopenDialogUpdated] = useState(false);
  const [openDialogPerfil, setopenDialogPerfil] = useState(false);
  const [openDialogPassword, setDialogPassword] = useState(false);
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [dataPerfil, setdataPerfil] = useState({});
  const [snackData, setDataSnack] = React.useState({
    vertical: "top",
    horizontal: "right",
    openSnack: false,
    snackMessage: "",
  });
  const { vertical, horizontal, openSnack, snackMessage } = snackData;
  const [dataUser, setDataUser] = useState({
    userCompany: "",
    userName: "",
    userPhone: "",
    userEmail: "",
    userId: "",
  });
  const { userCompany, userName, userPhone, userEmail, userId } = dataUser;
  const [dataPassword, setDataPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    openModalPass: false,
    passError: false,
  });

  const {
    newPassword,
    confirmPassword,
    oldPassword,
    showPassword1,
    showPassword2,
    showPassword3,
    openModalPass,
    passError,
  } = dataPassword;
  const [progress, setprogress] = useState(false);
  const [openMetodo, setOpenMetodo] = useState(false);
  const [agrupado, setAgrupado] = useState([{}]);
  const [clientes, setClientes] = useState([{}]);
  const [datos, setDatos] = useState(null);
  const [usuarios, setUsuarios] = useState("");
  const [modalDocumentos, setModalDocumentos] = useState(false);
  const [tablaDocumentos, setTablaDocumentos] = useState([{}]);
  const [modalUsuario, setModalUsuario] = useState(false);
  const [usuario, setUsuario] = useState(false);
  const [dataCompanias, setDataCompanias] = useState([]);
  const [dataGrupos, setDataGrupos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);

  // Metadata
  const [buttonPass, setButtonPass] = useState(true);
  const [buttonCustomMeta, setButtonCustom] = useState(true);
  const [modalCancelarMeta, setModalCancelarMeta] = useState(false);
  const [disabledNameMeta, setdisabledNameMeta] = useState(false);
  const [confirmEditname, setEditname] = useState(false);
  //notificados frecuentes
  const [agrupacionN, setAgrupacionN] = useState([]);
  const [notificadosFrecuentes, setNotificadosFrecuentes] = useState([]);
  const [notificado, setNotificado] = useState([]);
  const [modalNotificados, setModalNotificados] = useState(false);
  const [modalEliminarNotificado, setModalEliminarNotificado] = useState(false);
  const [modalEditarNotificado, setModalEditarNotificado] = useState(false);
  const [datosNotificado, setDatosNotificado] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    grupo: "DEFAULT",
  });
  const [datosModificarN, setDatosModificarN] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    grupo: "DEFAULT",
  });

  //importar registros
  const [modalLote, setmodalLote] = useState(false);
  const [modalLoteN, setmodalLoteN] = useState(false);
  const [initialData, setInitialData] = useState(undefined);
  const [currentSheet, setCurrentSheet] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setDataPassword({
        ...dataPassword,
        passError: true,
      });
    } else {
      setDataPassword({
        ...dataPassword,
        passError: false,
      });
    }
  }, [confirmPassword, newPassword]);

  useEffect(() => {
    if (confirmPassword && newPassword && oldPassword) {
      if (newPassword == confirmPassword) {
        setButtonPass(false);
      } else {
        setButtonPass(true);
      }
    } else {
      setButtonPass(true);
    }
  }, [confirmPassword, newPassword, oldPassword]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDataSnack({
      ...snackData,
      openSnack: false,
    });
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    //read excel file
    readFile(file)
      .then((readedData) => {
        setInitialData(readedData);
        //console.log("data:",readedData);
      })
      .catch((error) => console.error(error));
  };

  const handleClickShowPassword = (name, value) => {
    setDataPassword({
      ...dataPassword,
      [name]: !value,
    });
  };

  const handleOpenChangePassword = () => {
    setDataPassword({
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
      showPassword: false,
      openModalPass: true,
    });
  };

  const handleChangePassValues = (e) => {
    setDataPassword({
      ...dataPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handleCloseChangePassword = () => {
    setDataPassword({
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
      showPassword: false,
      openModalPass: false,
    });
  };
  const handleChangePassword = () => {
    setButtonPass(true);
    setprogress(true);
    const data = new FormData();
    data.append("password", newPassword);
    data.append("password_confirmation", confirmPassword);
    data.append("password_old", oldPassword);

    let token = sessionStorage.getItem("Token")
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/auth/user/${userId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        handleCloseChangePassword();
        setDataSnack({
          ...snackData,
          openSnack: true,
          snackMessage: "Contraseña actualizada!",
        });
        setprogress(false);
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response.data);
        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const saveFirmantes = () => {
    const result = generateObjects(currentSheet);
    console.log("Result Firmantes:", result);
    //save array of objects to backend
    for (var i = 0; i < result.length; i++) {
      var rol = result[i].rol;
      if (result[i].codigo_rol !== 5) {
        var rol = "N/A";
      }
      const data = new FormData();
      data.append("name", result[i].nombres);
      data.append("last_name", result[i].apellidos);
      data.append("email", result[i].email);
      if (result[i].telefono) {
        data.append("phone_country_code", result[i].codigo_pais);
        data.append("phone", result[i].telefono);
      }
      data.append("role", rol);
      data.append("firmante_involvements_id", result[i].codigo_rol);
      data.append("company", result[i].compañia);
      data.append("notification_types_id", result[i].notificacion);
      data.append("group", result[i].grupo.toUpperCase());

      let token = sessionStorage.getItem("Token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          let data = response.data;
          getFirmantes();
        })
        .catch((error) => {
          // Error 😨
          console.log(error.response.data);
          if (error.response.data.length > 1) {
            showNotificacion(error.response.data[0].message);
          } else {
            if (error.response.data.errors.email) {
              showNotificacion(error.response.data.errors.email);
            } else if (error.response.data.errors.phone) {
              showNotificacion(error.response.data.errors.phone);
            } else if (error.response.data.message) {
              showNotificacion(error.response.data.message);
            }
          }
        });
    }
    //fin save
    setInitialData(undefined);
    setmodalLote(false);
  };

  const saveNotificados = () => {
    const result = generateObjects(currentSheet);
    console.log("Result Notificados:", result);
    //save array of objects to backend
    for (var i = 0; i < result.length; i++) {
      const data = new FormData();
      data.append("name", result[i].nombres);
      data.append("last_name", result[i].apellidos);
      data.append("email", result[i].email);
      if (result[i].telefono) {
        data.append("phone_country_code", result[i].codigo_pais);
        data.append("phone", result[i].telefono);
      }
      data.append("notification_types_id", result[i].notificacion);
      data.append("group", result[i].grupo.toUpperCase());

      let token = sessionStorage.getItem("Token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          let data = response.data;
          getNotificados();
        })
        .catch((error) => {
          // Error 😨
          console.log(error.response.data);
          if (error.response.data.length > 1) {
            showNotificacion(error.response.data[0].message);
          } else {
            if (error.response.data.errors.email) {
              showNotificacion(error.response.data.errors.email);
            } else if (error.response.data.errors.phone) {
              showNotificacion(error.response.data.errors.phone);
            } else if (error.response.data.message) {
              showNotificacion(error.response.data.message);
            }
          }
        });
    }
    //fin save
    setInitialData(undefined);
    setmodalLoteN(false);
  };

  const handleEliminarFirma = () => {
    saveasset("firma", "");
    setGrafo("");
    setFirma("");
    setOpcionEliminar(0);
    setDialogoEliminar(false);
  };

  const handleDeleteIne = () => {
    saveasset("ine_front", "");
    saveasset("ine_back", "");
    setIneFrente("");
    setIneReverso("");
    setOpcionEliminar(0);
    setDialogoEliminar(false);
  };

  //firmantes frecuentes
  const [agrupacionF, setAgrupacionF] = useState([]);
  const [firmantesFrecuentes, setFirmantesFrecuentes] = useState([]);
  const [firmante, setFirmante] = useState([]);
  const [modalFirmantes, setModalFirmantes] = useState(false);
  const [modalEliminarFirmante, setModalEliminarFirmante] = useState(false);
  const [modalEditarFirmante, setModalEditarFirmante] = useState(false);
  const [textoValidacionMail, setTextoValidacionMail] = useState("");
  const [datosFirmante, setDatosFirmante] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "Por su Propio Derecho",
    Compañia: "",
    grupo: "DEFAULT",
    rfc: "",
  });
  const [datosModificar, setDatosModificar] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "Por su Propio Derecho",
    Compañia: "",
    grupo: "DEFAULT",
    rfc: "",
  });
  const [tipoActuacion, setTipoActuacion] = useState({
    codigo: 1,
    valor: "Por su Propio Derecho",
  });
  const [tiposActuacion, setTiposActuacion] = useState("");
  const [value, setValue] = useState("1");
  const [valueModificar, setValueModificar] = useState("1");

  const [loadingPerfil, setLoadingPerfil] = useState(true);
  const [curp, setCurp] = useState("");
  const [curpNumber, setCurpNumber] = useState("");
  const [loadingCurp, setLoadingCurp] = useState(false);
  const [messageCurp, setMessageCurp] = useState("");
  const [validaCurp, setValidaCurp] = useState(true);

  //TABS
  const [valueState, setValueState] = useState(0);
  const [headerColor, setHeaderColor] = useState("success");
  const handleChangeTabs = (event, value) => {
    setValueState(value);
  };

  function validarInput(input) {
    var curp = input.toUpperCase();
    if (curpValida(curp)) {
      setCurpNumber(curp);
      setValidaCurp(false);
      setMessageCurp("");
    } else {
      setValidaCurp(true);
      setMessageCurp("CURP no válido");
    }
  }

  function curpValida(curp) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)
      //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }
    if (validado[2] != digitoVerificador(validado[1])) return false;

    return true; //Validado
  }

  const [loadingIneFrente, setLoadingIneFrente] = useState(false);
  const [messageIneFrente, setMessageIneFrente] = useState("");

  const [dialogoINE, setDialogoINE] = useState(false);
  const [step, setStep] = useState(0);
  const [croppedFront, setCroppedFront] = useState("");
  const [reiniciar, setReiniciar] = useState(false);
  const [colorMessageFront, setcolorMessageFront] = useState("red");
  const [colorMessageBack, setcolorMessageBack] = useState("red");
  const [messageFront, setMessageFront] = useState("");
  const [messageBack, setMessageBack] = useState("");
  const [textoOCR, setTextoOCR] = useState("");

  const [ineReverso, setIneReverso] = useState("");
  const [loadingIneReverso, setLoadingIneReverso] = useState(false);
  const [messageIneReverso, setMessageIneReverso] = useState("");

  const [pasaporte, setPasaporte] = useState("");
  const [loadingPasaporte, setLoadingPasaporte] = useState(false);
  const [messagePasaporte, setMessagePasaporte] = useState("");

  const [dialogoPasaporte, setDialogoPasaporte] = useState(false);
  const [dialogoCURP, setDialogoCURP] = useState(false);

  const [grafo, setGrafo] = useState("");
  const [firma, setFirma] = useState("");
  const [dialogoFirma, setDialogoFirma] = useState(false);

  const [foto, setFoto] = useState("");
  const [dialogoFoto, setDialogoFoto] = useState(false);

  const [dialogoEliminar, setDialogoEliminar] = useState(false);
  const [opcionEliminar, setOpcionEliminar] = useState(0);

  const [openAlert, setOpenAlert] = useState("");
  const [messageError, setMessageError] = useState("");
  const [colorAlert, setColorAlert] = useState("warning");

  //pdf
  const [openDialogPDF, setOpenDialogPDF] = useState(false);
  const [base64, setbase64] = useState("");
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeigth, setpdfHeigth] = useState(575);
  const [pdfWidth, setpdfWidth] = useState(660);
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const handleclosepdf = () => {
    setOpenDialogPDF(false);
    setbase64("");
    setScale(1.0);
  };
  //fin pdf

  function saveasset(asset, base64) {
    let token = sessionStorage.getItem("Token");
    const URL = `${process.env.REACT_APP_URL}api/customassets`;
    var FormData = require("form-data");
    var data = new FormData();
    data.append(asset, base64);
    var config = {
      method: "post",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
      data: data,
    };
    axios(config)
      .then(function ({ data }) {
        switch (asset) {
          case "firma": {
            dispatch({
              type: "SET_SIGN",
              payload: data.firma,
            });
            clear();
            break;
          }
          case "ine_front": {
            dispatch({
              type: "SET_INE_FRONT",
              payload: data.ine_front,
            });
            break;
          }
          case "ine_back": {
            dispatch({
              type: "SET_INE_BACK",
              payload: data.ine_back,
            });
            break;
          }
          case "pasaporte": {
            dispatch({
              type: "SET_PASSPORT",
              payload: data.pasaporte,
            });
            break;
          }
          default:
            break;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const clear = () => {
    firma.clear();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 10000);
  };

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handleGuardaFirma = () => {
    let re = firma.getTrimmedCanvas().toDataURL("image/png");
    let firma64 = re.split("data:image/png;base64,", 3);
    let base64 = firma64[1];
    if (base64.length < 2000) {
      alert(
        "El tamaño de tu firma es muy pequeño, por favor vuelve a intentarlo"
      );
      return false;
    }
    setGrafo(re);
    setDialogoFirma(false);
    //save grafo firma
    saveasset("firma", re);
    //grafo firma saved
  };

  function getUsuarios() {
    getdocumentos();
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;

    //USUARIOS
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/roles/group`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data;

        let companias = [];
        Object.keys(data).forEach(function (n, i) {
          var v = data[n];
          companias = [...companias, { compania: n, grupos: v }];
        });
        setDataCompanias(companias);

        let grupos = [];
        for (var i = 0; i < companias.length; i++) {
          for (var j = 0; j < companias[i].grupos.groups.length; j++) {
            grupos = [...grupos, companias[i].grupos.groups[j]];
          }
        }
        setDataGrupos(grupos);

        let users = [];
        let objeto = [];
        for (var i = 0; i < grupos.length; i++) {
          for (var j = 0; j < grupos[i].users.length; j++) {
            if (grupos[i].users[j].group_id === grupos[i].id) {
              /*
              objeto[j] = {
                name:grupos[i].users[j].name,
                email:grupos[i].users[j].email,
                phone:grupos[i].users[j].phone,
                //grupo:getgrupo(grupos[i].users[j].group_id),
                grupo:grupos[i].users[j].group_id,
                group_id:grupos[i].users[j].group_id,
                numero:numero(grupos[i].users[j].id)
              }
              */
              //users = [...users,objeto]
              users = [...users, grupos[i].users[j]];
            }
          }
        }
        //console.log("objeto",objeto);
        setUsuarios(users);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN USUARIOS
  }

  function getgrupo(id) {
    const resultado = dataGrupos.find((grupo) => grupo.id === id);
    return resultado.name;
  }

  function getdocumentos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data;
        // console.log("Documentos:", data);
        setDocumentos(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function numero(id) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return documento.uid === id;
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return documento.uid === id;
      });
    }

    return num.length;
  }

  function estatus(id, estatus) {
    var num;
    if (documentosFiltrados.length === 0) {
      num = documentos.filter(function (documento) {
        return documento.uid === id && documento.status.id === estatus;
      });
    } else {
      num = documentosFiltrados.filter(function (documento) {
        return documento.uid === id && documento.status.id === estatus;
      });
    }

    return num.length;
  }

  //validación ID

  const handleFileReadPasaporte = async (event) => {
    const file = event.target.files[0];
    console.log("File PASAPORTE!!!");
    const base64 = await convertBase64(file);
    setPasaporte(base64);
    setStep(1);
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function getOCR(archivo) {
    //  console.log("archivo",archivo)
    let urlDocumento = `${process.env.REACT_APP_URL}api/ocrid`;
    const tokenCC = sessionStorage.getItem("Token");
    //alert(archivo);
    var data = new FormData();
    data.append("image", archivo);

    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log(error);
          alert(error);
        }
      );
    });
  }

  const handleValidarFront = async () => {
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);

    setMessageFront(
      "Por favor espere, este proceso puede tardar varios minutos."
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    setStep(2);
    await loadModels();
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Foto en validar front:", fullDesc);
    if (fullDesc) {
      var bl = dataURLtoBlob(url);
      var textresult = await getOCR(bl);
      var validacion = textoFrenteINE(textresult.toUpperCase());
      console.log(validacion, "VALIDACION RESULT");
      if (validacion === false) {
        setMessageFront("Credencial para votar inválida, por favor carga nuevamente");
        setcolorMessageFront("red");
        setIneFrente("");
        setReiniciar(true);
      } else {
        setMessageFront("Credencial para votar aceptada");
        setcolorMessageFront("blue");
        setIneFrente(lastCropped);
        saveasset("ine_front", lastCropped);
        setStep(3);
      }
    } else {
      setMessageFront(
        "Credencial para votar inválida, no se reconoce el rostro en la identificación, por favor cargar nuevamente"
      );
      setcolorMessageFront("red");
      setIneFrente("");
      setReiniciar(true);
    }
  };
  function handleRotate() {
    cropperRef.current.cropper.rotate(90);
    //this.handleCropChange();
  }

  const handleValidarBack = async () => {
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);

    setMessageBack(
      "Por favor espere, este proceso puede tardar varios minutos."
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    setStep(5);

    var bl = dataURLtoBlob(url);
    var textresult = await getOCR(bl);
    var validacion = textoReverso(textresult.toUpperCase());
    if (validacion === false) {
      setMessageBack("ID inválido, por favor cargar nuevamente");
      setcolorMessageBack("red");

      setIneReverso("");

      setReiniciar(true);
    } else {
      setMessageBack("ID aceptado");
      setcolorMessageBack("blue");
      setIneReverso(lastCropped);
      saveasset("ine_back", lastCropped);
      setDialogoINE(false);
      setStep(0);
    }

    console.log("FIN RECONOCIMIENTO");
  };

  function textoFrentePasaporte(texto) {
    console.log("texto frente PASAPORTE");
    /*
  if(isMobile){
    console.log("MOBILE");
    return true;
  }
*/
    var str = texto;
    var valor = 0;

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK");
      valor++;
    }

    if (str.search(dataPerfil.last_name.toUpperCase().split(" ")[1]) !== -1) {
      console.log("LAST OK");
      valor++;
    }

    if (str.search("PASSPORT") !== -1 || str.search("PASAPORTE") !== -1) {
      console.log("pasaporte ok");
      valor++;
    }
    console.log("valor PASAPORTE:", valor);
    if (valor > 0) {
      return true;
    }
    return false;
  }

  function textoReverso(texto) {
    /*
  if(isMobile){
    return true;
  }
  */
    var str = texto;
    var valor = 0;
    //es INE/IFE REVERSO

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK");
      valor++;
    }

    if (str.search(dataPerfil.last_name.toUpperCase().split(" ")[1]) !== -1) {
      console.log("LAST OK");
      valor++;
    }
    if (
      str.search("MEX") !== -1 ||
      str.search("INSTITUTO") !== -1 ||
      str.search("NACIONAL") !== -1 ||
      str.search("ELECTORAL") !== -1 ||
      str.search("FEDERAL") !== -1 ||
      str.search("ELECTOR") !== -1 ||
      str.search("CLAVE") !== -1
    ) {
      valor++;
    }

    if (valor > 0) {
      return true;
    }
    return false;
  }

  const handleFileFotoPerfil = async (event) => {
    const file = event.target.files[0];
    //console.log("File FRONT")
    const base64 = await convertBase64(file);
    setFotoPerfil(base64);
  };

  const handleFileReadFront = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setIneFrente(base64);
    setStep(1);
  };

  const handleFileReadBack = async (event) => {
    const file = event.target.files[0];
    console.log("File BACK");
    const base64 = await convertBase64(file);
    setIneReverso(base64);
    setStep(4);
  };

  const handleValidarPasaporte = async () => {
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);
    setMessageFront(
      "Por favor espere, este proceso puede tardar varios minutos."
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    setStep(2);
    console.log("File PASAPORTE");
    await loadModels();
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Foto en validar pasaporte:", fullDesc);
    if (fullDesc) {
      var bl = dataURLtoBlob(url);
      var textresult = await getOCR(bl);
      var validacion = textoFrentePasaporte(textresult.toUpperCase());
      console.log("validacion", validacion);
      if (validacion === false) {
        setMessageFront("Pasaporte inválido, por favor cargar nuevamente");
        setcolorMessageFront("red");
        setPasaporte("");
        setReiniciar(true);
      } else {
        setMessageFront("Pasaporte aceptado");
        setcolorMessageFront("blue");
        setPasaporte(lastCropped);
        saveasset("pasaporte", lastCropped);
        setDialogoPasaporte(false);
        setStep(0);
      }
    } else {
      setMessageFront("Pasaporte inválido, por favor cargar nuevamente");
      setcolorMessageFront("red");
      setPasaporte("");
      setReiniciar(true);
    }
  };

  //fin validacion ID

  function getFirmantes() {
    console.log("getfirmantes");
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantesfrecuentes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        console.log("FIRMANTES:", data);
        setFirmantesFrecuentes(data);
        //obtener grupos para autoComplete
        const unique = [...new Set(data.map((item) => item.group))];
        console.log("Agrupar Firmantes:", unique);
        setAgrupacionF(unique);
      })
      .catch((error) => {
        console.error(error);
        setFirmantesFrecuentes([]);
      });
  }

  function getNotificados() {
    console.log("getnotificados");
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/notificadosfrecuentes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        console.log("NOTIFICADOS:", data);
        setNotificadosFrecuentes(data);
        //obtener grupos para autoComplete
        const unique = [...new Set(data.map((item) => item.group))];
        console.log("Agrupar Notificados:", unique);
        setAgrupacionN(unique);
      })
      .catch((error) => {
        console.error(error);
        setNotificadosFrecuentes([]);
      });
  }

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleInput = (event) => {
    setDatosFirmante({
      ...datosFirmante,
      [event.target.name]: event.target.value,
    });
    console.log("datos", datosFirmante);
  };

  const handleInputN = (event) => {
    setDatosNotificado({
      ...datosNotificado,
      [event.target.name]: event.target.value,
    });
    console.log("datos Notificado", datosNotificado);
  };
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }

  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleInputModificar = (event) => {
    if (event.target.name == "celular") {
      let resultado = removerCaracteres(event);
      setDatosModificar({
        ...datosModificar,
        [event.target.name]: !resultado ? "" : resultado,
      });
      return false;
    }
    setDatosModificar({
      ...datosModificar,
      [event.target.name]: event.target.value,
    });
    // console.log("datos modificar", datosModificar);
  };

  const handleInputModificarN = (event) => {
    setDatosModificarN({
      ...datosModificarN,
      [event.target.name]: event.target.value,
    });
    console.log("datos modificar Notif", datosModificarN);
  };

  function getTiposActuacion() {
    const token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/involvement/types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("Tipos:", response.data);
        setTiposActuacion(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function consultaTipoActuacion(elemento) {
    if (elemento) {
      var t = [];
      Object.keys(tiposActuacion).forEach(function (n, i) {
        t[i] = {
          codigo: n,
          valor: tiposActuacion[n],
        };
      });
      const tipo = t.find((ta) => ta.codigo === elemento.toString());
      if (tipo) {
        return tipo.valor;
      }
    }
  }

  const handleChangeTipoActuacion = (event) => {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: Number(n),
        valor: tiposActuacion[n],
      };
    });
    const tipo = t.find((ta) => ta.codigo === event.target.value);
    setTipoActuacion(tipo);
  };

  function SelectTipos() {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: Number(n),
        valor: tiposActuacion[n],
      };
    });
    // console.log("Tipos", t);
    return (
      <React.Fragment>
        <InputLabel id="demo-simple-select-label">Tipo de Actuación</InputLabel>
        <Select
          labelId="tipoactuacion"
          id="tipoactuacion"
          value={tipoActuacion.codigo}
          onChange={handleChangeTipoActuacion}
        >
          {t.map(({ codigo, valor }, index) => (
            <MenuItem key={index} value={codigo}>
              {valor}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }
  const handleChange = async (event) => {
    await setValue(event.target.value);
    // console.log("VALUE:", value);
  };

  const registerNewFirmante = async () => {
    var rol = datosFirmante.rol;
    if (tipoActuacion.codigo !== 5) {
      rol = tipoActuacion.valor;
    }

    const data = new FormData();
    data.append("name", datosFirmante.nombre);
    data.append("last_name", datosFirmante.apellidos);
    data.append("email", datosFirmante.correo);
    if (datosFirmante.celular) {
      data.append("phone_country_code", datosFirmante.codigo);
      data.append("phone", datosFirmante.celular);
    }
    data.append("role", rol);
    data.append("firmante_involvements_id", tipoActuacion.codigo);
    data.append("company", datosFirmante.Compañia);
    data.append("notification_types_id", value);
    data.append("group", datosFirmante.grupo.toUpperCase());
    if (datosFirmante.rfc) {
      data.append("rfc", datosFirmante.rfc);
    }

    let token = sessionStorage.getItem("Token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        //let data = response.data[1];
        let data = response.data;
        setModalFirmantes(false);
        getFirmantes();
        setDatosFirmante({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
          grupo: "DEFAULT",
          rfc: "",
        });
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response.data);
        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const registerNewNotificado = async () => {
    const data = new FormData();
    data.append("name", datosNotificado.nombre);
    data.append("last_name", datosNotificado.apellidos);
    data.append("email", datosNotificado.correo);
    if (datosNotificado.celular) {
      data.append("phone_country_code", datosNotificado.codigo);
      data.append("phone", datosNotificado.celular);
    }
    data.append("notification_types_id", value);
    data.append("group", datosNotificado.grupo.toUpperCase());

    let token = sessionStorage.getItem("Token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        //let data = response.data[1];
        let data = response.data;
        setModalNotificados(false);
        getNotificados();
        setDatosNotificado({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          grupo: "DEFAULT",
        });
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response.data);
        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const editFirmante = async () => {
    var rol = datosModificar.rol;
    if (tipoActuacion.codigo !== 5) {
      rol = tipoActuacion.valor;
    }

    var url =
      process.env.REACT_APP_URL +
      "api/firmantesfrecuentes/" +
      firmante._id +
      "?name=" +
      datosModificar.nombre +
      "&last_name=" +
      datosModificar.apellidos +
      "&email=" +
      datosModificar.correo +
      "&role=" +
      rol +
      "&firmante_involvements_id=" +
      tipoActuacion.codigo +
      "&company=" +
      datosModificar.Compañia +
      "&notification_types_id=" +
      valueModificar +
      "&group=" +
      datosModificar.grupo.toUpperCase() +
      "&phone_country_code=" +
      datosModificar.codigo +
      "&phone=" +
      datosModificar.celular;
    if (datosModificar.rfc) {
      url = url + "&rfc" + datosModificar.rfc.toUpperCase();
    }

    /*
    var url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${
      firmante._id
    }?name=${datosModificar.nombre}&last_name=${
      datosModificar.apellidos
    }&email=${datosModificar.correo}&phone_country_code=${
      datosModificar.codigo
    }&phone=${datosModificar.celular}&role=${rol}&firmante_involvements_id=${
      tipoActuacion.codigo
    }&company=${
      datosModificar.Compañia
    }&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}`;

    if (datosModificar.rfc){
      var url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${
        firmante._id
      }?name=${datosModificar.nombre}&last_name=${
        datosModificar.apellidos
      }&email=${datosModificar.correo}&phone_country_code=${
        datosModificar.codigo
      }&phone=${datosModificar.celular}&role=${rol}&firmante_involvements_id=${
        tipoActuacion.codigo
      }&company=${
        datosModificar.Compañia
      }&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}&rfc=${datosModificar.rfc.toUpperCase()}`;
    }
    */
    /*
  var url="";
  if(valueModificar===2) {
    url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}?name=${datosModificar.nombre}&last_name=${datosModificar.apellidos}&email=${datosModificar.correo}&phone_country_code=${datosModificar.codigo}&phone=${datosModificar.celular}&role=${rol}&firmante_involvements_id=${tipoActuacion.codigo}&company=${datosModificar.Compañia}&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}`
  }
  else{
    url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}?name=${datosModificar.nombre}&last_name=${datosModificar.apellidos}&email=${datosModificar.correo}&role=${rol}&firmante_involvements_id=${tipoActuacion.codigo}&company=${datosModificar.Compañia}&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}`
  }
*/
    // console.log(datosModificar, "datosModificarOlv");
    var datajs;
    if (datosModificar.celular && datosModificar.celular.length === 10) {
      datajs = JSON.stringify({
        name: datosModificar.nombre,
        last_name: datosModificar.apellidos,
        email: datosModificar.correo,
        phone_country_code: datosModificar.codigo,
        phone: datosModificar.celular,
        role: rol,
        firmante_involvements_id: tipoActuacion.codigo,
        company: datosModificar.Compañia,
        notification_types_id: valueModificar,
        group: datosModificar.grupo.toUpperCase(),
        rfc: datosModificar.rfc?.toUpperCase(),
      });
    } else if (
      datosModificar.celular == undefined ||
      datosModificar.celular.length == 0
    ) {
      datajs = JSON.stringify({
        name: datosModificar.nombre,
        last_name: datosModificar.apellidos,
        email: datosModificar.correo,
        phone_country_code: null,
        phone: null,
        role: rol,
        firmante_involvements_id: tipoActuacion.codigo,
        company: datosModificar.Compañia,
        notification_types_id: valueModificar,
        group: datosModificar.grupo.toUpperCase(),
        rfc: datosModificar.rfc?.toUpperCase(),
      });
    }

    let token = sessionStorage.getItem("Token");
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };
    /*
    const config = {
      method: "put",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    */

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        let data = response.data;
        setModalEditarFirmante(false);
        getFirmantes();
        setDatosModificar({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
          grupo: "DEFAULT",
          rfc: "",
        });
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response.data);
        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const editNotificado = async () => {
    let token = sessionStorage.getItem("Token");

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes/${notificado._id
        }?name=${datosModificarN.nombre}&last_name=${datosModificarN.apellidos
        }&email=${datosModificarN.correo}&phone_country_code=${datosModificarN.codigo
        }&phone=${datosModificarN.celular
        }&notification_types_id=${valueModificar}&group=${datosModificarN.grupo.toUpperCase()}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        let data = response.data;
        setModalEditarNotificado(false);
        getNotificados();
        setDatosModificarN({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          grupo: "DEFAULT",
        });
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response.data);
        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const [customMeta, setCustomMeta] = React.useState("");
  const [selectCustomMeta, setSelectCustomMeta] = React.useState("");
  const [dialogoEliminarCustomMeta, setDialogoEliminarCustomMeta] = useState(
    false
  );
  const [dialogoAgregarCustomMeta, setDialogoAgregarCustomMeta] = useState(
    false
  );
  const [dialogoModificarCustomMeta, setDialogoModificarCustomMeta] = useState(
    false
  );
  const [nombreMetadato, setNombreMetadato] = React.useState("");
  const [fieldsMetadato, setFieldsMetadato] = React.useState([]);
  const [keyMetadato, setKeyMetadato] = React.useState("");
  const [valueMetadato, setValueMetadato] = React.useState("");

  useEffect(() => {
    if (confirmEditname) {
      setdisabledNameMeta(false);
    }
    if (nombreMetadato && fieldsMetadato.length > 0) {
      setButtonCustom(false);
    } else {
      setButtonCustom(true);
    }
  }, [fieldsMetadato, nombreMetadato, confirmEditname]);

  function handleModificarCustomMeta() {
    const data = new FormData();
    data.append("name", nombreMetadato);
    for (var i = 0; i < fieldsMetadato.length; i++) {
      data.append(
        "fields[" + fieldsMetadato[i].key + "]",
        fieldsMetadato[i].value
      );
    }

    let url = `${process.env.REACT_APP_URL}api/custommeta/${customMeta._id}`;
    //let token = `${process.env.REACT_APP_TOKEN}`;
    let token = sessionStorage.getItem("Token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log(response.data);
        getCustomMeta();
        setDialogoModificarCustomMeta(false);
        setNombreMetadato("");
        setFieldsMetadato([]);
      })
      .catch((error) => {
        // Error 😨
        console.log(error);
      });
  }

  const handleCancelConfirm = () => {
    setModalCancelarMeta(true);
    setDialogoAgregarCustomMeta(false);
  };

  const handleCancelarMetadata = () => {
    setDialogoAgregarCustomMeta(false);
    setNombreMetadato("");
    setFieldsMetadato([]);
    setModalCancelarMeta(false);
  };

  function handleGuardarCustomMeta() {
    const data = new FormData();
    data.append("name", nombreMetadato);
    for (var i = 0; i < fieldsMetadato.length; i++) {
      data.append(
        "fields[" + fieldsMetadato[i].key + "]",
        fieldsMetadato[i].value
      );
    }
    let url = `${process.env.REACT_APP_URL}api/custommeta`;
    //let token = `${process.env.REACT_APP_TOKEN}`;
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };
    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log(response.data);
        getCustomMeta();
        setDialogoAgregarCustomMeta(false);
        setNombreMetadato("");
        setFieldsMetadato([]);
      })
      .catch((error) => {
        // Error 😨
        console.log(error);
      });
  }

  function handleEliminarCustomMeta(id) {
    let token = sessionStorage.getItem("Token");
    // let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/custommeta/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Eliminar:", response.data);
        getCustomMeta();
        setDialogoEliminarCustomMeta(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCustomMeta() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/custommeta`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Metadatos:", response.data);
        setSelectCustomMeta(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setSelectCustomMeta("");
      });
  }

  useEffect(() => {
    getCustomMeta();
    getUsuarios();
    getFirmantes();
    getNotificados();
    getTiposActuacion();
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/auth/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        console.log("PERFIL AUTH", data.user);
        setdataPerfil(data.user);
        setDataUser({
          userCompany: data.user.company,
          userName: data.user.name,
          userPhone: data.user.phone,
          userEmail: data.user.email,
          userId: data.user.id,
        });

        setLoadingPerfil(false);

        if (data.user.my_assets) {
          setCurpNumber(data.user.my_assets.curp);
          setIneFrente(data.user.my_assets.ine_front);
          setIneReverso(data.user.my_assets.ine_back);
          setPasaporte(data.user.my_assets.pasaporte);
          setGrafo(data.user.my_assets.firma);
        }

        /*
        (data.user.hasOwnProperty('my_assets')) ? setCurp(data.user.my_assets.curp):setCurp("");
        (data.user.hasOwnProperty('my_assets')) ? setIneFrente(data.user.my_assets.ine_front):setIneFrente("");
        (data.user.hasOwnProperty('my_assets')) ? setIneReverso(data.user.my_assets.ine_back):setIneReverso("");
        (data.user.hasOwnProperty('my_assets')) ? setPasaporte(data.user.my_assets.pasaporte):setPasaporte("");
        (data.user.hasOwnProperty('my_assets')) ? setGrafo(data.user.my_assets.firma):setGrafo("");
        */
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCloseUpdated = () => {
    window.location.reload();
    //setopenDialogUpdated(false);
  };

  const handleCloseDialog = () => {
    setopenDialogPerfil(false);
  };

  // const handleClosePassword = () => {
  //   setDialogPassword(false);
  // };

  // const onchangePassword = (event) => {
  //   setPassword(event.target.value);
  // };

  // const onchangePasswordConfirm = (event) => {
  //   setConfirmPassword(event.target.value);
  // };

  const handleChangeValues = (event) => {
    setDataUser({
      ...dataUser,
      [event.target.name]: event.target.value,
    });
  };

  // const handleCambiarContraseña = () => {
  //   let token = sessionStorage.getItem("Token");
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: " application/json",
  //     },
  //   };

  //   const data = {
  //     password,
  //   };

  //   const URL = `${process.env.REACT_APP_URL}api/firmantes/${idUser}`;
  //   axios
  //     .put(URL, data, config)
  //     .then((res) => {
  //       const data = res.data;
  //       console.log("PERFIL", data);
  //       setdataPerfil(data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const HandleUpdateUser = () => {
    const idUser = userId;
    let token = sessionStorage.getItem("Token");

    var data = {};

    if (userCompany !== "" && userName !== +"" && userPhone !== "") {
      data = {
        company: userCompany,
        name: userName,
        phone: userPhone,
      };
    } else if (userCompany !== "" && userName !== "") {
      data = {
        company: userCompany,
        name: userName,
      };
    } else if (userCompany !== "" && userPhone) {
      data = {
        company: userCompany,
        phone: userPhone,
      };
    } else if (userName !== "" && userPhone !== "") {
      data = {
        name: userName,
        phone: userPhone,
      };
    } else if (userCompany !== "") {
      data = {
        company: userCompany,
      };
    } else if (userName !== "") {
      data = {
        name: userName,
      };
    } else if (userPhone !== "") {
      data = {
        phone: userPhone,
      };
    } else {
      data = {};
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };

    const URL = `${process.env.REACT_APP_URL}api/auth/user/${idUser}`;
    axios
      .put(URL, data, config)
      .then((res) => {
        const data = res.data;
        console.log("PERFIL MODIF.", data);
        setdataPerfil(data);
        setopenDialogUpdated(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOpenDialog = () => {
    setopenDialogPerfil(true);
  };

  const handlePassword = () => {
    setDialogPassword(true);
  };

  // const alertVerifyPassword =
  //   confirmPassword == password ? "" : "Las contraseñas no coinciden.";

  const handleFileReadCurp = async (e) => {
    setLoadingCurp(true);
    setMessageCurp("");
    setCurp("");

    const file = e.target.files[0];
    //validar curp
    /*
      showNotificacion(
        "Validando CURP, por favor espere un momento."
      );
      */

    //fin validar
    console.log("CURP:", file);
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setCurp(newImages[0].base64);
      //savecurp
      saveasset("curp", newImages[0].base64);
      //curp saved
    }
    setLoadingCurp(false);
  };

  const eliminarDiacriticos = (texto) => {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  function textoFrenteINE(texto) {
    var str = eliminarDiacriticos(texto);
    var valor = 0;

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK", str.search(dataPerfil.name.split(" ")[0]));
      valor++;
    }

    if (str.search(dataPerfil.last_name.toUpperCase().split(" ")[1]) !== -1) {
      console.log(
        "LASTNAME OK",
        str.search(dataPerfil.last_name.split(" ")[1])
      );
      valor++;
    }
    if (valor > 0) {
      return true;
    }

    if (
      str.search("MÉXICO") !== -1 ||
      str.search("INSTITUTO") !== -1 ||
      str.search("NACIONAL") !== -1 ||
      str.search("ELECTORAL") !== -1 ||
      str.search("FEDERAL") !== -1 ||
      str.search("ELECTOR") !== -1 ||
      str.search("CLAVE") !== -1
    ) {
      console.log("mexico ok", str.search("MÉXICO"));
      valor++;
    }

    console.log("valor:", valor);
    if (valor > 0) {
      return true;
    }

    return false;
  }

  const validarFrontINE = (file) => {
    //reconocimiento
    var confidence, text;
    var Tesseract = window.Tesseract;
    const url = URL.createObjectURL(file);
    // console.log(url);
    Tesseract.recognize(url, {
      lang: "spa",
    })
      .catch((err) => {
        console.error(err);
      })
      .then((result) => {
        // Get Confidence score
        confidence = result.confidence;
        console.log("Confiabilidad:", confidence);

        // Get full output
        text = result.text;
        console.log("Texto:", text);
        //setTextoOCR(text);

        // Get codes
        let pattern = /\b\w{10,10}\b/g;
        let patterns = result.text.match(pattern);
        console.log("Patrón:", patterns);

        //validación
        var id;
        var adicional = "";
        if (confidence < 50) {
          console.log("menor 50");
          setIneFrente("");
          setMessageIneFrente("Credencial para votar inválida");
          saveasset("ine_front", "");
          /*
                   setMessageFront(id+" inválido, por favor cargar nuevamente");
                   setcolorMessageFront("red")
                   props.setFrente("")
                   setFront("")
                   */
        } else {
          console.log("mayor 50");
          var validacion = textoFrenteINE(text);
          console.log("validacion", validacion);
          if (validacion === false) {
            setIneFrente("");
            setMessageIneFrente("Credencial para votar inválida");
            saveasset("ine_front", "");
          }
          /*
                   var validacion = textoFrente(text);
                   console.log("validacion",validacion);
                   if (validacion===false) {
                     setMessageFront(id+" inválido, por favor cargar nuevamente");
                     setcolorMessageFront("red")
                     props.setFrente("")
                     setFront("")
                     props.setValidacionId(false);
                   }
                   else{
                     setMessageFront(id+" aceptado"+adicional);
                     setcolorMessageFront("blue")
                     props.setValidacionId(true);
                   }
                   */
        }

        URL.revokeObjectURL(url);
        //fin validacion
        setLoadingIneFrente(false);
      });

    console.log("FIN RECONOCIMIENTO");

    //fin reconocimiento
  };

  const handleFileReadINE_validado = async (e) => {
    setLoadingIneFrente(true);
    setMessageIneFrente("");
    setIneFrente("");
    const file = e.target.files[0];

    //validar INE
    showNotificacion("Validando credencial para votar, por favor espere un momento.");

    //image detection
    await loadModels();
    const url = URL.createObjectURL(file);
    //await handleImage(url);
    //function
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Foto:", fullDesc);
    URL.revokeObjectURL(url);
    //fin image
    //fin validar
    if (fullDesc) {
      console.log("INE/IFE:", file);
      validarFrontINE(file);

      const newImagesPromises = [];
      newImagesPromises.push(fileToDataUri(file));
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setIneFrente(newImages[0].base64);
      //saveINEFrente
      saveasset("ine_front", newImages[0].base64);
      //ine frente saved
    } else {
      setMessageIneFrente("Credencial para votar inválida");
      setLoadingIneFrente(false);
    }
  };

  const handleFileReadINE = async (e) => {
    setLoadingIneFrente(true);
    setMessageIneFrente("");
    setIneFrente("");
    const file = e.target.files[0];

    //validar INE
    showNotificacion("Guardando credencial para votar, por favor espere un momento.");

    const newImagesPromises = [];
    newImagesPromises.push(fileToDataUri(file));
    const newImages = await Promise.all(newImagesPromises);
    console.log(newImages);
    setIneFrente(newImages[0].base64);
    //saveINEFrente
    saveasset("ine_front", newImages[0].base64);
    //ine frente saved
    setLoadingIneFrente(false);
  };

  function textoReversoINE(texto) {
    var str = texto;
    var valor = 0;
    //es INE/IFE REVERSO
    if (str.search("IDMEX") !== -1) {
      valor++;
      console.log("mexico ok", str.search("IDMEX"));
    }

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK");
      valor++;
    }

    if (str.search(dataPerfil.last_name.toUpperCase().split(" ")[1]) !== -1) {
      console.log("LASTNAME OK");
      valor++;
    }

    console.log("valor INE/IFE BACK:", valor);

    if (valor > 1) {
      return true;
    }
    return false;
  }

  const validarBackINE = (file) => {
    //reconocimiento
    showNotificacion("Validando credencial para votar, por favor espere un momento.");
    var confidence, text;
    var Tesseract = window.Tesseract;
    const url = URL.createObjectURL(file);
    console.log(url);
    Tesseract.recognize(url, {
      lang: "spa",
    })
      .catch((err) => {
        console.error(err);
      })
      .then((result) => {
        // Get Confidence score
        confidence = result.confidence;
        console.log("Confiabilidad:", confidence);

        // Get full output
        text = result.text;
        console.log("Texto:", text);

        // Get codes
        let pattern = /\b\w{10,10}\b/g;
        let patterns = result.text.match(pattern);
        console.log("Patrón:", patterns);

        //validación

        if (confidence < 50) {
          console.log("menor 50");
          setIneReverso("");
          setMessageIneReverso("Credencial para votar inválida");
          saveasset("ine_back", "");
        } else {
          var validacion = textoReversoINE(text);
          console.log("validacion", validacion);

          if (validacion === false) {
            setIneReverso("");
            setMessageIneReverso("Credencial para votar inválida");
            saveasset("ine_back", "");
          }
        }
        URL.revokeObjectURL(url);

        //fin validacion
      });
    setLoadingIneReverso(false);
    console.log("FIN RECONOCIMIENTO");
    //fin reconocimiento
  };

  const handleFileReadINEReverso_validacion = async (e) => {
    setLoadingIneReverso(true);
    setMessageIneReverso("");
    setIneReverso("");

    const file = e.target.files[0];
    console.log("INE/IFE Reverso:", file);

    //validar INE
    showNotificacion("Validando credencial para votar, por favor espere un momento.");
    validarBackINE(file);

    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setIneReverso(newImages[0]);

      //saveINEReverso
      saveasset("ine_back", newImages[0].base64);
      //ine reverso saved
    }
  };

  const handleFileReadINEReverso = async (e) => {
    setLoadingIneReverso(true);
    setMessageIneReverso("");
    setIneReverso("");

    const file = e.target.files[0];
    console.log("INE/IFE Reverso:", file);

    //validar INE
    showNotificacion("Guardando credencial para votar, por favor espere un momento.");

    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setIneReverso(newImages[0].base64);

      //saveINEReverso
      saveasset("ine_back", newImages[0].base64);
      //ine reverso saved
    }
    setLoadingIneReverso(false);
  };

  function textoPasaporte(texto) {
    var str = texto;
    var valor = 0;

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK");
      valor++;
    }

    if (str.search(dataPerfil.name.toUpperCase().split(" ")[1]) !== -1) {
      console.log("LASTNAME OK");
      valor++;
    }

    if (str.search("PASSPORT") !== -1) {
      valor++;
    }
    if (str.search("PÁSSPORT") !== -1) {
      valor++;
    }
    if (str.search("Surnames") !== -1) {
      valor++;
    }
    /*
          if (str.search("names")!==-1) {
            valor++;
          }
          if (str.search("type")!==-1) {
            valor++;
          }
          if (str.search("Naciona")!==-1) {
            valor++;
          }
          if (str.search("Date")!==-1) {
            valor++;
          }
          if (str.search("signature")!==-1) {
            valor++;
          }
    */

    console.log("valor:", valor);
    if (valor > 1) {
      return true;
    }

    return false;
  }

  const validarPasaporte = (file) => {
    //reconocimiento
    var confidence, text;
    var Tesseract = window.Tesseract;
    const url = URL.createObjectURL(file);
    console.log(url);
    Tesseract.recognize(url, {
      lang: "spa",
    })
      .catch((err) => {
        console.error(err);
      })
      .then((result) => {
        // Get Confidence score
        confidence = result.confidence;
        console.log("Confiabilidad:", confidence);

        // Get full output
        text = result.text;
        console.log("Texto:", text);
        //setTextoOCR(text);

        // Get codes
        let pattern = /\b\w{10,10}\b/g;
        let patterns = result.text.match(pattern);
        console.log("Patrón:", patterns);

        //validación
        var id;
        var adicional = "";
        if (confidence < 50) {
          console.log("menor 50");
          setPasaporte("");
          setMessagePasaporte("Inválido");
          saveasset("pasaporte", "");
        } else {
          console.log("mayor 50");
          var validacion = textoPasaporte(text);
          console.log("validacion", validacion);
          if (validacion === false) {
            setPasaporte("");
            setMessagePasaporte("Inválido");
            saveasset("pasaporte", "");
          }
        }

        URL.revokeObjectURL(url);
        //fin validacion
        setLoadingPasaporte(false);
      });

    console.log("FIN RECONOCIMIENTO");
    //fin reconocimiento
  };

  const handleFileReadPasaporte_Validacion = async (e) => {
    setLoadingPasaporte(true);
    setMessagePasaporte("");
    setPasaporte("");
    const file = e.target.files[0];
    console.log("PASAPORTE:", file);

    //validar PASAPORTE
    showNotificacion("Validando Pasaporte, por favor espere un momento.");

    //image detection
    await loadModels();
    const url = URL.createObjectURL(file);
    //await handleImage(url);
    //function
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Foto:", fullDesc);
    URL.revokeObjectURL(url);
    //fin image
    //fin validar
    if (fullDesc) {
      console.log("PASAPORTE:", file);
      validarPasaporte(file);
      const newImagesPromises = [];
      newImagesPromises.push(fileToDataUri(file));
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setPasaporte(newImages[0].base64);

      //save PASAPORTE
      saveasset("pasaporte", newImages[0].base64);
      //pasaporte saved
    } else {
      setMessagePasaporte("Inválido");
      setLoadingPasaporte(false);
    }
  };

  const handleFileReadPasaporte_last = async (e) => {
    setLoadingPasaporte(true);
    setMessagePasaporte("");
    setPasaporte("");
    const file = e.target.files[0];
    console.log("PASAPORTE:", file);

    //validar PASAPORTE
    showNotificacion("Guardando Pasaporte, por favor espere un momento.");

    const newImagesPromises = [];
    newImagesPromises.push(fileToDataUri(file));
    const newImages = await Promise.all(newImagesPromises);
    console.log(newImages);
    setPasaporte(newImages[0].base64);

    //save PASAPORTE
    saveasset("pasaporte", newImages[0].base64);
    //pasaporte saved
    setLoadingPasaporte(false);
  };

  const handleclosefoto = () => {
    setDialogoFoto(false);
    setFoto("");
  };

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "Herramientas",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  const handleCancelDialogIne = () => {
    setStep(0);
    setIneFrente("");
    setIneReverso("");
    setDialogoINE(false);
  };

  const handleCancelDialogPasaporte = () => {
    setStep(0);
    //setPasaporte("");
    setDialogoPasaporte(false);
  };

  const handleCancelDialogCurp = () => {
    // setCurpNumber("");
    setDialogoCURP(false);
  };

  const handleCancelDialogLote = () => {
    setInitialData(undefined);
    setmodalLote(false);
  };

  const handleCancelDialogLoteN = () => {
    setInitialData(undefined);
    setmodalLoteN(false);
  };

  const handleSetModalUsuario = () => {
    setModalUsuario(false);
    setTablaDocumentos([{}]);
    setUsuario("");
  };

  const handleCancelDialogFirmante = () => {
    setModalFirmantes(false);
    setDatosFirmante({
      nombre: "",
      apellidos: "",
      correo: "",
      codigo: "52",
      celular: "",
      rol: "Por su Propio Derecho",
      Compañia: "",
      grupo: "DEFAULT",
      rfc: "",
    });
  };

  const handleEliminarNotificado = () => {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes/${notificado._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificado eliminado:", response);
        getNotificados();
      })
      .catch(function (error) {
        console.log(error);
      });

    setModalEliminarNotificado(false);
  };

  const handleRegistrarNotificado = () => {
    setModalNotificados(false);
    setDatosNotificado({
      nombre: "",
      apellidos: "",
      correo: "",
      codigo: "52",
      celular: "",
      grupo: "DEFAULT",
    });
  };

  const handleUpdateNotificado = () => {
    setModalEditarNotificado(false);
    setDatosModificarN({
      nombre: "",
      apellidos: "",
      correo: "",
      codigo: "52",
      celular: "",
      grupo: "DEFAULT",
    });
  };
  const handleUpdateFirmante = () => {
    setModalEditarFirmante(false);
    setDatosModificar({
      nombre: "",
      apellidos: "",
      correo: "",
      codigo: "52",
      celular: "",
      rol: "Por su Propio Derecho",
      Compañia: "",
      grupo: "DEFAULT",
      rfc: "",
    });
  };

  const handleDeleteFirmante = () => {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
        getFirmantes();
      })
      .catch(function (error) {
        console.log(error);
      });

    setModalEliminarFirmante(false);
  };

  let alertRFC = "";
  if (datosFirmante.rfc && datosFirmante.rfc !== "") {
    alertRFC = regexRFC.test(datosFirmante.rfc) ? "" : "RFC inválido.";
  }

  let alertRFCModificar = "";
  if (datosModificar.rfc && datosModificar.rfc !== "") {
    alertRFCModificar = regexRFC.test(datosModificar.rfc)
      ? ""
      : "RFC inválido.";
  }

  let disabledNotificado = true;
  if (value == 2) {
    if (
      datosNotificado.correo.length >= 3 &&
      datosNotificado.nombre.length >= 3 &&
      datosNotificado.apellidos.length >= 2 &&
      datosNotificado.grupo.length >= 1 &&
      datosNotificado.celular.length >= 9
    ) {
      disabledNotificado = false;
    }
  } else {
    if (
      datosNotificado.correo.length >= 3 &&
      datosNotificado.nombre.length >= 3 &&
      datosNotificado.apellidos.length >= 3 &&
      datosNotificado.grupo.length >= 1
      //&& datosNotificado.celular.length >= 9
    ) {
      disabledNotificado = false;
    }
  }

  let disabledFirmante = true;
  if (value == 2) {
    if (
      datosFirmante.correo.length >= 3 &&
      datosFirmante.nombre.length >= 3 &&
      datosFirmante.rol.length >= 2 &&
      datosFirmante.apellidos.length >= 2 &&
      datosFirmante.Compañia.length >= 2 &&
      datosFirmante.grupo.length >= 1 &&
      datosFirmante.celular.length >= 9
    ) {
      disabledFirmante = false;
    }
  } else {
    let esCorreo = validator.isEmail(datosFirmante.correo);
    if (
      datosFirmante.correo.length >= 3 &&
      datosFirmante.nombre.length >= 3 &&
      datosFirmante.rol.length >= 2 &&
      datosFirmante.apellidos.length >= 3 &&
      datosFirmante.Compañia.length >= 2 &&
      datosFirmante.grupo.length >= 1 &&
      esCorreo
      //&& datosFirmante.celular.length >= 9
    ) {
      disabledFirmante = false;
    }
  }

  let disabledModificar = true;
  if (valueModificar == 2) {
    if (
      datosModificar &&
      datosModificar.correo &&
      datosModificar.correo.length >= 3 &&
      datosModificar.nombre &&
      datosModificar.nombre.length >= 2 &&
      datosModificar.rol &&
      datosModificar.rol.length >= 2 &&
      datosModificar.apellidos &&
      datosModificar.apellidos.length >= 2 &&
      datosModificar.Compañia &&
      datosModificar.Compañia.length >= 2 &&
      datosModificar.grupo &&
      datosModificar.grupo.length >= 1 &&
      datosModificar.celular &&
      datosModificar.celular.length >= 9
    ) {
      disabledModificar = false;
    }
  } else {
    let esCorreo = validator.isEmail(datosModificar.correo);

    if (
      datosModificar &&
      datosModificar.correo &&
      datosModificar.correo.length >= 3 &&
      datosModificar.nombre &&
      datosModificar.nombre.length >= 2 &&
      datosModificar.rol &&
      datosModificar.rol.length >= 2 &&
      datosModificar.apellidos &&
      datosModificar.apellidos.length >= 2 &&
      datosModificar.Compañia &&
      datosModificar.Compañia.length >= 2 &&
      datosModificar.grupo &&
      datosModificar.grupo.length >= 1 &&
      esCorreo
      //datosModificar.celular &&
      //datosModificar.celular.length >= 9
    ) {
      disabledModificar = false;
    }
  }

  let disabledModificarN = true;
  if (valueModificar == 2) {
    if (
      datosModificarN.correo.length >= 3 &&
      datosModificarN.nombre.length >= 3 &&
      datosModificarN.apellidos.length >= 2 &&
      datosModificarN.grupo.length >= 1 &&
      datosModificarN.celular.length >= 9
    ) {
      disabledModificarN = false;
    }
  } else {
    if (
      datosModificarN.correo.length >= 3 &&
      datosModificarN.nombre.length >= 3 &&
      datosModificarN.apellidos.length >= 3 &&
      datosModificarN.grupo.length >= 1 &&
      datosModificarN.celular &&
      datosModificarN.celular.length >= 9
    ) {
      disabledModificarN = false;
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        {/* AVATAR */}
        {/*
        <GridItem xs={12} sm={12} md={2}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>{userName}</h6>
              <h4 className={classes.cardTitle}>Con Certeza</h4>

              <Button color="success" normalMode={true} onClick={handleOpenDialog}>
                ver perfil
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        */}

        {/* DATOS DEL PERFIL */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <h4 className={classes.cardTitleWhite}>
                    Usuario: {dataPerfil ? dataPerfil.name : null}
                  </h4>
                  <p className={classes.cardCategoryWhite}>Datos del perfil</p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CardAvatar profile>
                    <React.Fragment>
                      <input
                        accept="image/*"
                        //className={classes.input}
                        style={{ display: "none" }}
                        id="contained-button-file-fotoPerfil"
                        multiple
                        type="file"
                        onChange={(e) => handleFileFotoPerfil(e)}
                      //onChange={()=>{console.log("cange")}}
                      />
                      <label htmlFor="contained-button-file-fotoPerfil">
                        {fotoPerfil ? (
                          <img src={fotoPerfil} alt="..." />
                        ) : (
                          <img src={avatar} alt="..." />
                        )}
                      </label>
                    </React.Fragment>
                  </CardAvatar>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {loadingPerfil ? <CircularProgress /> : null}
              <div className={classes.myRoot}>
                <Grid container spacing={5}>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="standar-company"
                      label="Compañía"
                      variant="outlined"
                      name="userCompany"
                      value={userCompany}
                      margin="dense"
                      fullWidth
                      style={{ maxWidth: "92%" }}
                      onChange={(event) => handleChangeValues(event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="standard-name"
                      label="Nombre completo"
                      variant="outlined"
                      name="userName"
                      value={userName}
                      margin="dense"
                      fullWidth
                      style={{ maxWidth: "92%" }}
                      onChange={(event) => handleChangeValues(event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <input
                      type="text"
                      name="email"
                      value=""
                      readOnly={true}
                      style={{ display: "none" }}
                    />

                    <TextField
                      id="standar-phone"
                      label="Teléfono"
                      variant="outlined"
                      name="userPhone"
                      value={userPhone}
                      margin="dense"
                      fullWidth
                      style={{ maxWidth: "92%" }}
                      onChange={(event) => handleChangeValues(event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <input
                      type="text"
                      name="email"
                      value=""
                      readOnly={true}
                      style={{ display: "none" }}
                    />
                    <TextField
                      id="standar-email"
                      label="Correo electrónico"
                      variant="outlined"
                      name="email"
                      value={userEmail}
                      margin="dense"
                      fullWidth
                      style={{ maxWidth: "92%" }}
                      InputProps={{
                        readOnly: true,
                        autoComplete: "new-password",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <center>
                      <Button
                        styles={{ align: "center" }}
                        onClick={handleOpenChangePassword}
                        color="success"
                        size="small"
                        startIcon={<AutorenewIcon />}
                      >
                        Cambiar Contraseña
                      </Button>
                    </center>
                  </GridItem>
                </Grid>
              </div>
            </CardBody>
            <CardFooter>
              {/*
                <Button
                onClick={HandleUpdateUser}
                color="success"
                disabled={true}>
                  Actualizar perfil
                </Button>
                */}
              {/*
                <Button onClick={handlePassword} color="success">
                  Cambiar contraseña
                </Button>
                */}
            </CardFooter>
          </Card>
        </GridItem>

        {/* ANEXOS */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Identificaciones</h4>
              <p className={classes.cardCategoryWhite}>Clic para cargar</p>
            </CardHeader>
            <CardBody>
              {/*CURP*/}
              {/*
              <GridContainer spacing={2}>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="standar-Curp"
                    label="CURP"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    // style={{ maxWidth: "92%" }}
                    value={curpNumber ? curpNumber : ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: "15%" }}
                    normalMode={true}
                    startIcon={<PublishIcon fontSize="small" />}
                    onClick={() => {
                      setDialogoCURP(true);
                    }}
                  >
                    {messageCurp ? (
                      <font color="red">{messageCurp}</font>
                    ) : curpNumber ? (
                      "Editar"
                    ) : (
                      "Cargar"
                    )}
                  </Button>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {curpNumber ? (
                    <IconButton
                      color="secondary"
                      aria-label="Eliminar"
                      size="small"
                      style={{ marginTop: "15%" }}
                      onClick={() => {
                        setDialogoEliminar(true);
                        setOpcionEliminar(1);
                      }}
                    >
                      <HighlightOffIcon
                        fontSize="medium"
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </IconButton>
                  ) : null}
                </GridItem>
              </GridContainer>
              */}
              {/*FIN CURP*/}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <hr />
                </GridItem>
              </GridContainer>
              {/*INE/IFE FRENTE*/}
              <GridContainer spacing={2}>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="standar-ine"
                    label="Credencial para votar(frente)"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    // style={{ maxWidth: "92%" }}
                    value="Credencial para votar(frente)"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {loadingIneFrente ? <CircularProgress /> : null}
                  {ineFrente ? (
                    <img
                      width="55px"
                      height="auto"
                      maxWidth="100px"
                      src={ineFrente}
                      style={{ cursor: "pointer", marginTop: "15%" }}
                      onClick={() => {
                        setDialogoFoto(true);
                        setFoto(ineFrente);
                      }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      normalMode={true}
                      style={{ marginTop: "15%" }}
                      startIcon={<PublishIcon fontSize="small" />}
                      onClick={() => {
                        setDialogoINE(true);
                      }}
                    >
                      {messageIneFrente ? (
                        <font color="red">{messageIneFrente}</font>
                      ) : (
                        "Cargar"
                      )}
                    </Button>
                  )}
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {ineFrente ? (
                    <IconButton
                      color="secondary"
                      aria-label="Eliminar"
                      style={{ marginTop: "15%" }}
                      size="small"
                      onClick={() => {
                        setDialogoEliminar(true);
                        setOpcionEliminar(2);
                      }}
                    >
                      <HighlightOffIcon
                        fontSize="medium"
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </IconButton>
                  ) : null}
                </GridItem>
              </GridContainer>
              {/*FIN INE/IFE FRENTE*/}

              {/*INE/IFE REVERSO*/}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="standar-ine"
                    label="Credencial para votar (reverso)"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    // style={{ maxWidth: "92%" }}
                    value="Credencial para votar (reverso)"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {loadingIneReverso ? <CircularProgress /> : null}
                  {
                    ineReverso ? (
                      <img
                        width="55px"
                        height="auto"
                        maxWidth="100px"
                        src={ineReverso}
                        style={{ cursor: "pointer", marginTop: "15%" }}
                        onClick={() => {
                          setDialogoFoto(true);
                          setFoto(ineReverso);
                        }}
                      />
                    ) : null
                    /*
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      normalMode={true}
                      style={{ marginTop: "15%" }}
                      startIcon={<PublishIcon fontSize="small" />}
                      onClick={() => {
                        setDialogoINE(true);
                      }}
                    >
                      {messageIneReverso ? (
                        <font color="red">{messageIneReverso}</font>
                      ) : (
                        "Cargar"
                      )}
                    </Button>
                    */
                  }
                </GridItem>
              </GridContainer>
              {/*FIN INE/IFE REVERSO*/}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <hr />
                </GridItem>
              </GridContainer>
              {/*PASAPORTE*/}
              <GridContainer spacing={2}>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="standar-passport"
                    label="PASAPORTE"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    // style={{ maxWidth: "92%" }}
                    value="PASAPORTE"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {loadingPasaporte ? <CircularProgress /> : null}
                  {pasaporte ? (
                    <img
                      width="55px"
                      height="auto"
                      maxWidth="100px"
                      src={pasaporte}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDialogoFoto(true);
                        setFoto(pasaporte);
                      }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      normalMode={true}
                      style={{ marginTop: "15%" }}
                      startIcon={<PublishIcon fontSize="small" />}
                      onClick={() => {
                        setDialogoPasaporte(true);
                      }}
                    >
                      {messagePasaporte ? (
                        <font color="red">{messagePasaporte}</font>
                      ) : (
                        "Cargar"
                      )}
                    </Button>
                  )}
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {pasaporte ? (
                    <IconButton
                      color="secondary"
                      aria-label="Eliminar"
                      size="small"
                      onClick={() => {
                        setDialogoEliminar(true);
                        setOpcionEliminar(4);
                      }}
                    >
                      <HighlightOffIcon
                        fontSize="small"
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </IconButton>
                  ) : null}
                </GridItem>
              </GridContainer>
              {/*FIN PASAPORTE*/}
              <GridContainer>
                <GridItem>
                  <hr />
                </GridItem>
              </GridContainer>
              {/*FIRMA*/}
              <GridContainer spacing={2}>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="standar-firma"
                    label="FIRMA"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    // style={{ maxWidth: "92%" }}
                    value="FIRMA"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {grafo ? (
                    <img
                      width="55px"
                      height="auto"
                      maxWidth="100px"
                      src={grafo}
                      title={"Firma"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDialogoFoto(true);
                        setFoto(grafo);
                      }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      normalMode={true}
                      style={{ marginTop: "15%" }}
                      startIcon={<GestureIcon fontSize="small" />}
                      onClick={() => {
                        setDialogoFirma(true);
                      }}
                    >
                      Firmar
                    </Button>
                  )}
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {grafo ? (
                    <IconButton
                      color="secondary"
                      aria-label="Eliminar"
                      style={{ marginTop: "15%" }}
                      size="small"
                      onClick={() => {
                        setDialogoEliminar(true);
                        setOpcionEliminar(5);
                      }}
                    >
                      <HighlightOffIcon
                        fontSize="small"
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </IconButton>
                  ) : null}
                </GridItem>
              </GridContainer>
              {/*FIN FIRMA*/}
            </CardBody>
          </Card>
        </GridItem>
        {/*FIN ANEXOS*/}
      </Grid>

      {/* {permissions.includes("menu.expedientes.view") === false ? ( */}
      <GridContainer>
        <CustomTabs
          handleChange={handleChangeTabs}
          valueState={valueState}
          headerColor={headerColor}
          title=""
          tabs={[
            {
              tabName: "USUARIOS",
              //tabIcon: PauseIcon,
              tabContent: (
                <React.Fragment>
                  <Card style={{ margin: 0 }}>
                    <CardHeader>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : null}
                    </CardHeader>
                    <CardBody>
                      {usuarios ? (
                        <MaterialTable
                          localization={localization}
                          options={{
                            draggable: false,
                            pageSize: 20, // make initial page size
                            pageSizeOptions: [20, 50, 100, 500, 1000],
                            actionsColumnIndex: -1,
                            exportButton: false,
                            search: false,
                          }}
                          columns={[
                            { title: "Nombre", field: "name" },
                            { title: "Teléfono", field: "phone" },
                            { title: "Correo Electrónico", field: "email" },
                            {
                              title: "Grupo",
                              field: "group_id",
                              render: (row) => (
                                <span>{getgrupo(row.group_id)}</span>
                              ),
                            },
                            {
                              title: "Documentos",
                              render: (row) => <span>{numero(row.id)}</span>,
                            },
                          ]}
                          data={usuarios}
                          title={"USUARIOS"}
                          actions={[
                            {
                              icon: () => (
                                <InfoIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                              tooltip: "Ver más detalles",
                              onClick: (event, rowData) => {
                                console.log(rowData);
                                //datos usuario
                                let token = sessionStorage.getItem("Token");

                                var config = {
                                  method: "get",
                                  url: `${process.env.REACT_APP_URL}api/users/show/${rowData.id}`,
                                  headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorization: "Bearer " + token,
                                  },
                                };
                                axios(config)
                                  .then(function (response) {
                                    let arr = response.data;
                                    console.log("USUARIO:", arr);
                                    setUsuario(arr);
                                    let num = documentos.filter(function (
                                      documento
                                    ) {
                                      return documento.uid === rowData.id;
                                    });
                                    setTablaDocumentos(num);
                                  })
                                  .catch(function (error) {
                                    console.log(error);
                                  });
                                //fin datos usuario

                                setModalUsuario(true);
                              },
                            },
                          ]}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </React.Fragment>
              ),
            },

            {
              tabName: "MIS FIRMANTES FRECUENTES",
              //tabIcon: GestureIcon,
              tabContent: (
                <React.Fragment>
                  <Button
                    onClick={() => {
                      setModalFirmantes(true);
                    }}
                    color="success"
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Registrar Nuevo Firmante
                  </Button>
                  <Button
                    onClick={() => {
                      setmodalLote(true);
                    }}
                    color="success"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                  >
                    Importar firmantes
                  </Button>
                  {firmantesFrecuentes ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                        exportButton: true,
                        search: true,
                      }}
                      columns={[
                        { title: "Nombre", field: "name" },
                        { title: "Apellido", field: "last_name" },
                        {
                          title: "Código",
                          field: "phone_country_code",
                          render: (row) => (
                            <span>
                              {row["phone_country_code"]
                                ? "+" + row["phone_country_code"]
                                : null}
                            </span>
                          ),
                        },
                        { title: "Teléfono", field: "phone" },
                        { title: "Correo Electrónico", field: "email" },
                        {
                          title: "Tipo de Actuación",
                          field: "firmante_involvements_id",
                        },
                        {
                          title: "Descripción Actuación",
                          field: "firmante_involvements_id",
                          render: (row) => (
                            <span>
                              {consultaTipoActuacion(
                                row["firmante_involvements_id"]
                              )}
                            </span>
                          ),
                        },
                        {
                          title: "Notificación",
                          field: "notification_types_id",
                          render: (row) => (
                            <span>
                              {row["notification_types_id"] === 1
                                ? "EMAIL"
                                : "SMS"}
                            </span>
                          ),
                        },
                        { title: "Empresa", field: "company" },
                        { title: "Grupo", field: "group" },
                        { title: "RFC firmante", field: "rfc" },
                      ]}
                      data={firmantesFrecuentes}
                      title={"MIS FIRMANTES FRECUENTES"}
                      actions={[
                        {
                          icon: () => (
                            <EditIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Editar firmante",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setFirmante(rowData);
                            setModalEditarFirmante(true);
                            var codigo;
                            if (rowData.phone_country_code) {
                              codigo = rowData.phone_country_code;
                            } else {
                              codigo = "52";
                            }
                            setDatosModificar({
                              nombre: rowData.name,
                              apellidos: rowData.last_name,
                              correo: rowData.email,
                              codigo: codigo,
                              celular: rowData.phone,
                              rol: rowData.role,
                              Compañia: rowData.company,
                              grupo: rowData.group,
                              rfc: rowData.rfc,
                            });
                            setTipoActuacion({
                              codigo: rowData.firmante_involvements_id,
                              valor: "Por su Propio Derecho",
                            });
                            setValueModificar(
                              rowData.notification_types_id.toString()
                            );
                            //setModalEliminarFirmante(true);
                          },
                        },
                        {
                          icon: () => (
                            <DeleteIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Eliminar firmante",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setFirmante(rowData);
                            setModalEliminarFirmante(true);
                          },
                        },
                      ]}
                    />
                  ) : null}
                </React.Fragment>
              ),
            },
            {
              tabName: "MIS OBSERVADORES FRECUENTES",
              //tabIcon: GestureIcon,
              tabContent: (
                <React.Fragment>
                  <Button
                    onClick={() => {
                      setModalNotificados(true);
                    }}
                    color="success"
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Registrar Nuevo Observador
                  </Button>
                  <Button
                    onClick={() => {
                      setmodalLoteN(true);
                    }}
                    color="success"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                  >
                    Importar Observadores
                  </Button>
                  {notificadosFrecuentes ? (
                    <MaterialTable
                      localization={localization}
                      options={{
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                        exportButton: false,
                        search: true,
                      }}
                      columns={[
                        { title: "Nombre", field: "name" },
                        { title: "Apellido", field: "last_name" },
                        {
                          title: "Código",
                          field: "phone_country_code",
                          render: (row) => (
                            <span>
                              {row["phone_country_code"]
                                ? "+" + row["phone_country_code"]
                                : null}
                            </span>
                          ),
                        },
                        { title: "Teléfono", field: "phone" },
                        { title: "Correo Electrónico", field: "email" },
                        {
                          title: "Notificación",
                          field: "notification_types_id",
                          render: (row) => (
                            <span>
                              {row["notification_types_id"] === 1
                                ? "EMAIL"
                                : "SMS"}
                            </span>
                          ),
                        },
                        { title: "Grupo", field: "group" },
                      ]}
                      data={notificadosFrecuentes}
                      title={"MIS OBSERVADORES FRECUENTES"}
                      actions={[
                        {
                          icon: () => (
                            <EditIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Editar notificado",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setNotificado(rowData);
                            setModalEditarNotificado(true);
                            var codigo;
                            if (rowData.phone_country_code) {
                              codigo = rowData.phone_country_code;
                            } else {
                              codigo = "52";
                            }
                            setDatosModificarN({
                              nombre: rowData.name,
                              apellidos: rowData.last_name,
                              correo: rowData.email,
                              codigo: codigo,
                              celular: rowData.phone,
                              grupo: rowData.group,
                            });
                            setValueModificar(
                              rowData.notification_types_id.toString()
                            );
                            //setModalEliminarFirmante(true);
                          },
                        },
                        {
                          icon: () => (
                            <DeleteIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Eliminar notificado",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            setNotificado(rowData);
                            setModalEliminarNotificado(true);
                          },
                        },
                      ]}
                    />
                  ) : null}
                </React.Fragment>
              ),
            },
            {
              tabName: "CAMPOS ADICIONALES",
              //tabIcon: GestureIcon,
              tabContent: (
                <React.Fragment>
                  <Button
                    onClick={() => {
                      setDialogoAgregarCustomMeta(true);
                      setdisabledNameMeta(false);
                      setButtonCustom(true);
                    }}
                    color="success"
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    NUEVO CATÁLOGO DE CAMPOS
                  </Button>
                  <hr />
                  {selectCustomMeta &&
                    selectCustomMeta.map(({ _id, name, fields }, index) => (
                      <>
                        <Grid container>
                          <Grid item xs={6}>
                            <b>{name}:</b>
                            {Object.keys(fields).map((key, i) => (
                              <li key={i}>
                                <b>{key}</b> ({fields[key]})
                              </li>
                            ))}
                          </Grid>
                          <Grid item xs={6}>
                            <Tooltip
                              title="Editar catálogo de campos"
                              placement="top"
                            >
                              <IconButton
                                aria-label="editar"
                                onClick={() => {
                                  let cm = {
                                    _id: _id,
                                    name: name,
                                    fields: fields,
                                  };
                                  console.log(cm);
                                  setNombreMetadato(name);
                                  let arr = [];
                                  Object.keys(fields).map(
                                    (key, i) =>
                                    (arr = [
                                      ...arr,
                                      { key: key, value: fields[key] },
                                    ])
                                  );
                                  setFieldsMetadato(arr);
                                  setCustomMeta(cm);
                                  setDialogoModificarCustomMeta(true);
                                }}
                              >
                                <EditIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Eliminar catálogo de campos"
                              placement="top"
                            >
                              <IconButton
                                aria-label="eliminar"
                                onClick={() => {
                                  let cm = { _id: _id, name: name };
                                  console.log(cm);
                                  setCustomMeta(cm);
                                  setDialogoEliminarCustomMeta(true);
                                }}
                              >
                                <DeleteIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <hr />
                      </>
                    ))}
                </React.Fragment>
              ),
            },
            {
              tabName: "COMPAÑIAS",
              //tabIcon: GestureIcon,
              tabContent: (
                <React.Fragment>
                  <Corporaciones />
                </React.Fragment>
              ),
            },
            {
              tabName: "CLASES DE DOCUMENTOS",
              //tabIcon: GestureIcon,
              tabContent: (
                <React.Fragment>
                  <ClasesDocu />
                </React.Fragment>
              ),
            },
          ]}
        />
      </GridContainer>
      {/*FIN TABS*/}

      {/*MODAL CONTRASEÑA*/}
      <DialogComp
        open={openModalPass}
        fullWidth={true}
        maxWidth="xs"
        // onClose={() => setModalDocumentos(false)}
        title="Cambiar Contraseña"
        content={
          <div className={classes.marginPass}>
            <TextField
              variant="outlined"
              type={showPassword1 ? "text" : "password"}
              name="oldPassword"
              label="Contraseña Actual"
              fullWidth
              value={oldPassword}
              onChange={handleChangePassValues}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                    >
                      <VpnKeyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("showPassword1", showPassword1)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword1 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            // labelWidth={70}
            />
            <TextField
              variant="outlined"
              type={showPassword2 ? "text" : "password"}
              name="newPassword"
              label="Contraseña Nueva"
              fullWidth
              value={newPassword}
              error={passError}
              onChange={handleChangePassValues}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                    >
                      <VpnKeyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("showPassword2", showPassword2)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            // labelWidth={70}
            />
            <TextField
              variant="outlined"
              type={showPassword3 ? "text" : "password"}
              name="confirmPassword"
              label="Confirma contraseña nueva"
              fullWidth
              value={confirmPassword}
              onChange={handleChangePassValues}
              error={passError}
              helperText={passError ? "Las contraseñas no coinciden" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                    >
                      <VpnKeyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("showPassword3", showPassword3)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword3 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            // labelWidth={70}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseChangePassword,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleChangePassword,
            buttonName: "Cambiar contraseña",
            buttonColor: "primary",
            buttonDisabled: buttonPass,
          },
        ]}
      />
      {/* Modal de carga */}
      <DialogComp
        open={progress}
        // title={"¿Eliminar metadato?"}
        bground={true}
        maxWidth={"sm"}
        content={
          <Backdrop className={classes.backdrop} open={progress}>
            <CircularProgress size={44} className={classes.buttonProgress} />
          </Backdrop>
        }
      />

      {/*MODAL DOCUMENTOS*/}
      <DialogComp
        open={modalDocumentos}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        // onClose={() => setModalDocumentos(false)}
        title="DOCUMENTOS"
        content={
          <MaterialTable
            localization={localization}
            options={{
              exportButton: false,
              draggable: false,
              pageSize: 20, // make initial page size
              pageSizeOptions: [20, 50, 100, 500, 1000],
              actionsColumnIndex: -1,
            }}
            columns={[
              { title: "COMPAÑÍA", field: "users.company" },
              { title: "USUARIO", field: "users.name" },
              { title: "DOCUMENTO", field: "filename" },
              { title: "TIPO", field: "type.name" },
              {
                title: "ASISTIDO",
                field: "asistido",
                render: (row) => <span>{row["asistido"] ? "SI" : null}</span>,
              },
              {
                title: "FECHA",
                field: "created_at",
                render: (row) => (
                  <span>
                    {row["created_at"]
                      ? moment(row["created_at"]).format("DD/MM/YYYY")
                      : null}
                  </span>
                ),
              },
              {
                title: "HORA",
                field: "created_at",
                render: (row) => (
                  <span>
                    {row["created_at"]
                      ? moment(row["created_at"]).format("HH:mm:ss")
                      : null}
                  </span>
                ),
              },
              { title: "ESTATUS", field: "status.name" },
              { title: "FIRMANTES", field: "firmantes_names" },
              { title: "ID", field: "_id" },
              { title: "HASH", field: "hash" },
              { title: "HASH FIRMADO", field: "hash_signed" },
            ]}
            data={tablaDocumentos}
            title={"TOTAL :" + tablaDocumentos.length}
          />
        }
        buttons={[
          {
            buttonEvent: () => setModalDocumentos(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*MODAL DATOS USUARIO*/}
      <DialogComp
        open={modalUsuario}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        // onClose={() => setModalUsuario(false)}
        title="USUARIO"
        content={
          usuario ? (
            <div>
              <h3>{usuario.user.name}</h3>
              {usuario.user.email}
              <br />
              {usuario.user.phone}
              <hr />
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textPrimary" style={{ margin: 0 }}>
                    <font>
                      <b>{numero(usuario.user.id)} Documentos</b>
                    </font>
                    <br />
                    <font color="#66bb6a">
                      {estatus(usuario.user.id, 3)} Firmados
                    </font>
                    /
                    <font color="#ffa726">
                      {estatus(usuario.user.id, 2)} En Espera
                    </font>
                    /
                    <font color="#ef5350">
                      {estatus(usuario.user.id, 4)} Rechazados
                    </font>
                    /
                    <font color="#ab47bc">
                      {estatus(usuario.user.id, 1)} Registrados
                    </font>
                    /
                    <font color="red">
                      {estatus(usuario.user.id, 7)} Eliminados
                    </font>
                    <br />
                  </Typography>
                  <MaterialTable
                    localization={localization}
                    options={{
                      exportButton: true,
                      draggable: false,
                      pageSize: 20, // make initial page size
                      pageSizeOptions: [20, 50, 100, 500, 1000],
                      actionsColumnIndex: -1,
                    }}
                    columns={[
                      { title: "DOCUMENTO", field: "filename" },
                      { title: "TIPO", field: "type.name" },
                      {
                        title: "FECHA",
                        field: "created_at",
                        render: (row) => (
                          <span>
                            {row["created_at"]
                              ? moment(row["created_at"]).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                              : null}
                          </span>
                        ),
                      },
                      { title: "ESTATUS", field: "status.name" },
                      { title: "FIRMANTES", field: "firmantes_names" },
                      { title: "ID", field: "_id" },
                    ]}
                    data={tablaDocumentos}
                    title={""}
                  />
                </CardContent>
              </Card>
            </div>
          ) : null
        }
        buttons={[
          {
            buttonEvent: handleSetModalUsuario,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo para ver pdf*/}
      <DialogComp
        open={openDialogPDF}
        maxWidth={"md"}
        title="Tu documento"
        content={
          base64.length === 0 ? (
            <Backdrop>
              <CircularProgress size={44} />
            </Backdrop>
          ) : (
            <div align="center">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                file={base64}
              />
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={base64}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>

              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          )
        }
        buttons={[
          {
            buttonEvent: handleclosepdf,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo para ver IMAGEN*/}

      <DialogComp
        open={dialogoFoto}
        maxWidth={"md"}
        title="IMAGEN"
        content={<img width="250" src={foto} />}
        buttons={[
          {
            buttonEvent: handleclosefoto,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo ELIMINAR*/}
      <DialogComp
        open={dialogoEliminar}
        maxWidth={"md"}
        title="¿ESTÁ SEGURO?"
        content={
          <>
            {opcionEliminar === 1 ? (
              <Button
                onClick={() => {
                  saveasset("curp", "");
                  setCurpNumber("");
                  setOpcionEliminar(0);
                  setDialogoEliminar(false);
                }}
                color="danger"
              >
                ELIMINAR CURP
              </Button>
            ) : null}
            {opcionEliminar === 2 ? (
              <Button
                onClick={() => {
                  handleDeleteIne();
                }}
                color="danger"
              >
                ELIMINAR CREDENCIAL PARA VOTAR
              </Button>
            ) : null}
            {opcionEliminar === 4 ? (
              <Button
                onClick={() => {
                  saveasset("pasaporte", "");
                  setPasaporte("");
                  setOpcionEliminar(0);
                  setDialogoEliminar(false);
                }}
                color="danger"
              >
                ELIMINAR PASAPORTE
              </Button>
            ) : null}
            {opcionEliminar === 5 ? (
              <Button
                onClick={() => {
                  handleEliminarFirma();
                }}
                color="danger"
              >
                ELIMINAR FIRMA
              </Button>
            ) : null}
          </>
        }
        buttons={[
          {
            buttonEvent: () => setDialogoEliminar(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          {snackMessage}
        </Alert>
      </Snackbar>

      {/*dialogo FIRMAR*/}
      <DialogComp
        open={dialogoFirma}
        fullWidth={true}
        maxWidth="sm"
        title="FIRMA"
        content={
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid Item xs={12} sm={12} md={12}>
                <Card className={stylesf.container}>
                  <CardHeader color="success">
                    <center>
                      <h5 className={styles.cardTitleWhite}>
                        Traza tu firma en el área gris{" "}
                      </h5>
                      <ArrowDownwardIcon></ArrowDownwardIcon>
                    </center>
                  </CardHeader>
                  <div className={stylesf.sigContainer}>
                    <SignaturePad
                      canvasProps={{
                        className: stylesf.sigPad,
                        height: "280%",
                      }}
                      ref={(ref) => {
                        setFirma(ref);
                      }}
                    />
                  </div>
                </Card>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Button
                    onClick={clear}
                    varian="contained"
                    color="default"
                    className={classes.button}
                  >
                    limpiar
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="success"
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    }}
                    onClick={handleGuardaFirma}
                  >
                    ACEPTAR
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        buttons={[
          {
            buttonEvent: () => setDialogoFirma(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*alerta mensaje*/}
      <Snackbar1
        place="tr"
        color={colorAlert}
        icon={InfoIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      {/*fin alerta*/}

      {/*dialogo INE*/}
      <Dialog
        open={dialogoINE}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      // maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {step === 0 ? "Carga el frente de tu credencial para votar" : null}
          {step === 1 ? "Ajuste la Imagen" : null}
          {step === 2 ? "Validando credencial para votar" : null}
          {step === 3 ? "Imagen correcta,  carga el reverso de tu credencial para votar" : null}
          {step === 4 ? "Ajuste la Imagen" : null}
          {step === 5 ? "Validando credencial para votar" : null}
          {step === 6 ? "Proceso culminado" : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {step === 0 ? (
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <img width="250" src={takepicture} />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                  <Typography color="textSecondary">
                      Por favor capturar o subir su identificacón siempre de
                      forma horizontal.
                    </Typography>
                    Puedes tomar fotografía o cargar la imagen de tu
                    identificación en{" "}
                    <font color="black">
                      <b>formatos jpg, png o jpeg</b>
                    </font>
                    . Al tomar la fotografía, te recomendamos limpiar el lente
                    de la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                    <br />
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => handleFileReadFront(e)}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="success"
                        component="span"
                        size="small"
                      >
                        <PhotoCamera />
                        Carga
                      </Button>
                    </label>
                  </GridItem>
                </GridContainer>
              </form>
            ) : null}

            {step === 1 ? (
              <Grid container spacing={2}>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 9}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    preview=".previewCropper"
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    move={20}
                    guides={true}
                    src={ineFrente}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    // crop={onCrop}
                    ref={cropperRef}
                  />

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleRotate()}
                    component="span"
                  >
                    <RotateRightIcon /> Girar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  Previsualización de imagen ajustada:
                  <br />
                  <div
                    className="previewCropper"
                    style={{
                      width: "100%",
                      float: "left",
                      height: "300px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleValidarFront()}
                    component="span"
                  >
                    Iniciar Validación
                  </Button>
                </GridItem>
              </Grid>
            ) : null}

            {step === 2 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={scanpicture} style={{ maxWidth: "200px" }} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={croppedFront} style={{ maxWidth: "200px" }} />
                  <br />
                  <font size="1" color={colorMessageFront}>
                    {messageFront}
                  </font>
                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(0);
                        setCroppedFront("");
                        setReiniciar(false);
                      }}
                      color="success"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 3 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img width="250" src={takepicture1} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                <Typography color="textSecondary">
                      Por favor capturar o subir su identificacón siempre de
                      forma horizontal.
                    </Typography>
                    Puedes tomar fotografía o cargar la imagen de tu
                    identificación en{" "}
                    <font color="black">
                      <b>formatos jpg, png o jpeg</b>
                    </font>
                    . Al tomar la fotografía, te recomendamos limpiar el lente
                    de la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                    <br />
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleFileReadBack(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="success"
                      component="span"
                    >
                      <CloudUploadIcon />
                      Cargar Reverso
                    </Button>
                  </label>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 4 ? (
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 9}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    preview=".previewCropperBack"
                    autoCropArea={1}
                    checkOrientation={false}
                    move={20}
                    src={ineReverso}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    // crop={onCrop}
                    ref={cropperRef}
                  />

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleRotate()}
                    component="span"
                  >
                    <RotateRightIcon /> Girar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  Previsualización de imagen ajustada:
                  <br />
                  <div
                    className="previewCropperBack"
                    style={{
                      width: "100%",
                      float: "left",
                      height: "300px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleValidarBack()}
                    component="span"
                  >
                    Iniciar Validación
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 5 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={scanpicture} style={{ maxWidth: "200px" }} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={croppedFront} style={{ maxWidth: "200px" }} />
                  <br />
                  <font size="1" color={colorMessageBack}>
                    {messageBack}
                  </font>
                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(3);
                        setCroppedFront("");
                        setReiniciar(false);
                      }}
                      color="success"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleCancelDialogIne();
            }}
            color="rose"
            size="small"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/*dialogo PASAPORTE*/}

      <Dialog
        open={dialogoPasaporte}
        // fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {step === 0 ? "Carga de Pasaporte" : null}
          {step === 1 ? "Ajuste la Imagen" : null}
          {step === 2 ? "Validando Pasaporte" : null}
          {step === 3 ? "Proceso culminado" : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {step === 0 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img width="250" src={takepicture} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                <font color="black">
                    <b>Fotografía - Pasaporte</b>
                  </font>
                  <br />
                  Puedes tomar fotografía o cargar la imagen de tu
                  identificación en{" "}
                  <font color="black">
                    <b>formatos jpg, png o jpeg</b>
                  </font>
                  . Al tomar la fotografía, te recomendamos limpiar el lente de
                  la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                  <br />
                  <br />
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleFileReadPasaporte(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      component="span"
                    >
                      <CloudUploadIcon />
                      Cargar
                    </Button>
                  </label>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 1 ? (
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 9}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    preview=".previewCropperPass"
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    checkOrientation={false}
                    move={20}
                    src={pasaporte}
                    // crop={onCrop}
                    ref={cropperRef}
                  />

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleRotate()}
                    component="span"
                  >
                    <RotateRightIcon /> Girar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  Previsualización de imagen ajustada:
                  <br />
                  <div
                    className="previewCropperPass"
                    style={{
                      width: "100%",
                      float: "left",
                      height: "300px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleValidarPasaporte()}
                    component="span"
                  >
                    Iniciar Validación
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 2 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={scanpicture} style={{ maxWidth: "200px" }} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <img src={croppedFront} style={{ maxWidth: "200px" }} />
                  <br />
                  <font size="1" color={colorMessageFront}>
                    {messageFront}
                  </font>
                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(0);
                        setCroppedFront("");
                        setReiniciar(false);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 3 ? <GridContainer></GridContainer> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            normalMode={true}
            variant="contained"
            onClick={() => {
              handleCancelDialogPasaporte();
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/*dialogo CURP*/}
      <DialogComp
        open={dialogoCURP}
        title="Ingrese CURP"
        content={
          <>
            <TextField
              id="outlined-basic"
              label="Ingrese CURP"
              variant="outlined"
              onChange={(e) => {
                validarInput(e.target.value);
              }}
            />
            <br />
            {messageCurp ? (
              <React.Fragment>
                <font size="1" color="red">
                  {messageCurp}
                </font>
                <br />
              </React.Fragment>
            ) : null}
            <Button
              disabled={validaCurp}
              onClick={() => {
                saveasset("curp", curpNumber);
                setDialogoCURP(false);
              }}
              color="success"
              variant="contained"
            >
              Guardar
            </Button>
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelDialogCurp,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo FIRMANTES FRECUENTES*/}
      <DialogComp
        open={modalFirmantes}
        maxWidth={"md"}
        fullWidth={true}
        title="REGISTRAR FIRMANTE FRECUENTE"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                id="nombre"
                name="nombre"
                label="Nombre(s)"
                value={datosFirmante.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInput}
                inputProps={{ maxLength: 30 }}
                error={datosFirmante.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                id="apellidos"
                name="apellidos"
                label="Apellido(s)"
                value={datosFirmante.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datosFirmante.apellidos.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                id="correo"
                name="correo"
                label="Correo electrónico"
                value={datosFirmante.correo}
                fullWidth
                autoComplete="email"
                onChange={handleInput}
                onBlur={() => {
                  if (datosFirmante.correo) {
                    validarMail(datosFirmante.correo);
                  }
                }}
                inputProps={{ maxLength: 60 }}
                error={datosFirmante.correo.length > 2 ? false : true}
              />
              <font color="red">{textoValidacionMail}</font>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="grupo"
                name="grupo"
                margin="dense"
                variant="outlined"
                label="Grupo"
                value={datosFirmante.grupo}
                fullWidth
                autoComplete="grupo"
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datosFirmante.grupo.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.codigo}>
              <font color={"gray"} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "80%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={"mx"}
                value={datosFirmante.codigo}
                onChange={(phone) => {
                  //setEstiloCelular("gray");
                  setDatosFirmante({
                    ...datosFirmante,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {value > 1 ? (
                <TextField
                  required
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  id="celular"
                  name="celular"
                  label="Celular"
                  value={datosFirmante.celular}
                  onChange={handleInput}
                  inputProps={{ maxLength: 10 }}
                  error={datosFirmante.celular.length > 9 ? false : true}
                />
              ) : (
                <TextField
                  //required
                  margin="dense"
                  variant="outlined"
                  style={{ width: "100%" }}
                  id="celular"
                  name="celular"
                  label="Celular"
                  value={datosFirmante.celular}
                  onChange={handleInput}
                  inputProps={{ maxLength: 10 }}
                  error={datosFirmante.celular.length > 9 ? false : true}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                margin="dense"
                id="Compañia"
                name="Compañia"
                label="Empresa"
                value={datosFirmante.Compañia}
                fullWidth
                variant="outlined"
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datosFirmante.Compañia.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="rfc"
                name="rfc"
                label="RFC firmante"
                margin="dense"
                variant="outlined"
                value={datosFirmante.rfc}
                fullWidth
                autoComplete="rfc"
                onChange={handleInput}
                inputProps={{ maxLength: 13 }}
              />
              <FormHelperText style={{ color: "red" }}>
                {alertRFC}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={value}
                  //onChange={handleChange}
                  onChange={(e) => {
                    setValue(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectTipos />
            </Grid>
            <Grid item xs={12} sm={4}>
              {tipoActuacion.codigo === 5 ? (
                <TextField
                  required
                  variant="outlined"
                  id="rol"
                  name="rol"
                  label="Tipo de actuación"
                  value={datosFirmante.rol}
                  fullWidth
                  onChange={handleInput}
                  inputProps={{ maxLength: 60 }}
                  error={datosFirmante.rol.length > 2 ? false : true}
                />
              ) : null}
            </Grid>
            <Grid item xs={3} sm={6}>
              <Button
                color="success"
                size="md"
                type="button"
                onClick={registerNewFirmante}
                className={classes.button}
                disabled={disabledFirmante}
              >
                agregar firmante
              </Button>
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleCancelDialogFirmante,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo ELIMINAR FIRMANTE*/}

      <DialogComp
        open={modalEliminarFirmante}
        title="¿ELIMINAR FIRMANTE?"
        content={firmante ? firmante.name + " " + firmante.last_name : null}
        buttons={[
          {
            buttonEvent: () => setModalEliminarFirmante(false),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleDeleteFirmante,
            buttonName: "Eliminar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Cancelar Carga Metadata */}
      <DialogComp
        open={modalCancelarMeta}
        title="¿Está seguro de cancelar?"
        buttons={[
          {
            buttonEvent: () => {
              setModalCancelarMeta(false);
              setDialogoAgregarCustomMeta(true);
            },
            buttonName: "No",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleCancelarMetadata,
            buttonName: "Si",
            buttonColor: "primary",
          },
        ]}
      />

      {/*dialogo modificar FIRMANTES*/}
      <DialogComp
        open={modalEditarFirmante}
        maxWidth={"md"}
        fullWidth={true}
        title="MODIFICAR FIRMANTE FRECUENTE"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="nombre"
                name="nombre"
                label="Nombre(s)"
                value={datosModificar.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInputModificar}
                inputProps={{ maxLength: 30 }}
                error={datosModificar.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="apellidos"
                name="apellidos"
                label="Apellido(s)"
                value={datosModificar.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInputModificar}
                inputProps={{ maxLength: 60 }}
                error={datosModificar.apellidos.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="correo"
                name="correo"
                label="Correo electrónico"
                value={datosModificar.correo}
                fullWidth
                autoComplete="email"
                onChange={handleInputModificar}
                onBlur={() => {
                  if (datosModificar.correo) {
                    validarMail(datosModificar.correo);
                  }
                }}
                inputProps={{ maxLength: 60 }}
                error={datosModificar.correo.length > 2 ? false : true}
              />
              <font color="red">{textoValidacionMail}</font>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="grupo"
                name="grupo"
                label="Grupo"
                value={datosModificar.grupo}
                fullWidth
                autoComplete="grupo"
                onChange={handleInputModificar}
                inputProps={{ maxLength: 60 }}
                error={datosModificar.grupo.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.codigo}>
              <font color={"gray"} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "80%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={datosModificar.codigo === "1" ? "us" : "mx"}
                //value={datosModificar.codigo}
                onChange={(phone) => {
                  //setEstiloCelular("gray");
                  setDatosModificar({
                    ...datosModificar,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                style={{ width: "100%" }}
                id="celular"
                name="celular"
                type={"text"}
                label="Celular"
                value={datosModificar.celular}
                onChange={handleInputModificar}
                inputProps={{ maxLength: 15 }}
                error={
                  valueModificar == 2 &&
                    datosModificar.celular &&
                    datosModificar.celular.length > 9
                    ? false
                    : true
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                id="Compañia"
                name="Compañia"
                label="Empresa"
                value={datosModificar.Compañia}
                fullWidth
                onChange={handleInputModificar}
                inputProps={{ maxLength: 60 }}
                error={datosModificar.Compañia.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="rfc"
                name="rfc"
                label="RFC firmante"
                value={datosModificar.rfc}
                fullWidth
                autoComplete="rfc"
                onChange={handleInputModificar}
                inputProps={{ maxLength: 13 }}
              />
              <FormHelperText style={{ color: "red" }}>
                {alertRFCModificar}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={valueModificar}
                  //onChange={handleChange}
                  onChange={(e) => {
                    setValueModificar(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo electrónico"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectTipos />
            </Grid>
            <Grid item xs={12} sm={4}>
              {tipoActuacion.codigo === 5 ? (
                <TextField
                  required
                  id="rol"
                  name="rol"
                  label="Tipo de actuación"
                  value={datosModificar.rol}
                  fullWidth
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.rol.length > 2 ? false : true}
                />
              ) : null}
            </Grid>
            <Grid item xs={3} sm={6}>
              <Button
                color="success"
                size="md"
                type="button"
                onClick={editFirmante}
                className={classes.button}
                disabled={disabledModificar}
              >
                Modificar firmante
              </Button>
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleUpdateFirmante,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      <DialogComp
        open={modalNotificados}
        maxWidth={"md"}
        fullWidth={true}
        title="REGISTRAR OBSERVADOR FRECUENTE"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={6}>
              <TextField
                variant="outlined"
                margin="dense"
                required
                id="nombre"
                name="nombre"
                label="Nombre(s)"
                value={datosNotificado.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInputN}
                inputProps={{ maxLength: 30 }}
                error={datosNotificado.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                variant="outlined"
                margin="dense"
                id="apellidos"
                name="apellidos"
                label="Apellido(s)"
                value={datosNotificado.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInputN}
                inputProps={{ maxLength: 60 }}
                error={datosNotificado.apellidos.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                variant="outlined"
                margin="dense"
                id="correo"
                name="correo"
                label="Correo electrónico"
                value={datosNotificado.correo}
                fullWidth
                autoComplete="email"
                onChange={handleInputN}
                onBlur={() => {
                  if (datosNotificado.correo) {
                    validarMail(datosNotificado.correo);
                  }
                }}
                inputProps={{ maxLength: 60 }}
                error={datosNotificado.correo.length > 2 ? false : true}
              />
              <font color="red">{textoValidacionMail}</font>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                variant="outlined"
                margin="dense"
                id="grupo"
                name="grupo"
                label="Grupo"
                value={datosNotificado.grupo}
                fullWidth
                autoComplete="grupo"
                onChange={handleInputN}
                inputProps={{ maxLength: 60 }}
                error={datosNotificado.grupo.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.codigo}>
              <font color={"gray"} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "80%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={"mx"}
                value={datosNotificado.codigo}
                onChange={(phone) => {
                  //setEstiloCelular("gray");
                  setDatosNotificado({
                    ...datosNotificado,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                margin="dense"
                style={{ width: "100%" }}
                id="celular"
                name="celular"
                label="Celular"
                value={datosNotificado.celular}
                onChange={handleInputN}
                inputProps={{ maxLength: 15 }}
                error={datosNotificado.celular.length > 9 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={value}
                  //onChange={handleChange}
                  onChange={(e) => {
                    setValue(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={6}>
              <Button
                color="success"
                size="md"
                type="button"
                onClick={registerNewNotificado}
                className={classes.button}
                disabled={disabledNotificado}
              >
                agregar observador
              </Button>
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleRegistrarNotificado,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo ELIMINAR NOTIFICADO*/}

      <DialogComp
        open={modalEliminarNotificado}
        title="¿ELIMINAR OBSERVADOR?"
        content={
          notificado ? notificado.name + " " + notificado.last_name : null
        }
        buttons={[
          {
            buttonEvent: () => setModalEliminarNotificado(false),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleEliminarNotificado,
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />

      {/*dialogo modificar NOTIFICADOS*/}

      <DialogComp
        open={modalEditarNotificado}
        maxWidth={"md"}
        fullWidth={true}
        title="MODIFICAR OBSERVADOR FRECUENTE"
        content={
          <Grid container spacing={2}>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="nombre"
                name="nombre"
                label="Nombre(s)"
                value={datosModificarN.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInputModificarN}
                inputProps={{ maxLength: 30 }}
                error={datosModificarN.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="apellidos"
                name="apellidos"
                label="Apellido(s)"
                value={datosModificarN.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInputModificarN}
                inputProps={{ maxLength: 60 }}
                error={datosModificarN.apellidos.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="correo"
                name="correo"
                label="Correo electrónico"
                value={datosModificarN.correo}
                fullWidth
                autoComplete="email"
                onChange={handleInputModificarN}
                onBlur={() => {
                  if (datosModificarN.correo) {
                    validarMail(datosModificarN.correo);
                  }
                }}
                inputProps={{ maxLength: 60 }}
                error={datosModificarN.correo.length > 2 ? false : true}
              />
              <font color="red">{textoValidacionMail}</font>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="grupo"
                name="grupo"
                label="Grupo"
                value={datosModificarN.grupo}
                fullWidth
                autoComplete="grupo"
                onChange={handleInputModificarN}
                inputProps={{ maxLength: 60 }}
                error={datosModificarN.grupo.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.codigo}>
              <font color={"gray"} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "80%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={datosModificarN.codigo === "1" ? "us" : "mx"}
                //country={"mx"}
                //value={datosModificarN.codigo}
                onChange={(phone) => {
                  //setEstiloCelular("gray");
                  setDatosModificarN({
                    ...datosModificarN,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                style={{ width: "100%" }}
                id="celular"
                name="celular"
                label="Celular"
                value={datosModificarN.celular}
                onChange={handleInputModificarN}
                inputProps={{ maxLength: 10 }}
                error={
                  (datosModificarN.celular && datosModificarN.celular.length) >
                    9
                    ? false
                    : true
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={valueModificar}
                  //onChange={handleChange}
                  onChange={(e) => {
                    setValueModificar(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={6}>
              <Button
                color="success"
                size="md"
                type="button"
                onClick={editNotificado}
                className={classes.button}
                disabled={disabledModificarN}
              >
                Modificar Notificado
              </Button>
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleUpdateNotificado,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*dialogo LOTE FIRMANTES*/}
      <DialogComp
        open={modalLote}
        maxWidth={"md"}
        fullWidth={true}
        title="Instrucciones"
        content={
          <>
            <DialogContentText style={{ color: "black" }}>
              <p style={{ margin: 0 }}>
                Elabore un documento en formato .xlsx con las siguientes
                columnas:
              </p>
              <ul style={{ margin: 0 }}>
                <li>
                  <b>nombre</b> (Texto. Nombre(s) del firmante frecuente)
                </li>
                <li>
                  <b>apellido</b> (Texto. Apellidos(s) del firmante frecuente)
                </li>
                <li>
                  <b>codigo_pais</b> (Número de 1 a 3 dígitos. Ej. 52)
                </li>
                <li>
                  <b>telefono</b> (Número de 10 dígitos sin espacios ni guiones)
                </li>
                <li>
                  <b>email</b> (No puede estar duplicado, es un campo único de
                  cada firmante)
                </li>
                <li>
                  <b>compañia</b> (Texto. Compañía a la que pertenece el
                  firmante frecuente)
                </li>
                <li>
                  <b>codigo_rol</b> (Número entero. <b>1</b>: "Por su Propio
                  Derecho", <b>2</b>: "Representante Legal", <b>3</b>:
                  "Testigo", <b>4</b>:"Visto Bueno", <b>5</b>:"Otro", <b>6</b>
                  :"Apoderado Legal")
                </li>
                <li>
                  <b>rol</b> (Texto. la descripción del rol, para el codigo_rol
                  = 5)
                </li>
                <li>
                  <b>notificacion</b> (Número entrero. <b>1</b>: Correo
                  electrónico, <b>2</b>: SMS)
                </li>
                <li>
                  <b>grupo</b> (Texto. Nombre del grupo al que pertenece el
                  usuario)
                </li>
              </ul>
              <p style={{ margin: 0 }}>--</p>
              <p style={{ margin: 0, color: "darkblue" }}>
                <b>
                  <a
                    style={{ color: "darkblue" }}
                    href={formatofirmantes}
                    download="formatofirmantes.xlsx"
                  >
                    [Descargar formato guía]
                  </a>
                </b>
              </p>
            </DialogContentText>
            <input
              id="contained-button-excel"
              type="file"
              accept=".xlsx"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <label htmlFor="contained-button-excel">
              <Button
                variant="contained"
                color="success"
                size="small"
                component="span"
              >
                <CloudUploadIcon />
                cargar archivo (.xlsx)
              </Button>
            </label>
            <hr />
            <ReactExcel
              initialData={initialData}
              onSheetUpdate={(currentSheet) => setCurrentSheet(currentSheet)}
              activeSheetClassName="active-sheet"
              reactExcelClassName="react-excel"
            />
            <hr />
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelDialogLote,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => saveFirmantes(),
            buttonName: "Importar",
            buttonColor: "primary",
          },
        ]}
      />

      {/*dialogo LOTE NOTIFICADOS*/}
      <DialogComp
        open={modalLoteN}
        maxWidth={"md"}
        fullWidth={true}
        title="Instrucciones"
        content={
          <>
            <DialogContentText style={{ color: "black" }}>
              <p style={{ margin: 0 }}>
                Elabore un documento en formato .xlsx con las siguientes
                columnas:
              </p>
              <ul style={{ margin: 0 }}>
                <li>
                  <b>nombre</b> (Texto. Nombre(s) del firmante frecuente)
                </li>
                <li>
                  <b>apellido</b> (Texto. Apellidos(s) del firmante frecuente)
                </li>
                <li>
                  <b>codigo_pais</b> (Número de 1 a 3 dígitos. Ej. 52)
                </li>
                <li>
                  <b>telefono</b> (Número de 10 dígitos sin espacios ni guiones)
                </li>
                <li>
                  <b>email</b> (No puede estar duplicado, es un campo único de
                  cada notificado)
                </li>
                <li>
                  <b>notificacion</b> (Número entrero. <b>1</b>: Correo
                  electrónico, <b>2</b>: SMS)
                </li>
                <li>
                  <b>grupo</b> (Texto. Nombre del grupo al que pertenece el
                  usuario)
                </li>
              </ul>
              <p style={{ margin: 0 }}>--</p>
              <p style={{ margin: 0, color: "darkblue" }}>
                <b>
                  <a
                    style={{ color: "darkblue" }}
                    href={formatonotificados}
                    download="formatonotificados.xlsx"
                  >
                    [Descargar formato guía]
                  </a>
                </b>
              </p>
            </DialogContentText>
            <input
              id="contained-button-excel"
              type="file"
              accept=".xlsx"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <label htmlFor="contained-button-excel">
              <Button
                variant="contained"
                color="success"
                size="small"
                component="span"
              >
                <CloudUploadIcon />
                cargar archivo (.xlsx)
              </Button>
            </label>
            <hr />
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelDialogLoteN,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => saveNotificados(),
            buttonName: "Importar",
            buttonColor: "primary",
          },
        ]}
      />

      {/*dialogo ELIMINAR metadatos*/}
      <DialogComp
        open={dialogoEliminarCustomMeta}
        maxWidth={"md"}
        title="¿Está seguro de eliminar el catálogo de campos?"
        buttons={[
          {
            buttonEvent: () => setDialogoEliminarCustomMeta(false),
            buttonName: "Cancelar",
            buttonColor: "primary",
          },
          {
            buttonEvent: () => handleEliminarCustomMeta(customMeta._id),
            buttonName: "Eliminar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* fin dialogo eliminar METADATOS*/}

      {/*dialogo AGREGAR metadatos*/}
      <DialogComp
        open={dialogoAgregarCustomMeta}
        maxWidth={"md"}
        title="INFORMACIÓN DEL NUEVO CATÁLOGO DE DATOS"
        content={
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                disabled={disabledNameMeta}
                margin="dense"
                id="name"
                label="Nombre del catálogo"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        id="tooltip-top"
                        title="Editar Nombre del catálogo"
                        placement="top"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setdisabledNameMeta(false);
                            setEditname(true);
                          }}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={nombreMetadato}
                onChange={(e) => {
                  setNombreMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="key"
                label="Nombre del campo adicional"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={keyMetadato}
                onChange={(e) => {
                  setKeyMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                fullWidth
                margin="dense"
                id="value"
                label="Descripción del campo adicional"
                variant="outlined"
                inputProps={{
                  maxlength: 128,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={valueMetadato}
                onChange={(e) => {
                  setValueMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {keyMetadato && valueMetadato ? (
                <Tooltip title="Agregar campo adicional" placement="top">
                  <IconButton
                    aria-label="agregar"
                    onClick={() => {
                      setFieldsMetadato([
                        ...fieldsMetadato,
                        { key: keyMetadato, value: valueMetadato },
                      ]);
                      setValueMetadato("");
                      setKeyMetadato("");
                      setdisabledNameMeta(true);
                      setEditname(false);
                    }}
                  >
                    <AddCircleOutlineIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {fieldsMetadato.map(({ key, value }, index) => (
                <>
                  <li>
                    <b>{key}</b>({value})
                    <Tooltip title="Eliminar campo" placement="top">
                      <IconButton
                        aria-label="eliminar"
                        onClick={() => {
                          let arr = [];
                          for (var i = 0; i < fieldsMetadato.length; i++) {
                            if (fieldsMetadato[i].key != key)
                              arr.push(fieldsMetadato[i]);
                          }
                          setFieldsMetadato(arr);
                        }}
                      >
                        <DeleteIcon
                          className={
                            classes2.tableActionButtonIcon + " " + classes2.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </li>
                </>
              ))}
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleGuardarCustomMeta,
            buttonName: "Guardar",
            buttonColor: "success",
            buttonDisabled: buttonCustomMeta,
          },
          {
            buttonEvent: handleCancelConfirm,
            buttonName: "Cancelar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* fin dialogo agregar METADATOS*/}

      {/*dialogo MODIFICAR metadatos*/}
      <DialogComp
        open={dialogoModificarCustomMeta}
        maxWidth={"md"}
        title="INFORMACIÓN DE DATOS ADICIONALES"
        content={
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Nombre"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={nombreMetadato}
                onChange={(e) => {
                  setNombreMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="key"
                label="Campo adicional"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={keyMetadato}
                onChange={(e) => {
                  setKeyMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="value"
                label="Descripción"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                value={valueMetadato}
                onChange={(e) => {
                  setValueMetadato(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {keyMetadato && valueMetadato ? (
                <Tooltip title="Agregar campo" placement="top">
                  <IconButton
                    aria-label="agregar"
                    onClick={() => {
                      setFieldsMetadato([
                        ...fieldsMetadato,
                        { key: keyMetadato, value: valueMetadato },
                      ]);
                      setValueMetadato("");
                      setKeyMetadato("");
                    }}
                  >
                    <AddCircleOutlineIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {fieldsMetadato.map(({ key, value }, index) => (
                <>
                  <li>
                    <b>{key}</b>({value})
                    <Tooltip title="Eliminar campo" placement="top">
                      <IconButton
                        aria-label="eliminar"
                        onClick={() => {
                          let arr = [];
                          for (var i = 0; i < fieldsMetadato.length; i++) {
                            if (fieldsMetadato[i].key != key)
                              arr.push(fieldsMetadato[i]);
                          }
                          setFieldsMetadato(arr);
                        }}
                      >
                        <DeleteIcon
                          className={
                            classes2.tableActionButtonIcon + " " + classes2.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </li>
                </>
              ))}
            </Grid>
            <Grid item xs={12}>
              {nombreMetadato && fieldsMetadato.length > 0 ? (
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  component="span"
                  onClick={() => {
                    handleModificarCustomMeta();
                  }}
                >
                  Modificar
                </Button>
              ) : null}
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: () => {
              setDialogoModificarCustomMeta(false);
              setNombreMetadato("");
              setFieldsMetadato([]);
            },
            buttonName: "Cancelar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* fin dialogo MODIFICAR METADATOS*/}
    </React.Fragment>
  );
}
