import React, { useEffect, useRef, useState } from "react";
import { Image } from "react";
import TextField from "@material-ui/core/TextField";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import frente from "./frente.jpg";
import { Alert } from "@material-ui/lab";
import reverso from "./reverso.jpg";
import { createWorker } from "tesseract.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Switch, Tooltip } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import takepicture from "./take-frente.jpg";
import takepicture1 from "./take-reverso.jpg";
import scanpicture from "./scan.gif";
import { makeStyles } from "@material-ui/core/styles";
import { loadModels, getFullFaceDescription } from "./face";
import * as faceapi from "face-api.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropRotateIcon from "@material-ui/icons/CropRotate";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { isMobile } from "react-device-detect";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { DialogComp } from "components/Dialog/DialogComp";
import { green } from "@material-ui/core/colors";
import "./SubirId.css";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import ImagePicker from "components/ImagePicker";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SubirId(props) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isLogged } = props;
  const classes = useStyles();

  const cropperRef = useRef();

  useEffect(() => {
    const token = sessionStorage.signToken;
    //tipos
    axios
      .get(`${process.env.REACT_APP_URL}api/firmas/photoid/types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTiposId(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    //firmante
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/${props.idfirmante}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("firmante:", res.data);
        setFirmante(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [disabledContinue, setdisabledContinue] = useState(false);
  const [disabledContinue2, setdisabledContinue2] = useState(false);

  const [checkedP, setcheckedP] = useState(false);
  const [checkedB, setcheckedB] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [isPasaporte, setIsPasaporte] = useState(false);
  const [isINE, setIsINE] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [progrees, setProgrees] = useState(false);

  const [frontBase64, setFrontBase64] = useState("");
  const [backBase64, setBackBase64] = useState("");
  const [passportBase64, setPassportBase64] = useState("");

  // const [state, setstate] = useState(initialState);

  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [tiposId, setTiposId] = useState("");
  const [typeId, setTypeId] = useState(0);
  const [firmante, setFirmante] = useState("");
  const [fotoEncontrada, setFotoEncontrada] = useState("");
  const [encontreFoto, setEntontreFoto] = useState("");
  const [dialogoINE, setDialogoINE] = useState(false);
  const [dialogoPasaporte, setDialogoPasaporte] = useState(false);
  const [dialogoCaptura, setDialogoCaptura] = useState(false);
  const [dialogoInstruccion, setDialogoInstrucion] = useState(false);
  const [step, setStep] = useState(0);
  const [croppedFront, setCroppedFront] = useState("");
  const [reiniciar, setReiniciar] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);

  //snakbar
  const [messageError, setMessageError] = useState("");
  const [colorMessageFront, setcolorMessageFront] = useState("red");
  const [colorMessageBack, setcolorMessageBack] = useState("red");
  const [openAlert, setOpenAlert] = useState("");
  const [messageFront, setMessageFront] = useState("");
  const [messageBack, setMessageBack] = useState("");
  const [textoOCR, setTextoOCR] = useState("");

  useEffect(() => {
    setFront(frente);
    setBack(reverso);
    if (checkedB) {
      setShowSign(true);
      handleCancelarCargaINE(true);
    } else if (isINE) {
      setShowSign(false);
      setDialogoINE(true);
      setdisabledContinue(false);
      if (isMobile) {
        setDialogoInstrucion(true);
      }
    }
  }, [checkedB]);

  useEffect(() => {
    setFront(frente);
    setBack(reverso);
    if (checkedP) {
      setShowSign(true);
      handleCancelarCargaPasaporte(true);
    } else if (isPasaporte) {
      setShowSign(false);
      setDialogoPasaporte(true);
      setdisabledContinue2(false);

      if (isMobile) {
        setDialogoInstrucion(true);
      }
    }
  }, [checkedP]);

  useEffect(() => {
    let frontImgZ = document.getElementById("frontImgZ");
    let backImgZ = document.getElementById("backImgZ");
    if (front.length > 50) {
      frontImgZ.className = "myZoomyStyle";
      if (backImgZ) {
        backImgZ.className = "myZoomyStyle";
      }
    } else {
      frontImgZ.className = "";
      if (backImgZ) {
        backImgZ.className = "";
      }
    }
  }, [front]);

  const handleChangeSwitch = (typeChecked) => {
    typeChecked == "ine" ? setcheckedB(!checkedB) : setcheckedP(!checkedP);
  };

  function handleRotate() {
    cropperRef.current.cropper.rotate(90);
    //this.handleCropChange();
  }

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    setFront(dataUri);
    setStep(1);
    setDialogoCaptura(false);
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // console.log(dataUri);
  }

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 10000);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function eliminarDiacriticos(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function textoFrenteINE(texto) {
    var str = eliminarDiacriticos(texto);
    var valor = 0;

    if (str.search(firmante.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK", str.search(firmante.name.split(" ")[0]));
      valor++;
    }

    if (str.search(firmante.last_name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("LAST OK", str.search(firmante.last_name.split(" ")[0]));
      valor++;
    }

    if (valor > 0) {
      return true;
    }

    if (
      str.search("MÉXICO") !== -1 ||
      str.search("INSTITUTO") !== -1 ||
      str.search("NACIONAL") !== -1 ||
      str.search("ELECTORAL") !== -1 ||
      str.search("FEDERAL") !== -1 ||
      str.search("ELECTOR") !== -1 ||
      str.search("CLAVE") !== -1
    ) {
      console.log("mexico ok", str.search("MÉXICO"));
      valor++;
    }

    /*
  if (str.search("MÉXICO")!==-1) {
    valor++;
    console.log("mexico ok",str.search("MÉXICO"))
  }

*/
    console.log("valor INE FRENTE:", valor);
    if (valor > 0) {
      return true;
    }
    return false;
  }

  function textoFrentePasaporte(texto) {
    console.log("texto frente PASAPORTE");
    /*
  if(isMobile){
    console.log("MOBILE");
    return true;
  }
*/
    //var str = texto;
    var str = eliminarDiacriticos(texto);
    var valor = 0;

    if (str.search(firmante.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK", str.search(firmante.name.split(" ")[0]));
      valor++;
    }

    if (str.search(firmante.last_name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("LAST OK", str.search(firmante.last_name.split(" ")[0]));
      valor++;
    }

    if (str.search("PASSPORT") !== -1 || str.search("PASAPORTE") !== -1) {
      console.log("pasaporte ok");
      valor++;
    }
    console.log("valor PASAPORTE:", valor);
    if (valor > 1) {
      return true;
    }
    return false;
  }

  /*
  function textoFrente(texto){

    var str = texto;
    var valor=0;
    if(typeId===1 || typeId==="1"){
      //es INE

        if (str.search(firmante.name.toUpperCase().split(" ")[0])!==-1) {
          console.log("NAME OK",str.search(firmante.name.split(" ")[0]))
          valor++;
        }

        if (str.search(firmante.last_name.toUpperCase().split(" ")[0])!==-1) {
          console.log("LAST OK",str.search(firmante.last_name.split(" ")[0]))
          valor++;
        }

        if (str.search("MÉXICO")!==-1) {
          valor++;
          console.log("mexico ok",str.search("MÉXICO"))
        }

        if (str.search("INSTITUTO")!==-1) {
          console.log("INE OK")
          valor++;
        }

        if (str.search("NACIONAL")!==-1) {
          console.log("INE OK")
          valor++;
        }

        if (str.search("ELECTORAL")!==-1) {
          console.log("INE OK")
          valor++;
        }

        console.log("valor:",valor)
        if (valor>1){
          return(true);
        }

    }
    else{
      //es pasaporte
      if (str.search(firmante.name.toUpperCase().split(" ")[0])!==-1) {
        console.log("NAME OK")
        valor++;
      }

      if (str.search(firmante.last_name.toUpperCase().split(" ")[0])!==-1) {
        console.log("LAST OK",str.search(firmante.last_name))
        valor++;
      }

      if (str.search("PASSPORT")!==-1) {
        valor++;
      }
      if (str.search("PÁSSPORT")!==-1) {
        valor++;
      }
      if (str.search("Surnames")!==-1) {
        valor++;
      }


      console.log("valor:",valor)
      if (valor>1){
        return(true);
      }

    }
    return(false);
  }
*/

  function textoReverso(texto) {
    /*
    if(isMobile){
      return true;
    }
    */
    //var str = texto;
    var str = eliminarDiacriticos(texto);
    var valor = 0;
    //es INE REVERSO
    if (
      str.search("MEX") !== -1 ||
      str.search("INSTITUTO") !== -1 ||
      str.search("NACIONAL") !== -1 ||
      str.search("ELECTORAL") !== -1 ||
      str.search("FEDERAL") !== -1 ||
      str.search("ELECTOR") !== -1 ||
      str.search("CLAVE") !== -1
    ) {
      valor++;
    }

    if (str.search(firmante.name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("NAME OK");
      valor++;
    }

    if (str.search(firmante.last_name.toUpperCase().split(" ")[0]) !== -1) {
      console.log("LAST OK", str.search(firmante.last_name));
      valor++;
    }

    console.log("valor INE BACK:", valor);
    if (valor > 0) {
      return true;
    }
    return false;
  }

  const validarBack = (file) => {
    //reconocimiento
    setMessageBack("Por favor espere...");
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    var confidence, text;
    var Tesseract = window.Tesseract;
    const url = URL.createObjectURL(file);
    console.log(url);
    Tesseract.recognize(url, {
      lang: "spa",
    })
      .catch((err) => {
        console.error(err);
        showNotificacion(err);
      })
      .then((result) => {
        // Get Confidence score
        confidence = result.confidence;
        console.log("Confiabilidad:", confidence);

        // Get full output
        text = result.text;
        console.log("Texto:", text);
        setTextoOCR(text);

        // Get codes
        let pattern = /\b\w{10,10}\b/g;
        let patterns = result.text.match(pattern);
        console.log("Patrón:", patterns);

        //validación
        var id = "ID";

        if (confidence < 45) {
          setMessageBack("<b>Imagen inválida, por favor cargar nuevamente</b>");
          setcolorMessageBack("red");
          props.setReverso("");
          setBack(reverso);
        } else {
          var validacion = textoReverso(text);
          console.log("validacion", validacion);
          if (validacion === false) {
            setMessageBack(
              "<b>Imagen inválida, por favor cargar nuevamente</b>"
            );
            setcolorMessageBack("red");
            props.setReverso("");
            setBack(reverso);
            props.setValidacionId(false);
          } else {
            setMessageBack("<b>ID aceptado</b>");
            setcolorMessageBack("blue");
            props.setValidacionId(true);
          }
        }
        URL.revokeObjectURL(url);
        //fin validacion
      });

    console.log("FIN RECONOCIMIENTO");

    //fin reconocimiento
  };

  const handleImage = async (image) => {
    // console.log("DETECCIÓN DE FOTO:");
    await getFullFaceDescription(image).then((fullDesc) => {
      console.log(fullDesc);
      setFotoEncontrada(fullDesc);
    });
  };

  // const onCrop = (value, instance) => {
  //   setCroppedFront(instance);
  //   switch (value) {
  //     case "ine_front": {
  //       setFront(instance.getCroppedCanvas().toDataURL());
  //       setFrontBase64(instance.getCroppedCanvas().toDataURL());
  //       break;
  //     }
  //     case "ine_back": {
  //       setBackBase64(instance.getCroppedCanvas().toDataURL());
  //       break;
  //     }
  //     case "passport": {
  //       setPassportBase64(instance.getCroppedCanvas().toDataURL());
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  // };

  const handleFileRead = async (e, source) => {
    // const file = event.target.files[0];
    // const base64 = await convertBase64(file);
    // setFront(base64);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      source == "front" ? setFront(reader.result) : setBack(reader.result);
    };
    source == "front" ? setStep(1) : setStep(4);
  };

  // const handleFileReadBack = async (event) => {
  //   const file = event.target.files[0];
  //   console.log("File BACK");
  //   const base64 = await convertBase64(file);
  //   //props.setFrente(base64)
  //   setBack(base64);
  //   setStep(4);
  // };

  // const handleFileReadPasaporte = async (event) => {
  //   const file = event.target.files[0];
  //   console.log("File PASAPORTE");
  //   const base64 = await convertBase64(file);
  //   setFront(base64);
  //   setStep(1);
  // };

  const handleValidarFront = async () => {
    setdisabledContinue(true);
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);
    setFrontBase64(lastCropped);

    setMessageFront(
      "<b>POR FAVOR NO CIERRE ESTA VENTANA.<br /> Este proceso puede tardar varios minutos.</b>"
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    setStep(2);
    await loadModels();
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    //console.log("IMG",dataURLtoBlob(url));
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Resultado de face Api", fullDesc);
    if (fullDesc) {
      /*OCR EN SERVER FRONT INE*/
      var bl = dataURLtoBlob(url);
      var textresult = await getOCR(bl);
      console.log("texto:", textresult);
      setTextoOCR(textresult.toUpperCase());
      var validacion = textoFrenteINE(textresult.toUpperCase());
      console.log("validacion", validacion);
      if (validacion === false) {
        setProgrees(false);
        setMessageFront("<b>Imagen no válida, por favor carga nuevamente</b>");
        setcolorMessageFront("red");
        props.setFrente("");
        setFront(frente);
        props.setValidacionId(false);
        setReiniciar(true);
        props.setOcrFront("");
      } else {
        setProgrees(false);
        setMessageFront("<b>Credencial para votar aceptada</b>");
        setcolorMessageFront("blue");
        props.setOcrFront(textresult);
        props.setValidacionId(true);
        props.setFrente(bl);
        setFront(lastCropped);
        setStep(3);
      }
      /*FIN OCR EN SERVER*/

      /* OCR EN EL FRONT (ANTERIOR) */
      /*
      var confidence, text;
      var Tesseract = window.Tesseract;
      Tesseract.recognize(url, {
        lang: "spa",
      })
        .catch((err) => {
          console.error(err);
        })
        .then((result) => {

          confidence = result.confidence;
          console.log("Confiabilidad:", confidence);


          text = result.text;
          console.log("Texto:", text);
          setTextoOCR(text.toUpperCase());


          let pattern = /\b\w{10,10}\b/g;
          let patterns = result.text.match(pattern);
          console.log("Patrón:", patterns);

          if (confidence < 45) {
            setMessageFront(
              "<b>Imagen no válida, por favor carga nuevamente (debe mejorar la nitidez)</b>"
            );
            setcolorMessageFront("red");
            props.setFrente("");
            setFront(frente);
            props.setValidacionId(false);
          } else {
            var validacion = textoFrenteINE(text.toUpperCase());
            console.log("validacion", validacion);
            if (validacion === false) {
              setMessageFront(
                "<b>Imagen no válida, por favor carga nuevamente</b>"
              );
              setcolorMessageFront("red");
              props.setFrente("");
              setFront(frente);
              props.setValidacionId(false);
              setReiniciar(true);
            } else {
              setMessageFront("<b>INE/IFE aceptado</b>");
              setcolorMessageFront("blue");
              props.setValidacionId(true);
              props.setFrente(croppedFront);
              setFront(croppedFront);
              setStep(3);
            }
          }

        });
*/
      /*fin ocr en el front*/
    } else {
      setProgrees(false);
      setMessageFront(
        "Credencial para votar inválida, no se reconoce el rostro en la identificación, por favor cargar nuevamente"
      );
      setcolorMessageFront("red");
      props.setFrente("");
      setFront(frente);
      props.setValidacionId(false);
      setReiniciar(true);
    }
  };

  const handleValidarBack = async () => {
    setMessageBack(
      "<b>POR FAVOR NO CIERRE ESTA VENTANA.<br /> Este proceso puede tardar varios minutos.</b>"
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);
    setBackBase64(lastCropped);
    setStep(5);
    /*OCR EN SERVER BACK INE/IFE*/
    var bl = dataURLtoBlob(url);
    var textresult = await getOCR(bl);
    // console.log("texto:", textresult);
    setTextoOCR(textresult.toUpperCase());
    var validacion = textoReverso(textresult.toUpperCase());
    if (validacion === false) {
      setMessageBack("<b>Imagen no válida, por favor cargar nuevamente</b>");
      setcolorMessageBack("red");
      props.setReverso("");
      setBack(reverso);
      props.setValidacionId(false);
      setStep(5);
      setReiniciar(true);
      props.setOcrBack("");
    } else {
      setMessageBack("ID aceptado");
      setcolorMessageBack("blue");
      setBack(lastCropped);
      props.setOcrBack(textresult);
      props.setReverso(bl);
      props.setValidacionId(true);
      setDialogoINE(false);
      if (isLogged && user.my_assets) {
        setopenDialog(true);
        return false;
      }
    }
    /*FIN OCR EN SERVER */

    /*OCR EN EL FRONT*/
    /*
    var confidence, text;
    var Tesseract = window.Tesseract;
    const url = croppedFront;

    Tesseract.recognize(url, {
      lang: "spa",
    })
      .catch((err) => {
        console.error(err);
      })
      .then((result) => {
        confidence = result.confidence;
        console.log("Confiabilidad:", confidence);

        text = result.text;
        console.log("Texto:", text);
        setTextoOCR(text.toUpperCase());

        let pattern = /\b\w{10,10}\b/g;
        let patterns = result.text.match(pattern);
        console.log("Patrón:", patterns);


        var id = "ID";

        if (confidence < 45) {
          setMessageBack(
            "<b>Imagen no válida, por favor carga nuevamente (debe mejorar la nitidez)</b>"
          );
          setcolorMessageBack("red");
          props.setReverso("");
          props.setValidacionId(false);
          setBack(reverso);
          setStep(5);
          setReiniciar(true);
        } else {
          var validacion = textoReverso(text.toUpperCase());
          console.log("validacion", validacion);
          if (validacion === false) {
            setMessageBack(
              "<b>Imagen no válida, por favor cargar nuevamente</b>"
            );
            setcolorMessageBack("red");
            props.setReverso("");
            setBack(reverso);
            props.setValidacionId(false);
            setStep(5);
            setReiniciar(true);
          } else {
            setMessageBack(id + " aceptado");
            setcolorMessageBack("blue");
            setBack(croppedFront);
            props.setReverso(croppedFront);
            props.setValidacionId(true);
            setDialogoINE(false);

          }
        }

      });
      */
    /*FIN OCR EN EL FRONT*/
    console.log("FIN RECONOCIMIENTO");
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function getOCR(archivo) {
    console.log("archivoOlv", archivo);
    let urlDocumento = `${process.env.REACT_APP_URL}api/ocrid`;
    const tokenCC = sessionStorage.signToken;
    //alert(archivo);
    var data = new FormData();
    data.append("image", archivo);

    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log(error);
          setMessageFront(
            "<b>Hubo un error al cargar la imagen, por favor intente con una distinta.</b>"
          );
          showNotificacion(
            "Hubo un error al cargar la imagen, por favor intente con una distinta"
          );
          // alert(error);
        }
      );
    });
  }

  const handleValidarPasaporte = async () => {
    setdisabledContinue2(true);
    let lastCropped = croppedFront.getCroppedCanvas().toDataURL();
    const url = lastCropped;
    setCroppedFront(lastCropped);
    setPassportBase64(lastCropped);

    setMessageFront(
      "<b>POR FAVOR NO CIERRE ESTA VENTANA. <br /> Este proceso puede tardar varios minutos.</b>"
    );
    showNotificacion(
      "Validando imagen, por favor espere un momento. Al culminar la validación podrá continuar."
    );
    setStep(2);
    console.log("File PASAPORTE");
    await loadModels();
    let scoreThreshold = 0.5;
    const inputSize = 512;
    const OPTION = new faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold,
    });
    const useTinyModel = true;
    // fetch image to api
    let img = await faceapi.fetchImage(url);
    let fullDesc = await faceapi.detectSingleFace(img, OPTION);
    console.log("Foto en validar pasaporte:", fullDesc);
    if (fullDesc) {
      /*OCR EN SERVER PASAPORTE*/
      var bl = dataURLtoBlob(url);
      var textresult = await getOCR(bl);
      console.log("texto:", textresult);
      setTextoOCR(textresult.toUpperCase());
      var validacion = textoFrenteINE(textresult.toUpperCase());
      console.log("validacion", validacion);
      if (validacion === false) {
        setMessageFront(
          "<b>Pasaporte inválido, por favor cargar nuevamente</b>"
        );
        setcolorMessageFront("red");
        props.setFrente("");
        setFront(frente);
        props.setValidacionId(false);
        setReiniciar(true);
        props.setOcrFront("");
      } else {
        setMessageFront("<b>Pasaporte aceptado</b>");
        setcolorMessageFront("blue");
        props.setFrente(bl);
        props.setOcrFront(textresult);
        props.setValidacionId(true);
        setFront(lastCropped);
        setDialogoPasaporte(false);
        if (isLogged && user.my_assets) {
          setopenDialog(true);
          return false;
        }
      }
      /*FIN OCR EN SERVER */

      /*OCR EN EL FRONT*/
      /*
      var confidence, text;
      var Tesseract = window.Tesseract;
      Tesseract.recognize(url, {
        lang: "spa",
      })
        .catch((err) => {
          console.error(err);
        })
        .then((result) => {

          confidence = result.confidence;
          console.log("Confiabilidad:", confidence);


          text = result.text;
          console.log("Texto:", text);
          setTextoOCR(text.toUpperCase());


          let pattern = /\b\w{10,10}\b/g;
          let patterns = result.text.match(pattern);
          console.log("Patrón:", patterns);

          if (confidence < 45) {
            setMessageFront(
              "<b>Pasaporte inválido, por favor cargar nuevamente (debe mejorar la nitidez de la imágen)</b>"
            );
            setcolorMessageFront("red");
            props.setFrente("");
            setFront(frente);
            props.setValidacionId(false);
            setReiniciar(true);
          } else {
            var validacion = textoFrentePasaporte(text.toUpperCase());
            console.log("validacion", validacion);
            if (validacion === false) {
              setMessageFront(
                "<b>Pasaporte inválido, por favor cargar nuevamente</b>"
              );
              setcolorMessageFront("red");
              props.setFrente("");
              setFront(frente);
              props.setValidacionId(false);
              setReiniciar(true);
            } else {
              setMessageFront("<b>Pasaporte aceptado</b>");
              setcolorMessageFront("blue");
              props.setValidacionId(true);
              props.setFrente(croppedFront);
              setFront(croppedFront);
              setDialogoPasaporte(false);

            }
          }

        });
        */
      /* FIN OCR EN EL FRONT*/
    } else {
      setMessageFront("<b>Pasaporte inválido, por favor cargar nuevamente</b>");
      setcolorMessageFront("red");
      props.setFrente("");
      setFront(frente);
      props.setValidacionId(false);
      setReiniciar(true);
    }
  };

  const handleFileRead1_sin = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    props.setFrente(base64);
    setFront(base64);
    console.log("frente");
    if (typeId === 1 || typeId === "1") {
    } else {
      props.setValidacionId(true);
    }
  };

  const handleFileRead2_sin = async (event) => {
    const file = event.target.files[0];
    if (typeId === 1 || typeId === "1") {
      const base64 = await convertBase64(file);
      props.setReverso(base64);
      setBack(base64);
      console.log("reverso");
      props.setValidacionId(true);
    } else {
      setMessageBack("No es requerido el reverso");
      props.setReverso("");
      setBack(reverso);
    }
  };

  const handleFileRead2 = async (event) => {
    const file = event.target.files[0];
    if (typeId === 1 || typeId === "1") {
      validarBack(file);
    } else {
      setMessageBack("No es requerido el reverso");
      props.setReverso("");
      setBack(reverso);
    }
    const base64 = await convertBase64(file);
    props.setReverso(base64);
    setBack(base64);
    console.log("reverso");
  };

  const handleConfirmarGuardarINE = async (value) => {
    if (value === 1) {
      setProgrees(true);
      setopenDialog(false);
      await guardarDataPredeterminada("ine_front", frontBase64);
      await guardarDataPredeterminada("ine_back", backBase64);
      setProgrees(false);
      return false;
    }
    setopenDialog(false);
  };

  const handleConfirmarGuardarPasaporte = async (value) => {
    if (value === 1) {
      setProgrees(true);
      setopenDialog(false);
      await guardarDataPredeterminada("pasaporte", passportBase64);
      setProgrees(false);
      return false;
    }
    setopenDialog(false);
  };

  const guardarDataPredeterminada = async (asset, value) => {
    let token = sessionStorage.getItem("Token");
    const URL = `${process.env.REACT_APP_URL}api/customassets`;
    var FormData = require("form-data");
    var data = new FormData();
    data.append(asset, value);
    var config = {
      method: "post",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
      data: data,
    };
    return new Promise(function (resolve, reject) {
      axios(config).then(
        ({ data }) => {
          switch (asset) {
            case "ine_front": {
              dispatch({
                type: "SET_INE_FRONT",
                payload: data.ine_front,
              });
              break;
            }
            case "ine_back": {
              dispatch({
                type: "SET_INE_BACK",
                payload: data.ine_back,
              });
              break;
            }
            default:
              break;
          }
          resolve(console.log("All complete"));
        },
        (error) => {
          reject(error);
          console.log(error);
          showNotificacion(error);
          setProgrees(false);
        }
      );
    });
  };

  const handleChange = (event) => {
    setStep(0);
    setTypeId(event.target.value);
    props.setTypeId(event.target.value);
    props.setFrente("");
    setFront(frente);
    props.setReverso("");
    setCroppedFront("");
    setBack(reverso);
    setMessageFront("");
    setMessageBack("");
    props.setValidacionId(false);

    switch (event.target.value) {
      case "1":
        //alert("INE/IFE");
        handleUsarInePrecargada();
        break;
      case "2":
        handleUsarPasaportePrecargado();
        break;
    }
  };
  const handleUsarInePrecargada = () => {
    if (user.my_assets && user.my_assets.ine_back) {
      setFront(user.my_assets.ine_front);
      setBack(user.my_assets.ine_back);
      setcheckedB(true);
      setIsINE(true);
      props.setFrente(user.my_assets.ine_front);
      props.setReverso(user.my_assets.ine_back);
      props.setValidacionId(true);
    } else {
      setDialogoINE(true);
      setdisabledContinue(false);
      if (isMobile) {
        setDialogoInstrucion(true);
      }
    }
  };

  const handleUsarPasaportePrecargado = () => {
    if (user.my_assets && user.my_assets.pasaporte) {
      setFront(user.my_assets.pasaporte);
      setBack("");
      setcheckedP(true);
      setIsPasaporte(true);
      props.setFrente(user.my_assets.ine_front);
      props.setValidacionId(true);
    } else {
      setDialogoPasaporte(true);
      setdisabledContinue2(false);

      //setDialogoInstrucion(true);
      if (isMobile) {
        setDialogoInstrucion(true);
      }
    }
  };

  const handleCancelarCargaINE = (value) => {
    setStep(0);
    setDialogoINE(false);
    setMessageFront("");
    setMessageBack("");
    setcolorMessageFront("blue");
    setReiniciar(false);
    setcheckedB(true);
    if (!value) {
      setFront(frente);
      setBack(reverso);
      setcheckedB(false);
      setDisableSelect(false);
      setTypeId(0);
      props.setFrente("");
      props.setReverso("");
      props.setValidacionId(false);
    } else if (user.my_assets.ine_back) {
      setFront(user.my_assets.ine_front);
      setBack(user.my_assets.ine_back);
    }
  };

  const handleCancelarCargaPasaporte = (value) => {
    setStep(0);
    setDialogoPasaporte(false);
    setMessageFront("");
    setMessageBack("");
    setcolorMessageFront("blue");
    setReiniciar(false);
    setcheckedP(true);
    if (!value) {
      setFront(frente);
      setBack(reverso);
      setcheckedP(false);
      setDisableSelect(false);
      setTypeId(0);
      props.setFrente("");
      props.setReverso("");
      props.setOcrFront("");
      props.setValidacionId(false);
    } else if (user.my_assets.pasaporte) {
      setFront(user.my_assets.pasaporte);
      setBack("");
    }
  };

  function SelectTipos() {
    var t = [];
    Object.keys(tiposId).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposId[n],
      };
    });
    return (
      <React.Fragment>
        <Select
          labelId="tipoid"
          id="tipoid"
          value={typeId}
          // disabled={disableSelect}
          onChange={handleChange}
        >
          <MenuItem key={0} value={0}>
            {"Seleccione tipo de identificación"}
          </MenuItem>

          {t.map(({ codigo, valor }, index) => (
            <MenuItem key={index} value={codigo}>
              {valor}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Snackbar
        place="bc"
        color="warning"
        icon={InfoIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <SelectTipos />
        </Grid>
      </Grid>
      <br />

      {/*Validacion de uso de INE/IFE precargada*/}
      {isLogged &&
        showSign &&
        user.my_assets &&
        (user.my_assets.ine_back || user.my_assets.pasaporte) && (
          <GridContainer>
            <Grid item xs={12}>
              <Typography component="h5" variant="h6" align="center">
                {typeId == "1"
                  ? "Usar mi Credencial para votar precargada"
                  : "Usar mi Pasaporte precargado"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <GridContainer justifyContent="center" spacing={2}>
                <Grid item>No</Grid>
                <Grid item>
                  <Tooltip
                    title={`Da click para utilizar tu ${typeId == "1"
                        ? "Credencial para votar precargada"
                        : "Pasaporte precargado"
                      }`}
                  >
                    <Switch
                      disabled={
                        typeId == "1"
                          ? !user.my_assets.ine_back
                          : !user.my_assets.pasaporte
                      }
                      checked={typeId == "1" ? checkedB : checkedP}
                      onChange={() => {
                        handleChangeSwitch(typeId == "1" ? "ine" : "pasaporte");
                      }}
                      color="primary"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>Si</Grid>
              </GridContainer>
            </Grid>
          </GridContainer>
        )}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={6} sm={6} md={6}>
          <label htmlFor="ladoAnverso">
            <img
              style={{ alignContent: "center" }}
              id="frontImgZ"
              src={front}
              alt="imgFrente"
              width="50%"
              height="auto"
            />
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {typeId === "1" || typeId === 1 || typeId === 0 ? (
            <label htmlFor="ladoReverso">
              <img
                style={{ alignContent: "center" }}
                src={back}
                id="backImgZ"
                alt="imgBack"
                width="50%"
                height="auto"
              />
            </label>
          ) : null}

          {/* <ImagePicker /> */}
          {/*
      <TextField
        id="ladoReverso"
        type="file"
        inputProps={{ accept: 'image/*' }}
        required
        label="Reverso"
        name="ladoReverso"
        onChange={e => handleFileRead2(e)}
        size="small"
        variant="standard"
        style={{display: 'none'}}
      />
*/}
        </Grid>
        {/*
      <Grid item xs={12} sm={12}>
      {
        front !== "" ? (
          <font size="1" color="red">(*) Puede reemplazar la imagen haciendo nuevamente clic</font>
        ):null
      }
      </Grid>
  */}
        {/* texto:{textoOCR} */}
      </Grid>
      {/*dialogo INE/IFE*/}
      <Dialog
        open={dialogoINE}
        //maxWidth={"lg"}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {step === 0
            ? "Carga el frente de tu credencial para votar"
            : step === 1
              ? "Ajusta la Imagen"
              : step === 2
                ? "Validando imagen"
                : step === 3
                  ? "Imagen correcta, carga el reverso de tu credencial para votar"
                  : step === 4
                    ? "Ajusta la Imagen"
                    : step === 5
                      ? "Validando imagen"
                      : step === 6
                        ? "Proceso culminado"
                        : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {step === 0 ? (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <img width="250" src={takepicture} />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <br />
                    <Typography color="textSecondary">
                      Por favor capturar o subir su identificacón siempre de
                      forma horizontal.
                    </Typography>
                    Puedes tomar fotografía o cargar la imagen de tu
                    identificación en{" "}
                    <font color="black">
                      <b>formatos jpg, png o jpeg</b>
                    </font>
                    . Al tomar la fotografía, te recomendamos limpiar el lente
                    de la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                    <br />
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => handleFileRead(e, "front")}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        size="small"
                      >
                        <PhotoCamera />
                        Carga
                      </Button>
                    </label>
                    {true ? null : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 1 }}
                        size="small"
                        onClick={() => {
                          setDialogoCaptura(true);
                        }}
                        component="span"
                      >
                        <PhotoCamera />
                        Cámara Frontal
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            ) : null}

            {step === 1 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    style={{ height: 350, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 10}
                    preview=".previewCropper"
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    move={20}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    // crop={() => onCrop("ine_front")}
                    src={front}
                    ref={cropperRef}
                  />
                  <br />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <Grid container spacing={2}>
                    <GridItem md={6} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRotate()}
                        component="span"
                      >
                        <RotateRightIcon /> Girar
                      </Button>
                    </GridItem>
                    <GridItem md={6} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabledContinue}
                        onClick={() => handleValidarFront()}
                        component="span"
                      >
                        CONTINUAR
                      </Button>
                    </GridItem>
                  </Grid>
                  <br />

                  <div className="boxCropper">
                    <hr />
                    Previsualización de imagen ajustada:
                    <br />
                    <div
                      className="previewCropper"
                      style={{
                        width: "100%",
                        float: "left",
                        height: "300px",
                      }}
                    />
                  </div>

                  <hr />
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 2 ? (
              <GridContainer>
                {reiniciar ? null : (
                  <GridItem xs={12} sm={6} md={6}>
                    <img src={scanpicture} style={{ maxWidth: "200px" }} />
                  </GridItem>
                )}
                <GridItem xs={12} sm={6} md={6}>
                  <img src={croppedFront} style={{ maxWidth: "200px" }} />
                  <br />
                  {isMobile ? (
                    <font size="2" color={colorMessageFront}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageFront }}
                      ></div>
                    </font>
                  ) : (
                    <font size="2" color={colorMessageFront}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageFront }}
                      ></div>
                    </font>
                  )}
                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(0);
                        //setTypeId(0);
                        setCroppedFront("");
                        setReiniciar(false);
                        setdisabledContinue(false);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 3 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img width="250" src={takepicture1} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <br />
                  Puedes tomar fotografía o cargar la imagen de tu
                  identificación en{" "}
                  <font color="black">
                    <b>formatos jpg, png o jpeg</b>
                  </font>
                  . Al tomar la fotografía, te recomendamos limpiar el lente de
                  la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                  <br />
                  <br />
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleFileRead(e, "back")}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <CloudUploadIcon />
                      Carga
                    </Button>
                  </label>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 4 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    //style={{ height: 400, width: "100%" }}
                    // Cropper.js options
                    // initialAspectRatio={16 / 9}
                    // guides={true}
                    // dragMode={"none"}
                    // movable={false}
                    // autoCropArea={1}
                    // minCropBoxHeight={200}
                    // move={20}
                    style={{ height: 400, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 9}
                    preview=".previewCropperBack"
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    move={20}
                    // crop={() => onCrop("ine_back")}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    src={back}
                    ref={cropperRef}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRotate()}
                    component="span"
                  >
                    <RotateRightIcon /> Girar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  {isMobile ? null : (
                    <div className="boxCropper">
                      Previsualización de imagen ajustada:
                      <br />
                      <div
                        className="previewCropperBack"
                        style={{
                          width: "100%",
                          float: "left",
                          height: "300px",
                        }}
                      />
                    </div>
                  )}
                  <hr />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleValidarBack()}
                    component="span"
                  >
                    CONTINUAR
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 5 ? (
              <GridContainer>
                {reiniciar ? null : (
                  <GridItem xs={12} sm={6} md={6}>
                    <img src={scanpicture} style={{ maxWidth: "200px" }} />
                  </GridItem>
                )}
                <GridItem xs={12} sm={6} md={6}>
                  <img src={croppedFront} style={{ maxWidth: "200px" }} />
                  <br />
                  {isMobile ? (
                    <font size="1" color={colorMessageBack}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageBack }}
                      ></div>
                    </font>
                  ) : (
                    <font size="2" color={colorMessageBack}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageBack }}
                      ></div>
                    </font>
                  )}
                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(3);
                        //setTypeId(0);
                        setCroppedFront("");
                        setReiniciar(false);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleCancelarCargaINE(false);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo INE*/}

      {/*dialogo PASAPORTE*/}
      <Dialog
        open={dialogoPasaporte}
        //maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {step === 0 ? "Carga de Pasaporte" : null}
          {step === 1 ? "Ajuste la Imagen" : null}
          {step === 2 ? "Validando Pasaporte" : null}
          {step === 3 ? "Proceso culminado" : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {step === 0 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <img width="250" src={takepicture} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <font color="black">
                    <b>Fotografía - Pasaporte</b>
                  </font>
                  <br />
                  Puedes tomar fotografía o cargar la imagen de tu
                  identificación en{" "}
                  <font color="black">
                    <b>formatos jpg, png o jpeg</b>
                  </font>
                  . Al tomar la fotografía, te recomendamos limpiar el lente de
                  la cámara, buscar la iluminación adecuada y sin reflejos.{" "}
                  <br />
                  <br />
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleFileRead(e, "front")}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      component="span"
                    >
                      <CloudUploadIcon />
                      Cargar
                    </Button>
                  </label>
                  {true ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 1 }}
                      size="small"
                      onClick={() => {
                        setDialogoCaptura(true);
                      }}
                      component="span"
                    >
                      <PhotoCamera />
                      Cámara Frontal
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 1 ? (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    // zoomTo={0.5}
                    initialAspectRatio={16 / 9}
                    preview=".previewCropperPass"
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    move={20}
                    onInitialized={(instance) => {
                      setCroppedFront(instance);
                    }}
                    // crop={() => onCrop("passport")}
                    src={front}
                    ref={cropperRef}
                  />
                </GridItem>
                <br />
                <GridItem xs={12} sm={6} md={6}>
                  <Grid container spacing={2}>
                    <GridItem md={6} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRotate()}
                        component="span"
                      >
                        <RotateRightIcon /> Girar
                      </Button>
                    </GridItem>
                    <GridItem md={6} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabledContinue2}
                        onClick={() => handleValidarPasaporte()}
                        component="span"
                      >
                        CONTINUAR
                      </Button>
                    </GridItem>
                  </Grid>
                  <br />

                  <div className="boxCropper">
                    <hr />
                    Previsualización de imagen ajustada:
                    <br />
                    <div
                      className="previewCropperPass"
                      style={{
                        width: "100%",
                        float: "left",
                        height: "300px",
                      }}
                    />
                  </div>

                  <hr />
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 2 ? (
              <GridContainer>
                {reiniciar ? null : (
                  <GridItem xs={12} sm={6} md={6}>
                    <img src={scanpicture} style={{ maxWidth: "200px" }} />
                  </GridItem>
                )}
                <GridItem xs={12} sm={6} md={6}>
                  <img
                    src={croppedFront}
                    alt="myCroppedFront"
                    style={{ maxWidth: "200px" }}
                  />
                  <br />
                  {isMobile ? (
                    <font size="2" color={colorMessageFront}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageFront }}
                      ></div>
                    </font>
                  ) : (
                    <font size="2" color={colorMessageFront}>
                      <div
                        dangerouslySetInnerHTML={{ __html: messageFront }}
                      ></div>
                    </font>
                  )}

                  <br />
                  {reiniciar ? (
                    <Button
                      onClick={() => {
                        setStep(0);
                        //setTypeId(0);
                        setCroppedFront("");
                        setReiniciar(false);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Reiniciar proceso de Carga
                    </Button>
                  ) : null}
                </GridItem>
              </GridContainer>
            ) : null}

            {step === 3 ? <GridContainer></GridContainer> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleCancelarCargaPasaporte(false);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo PASAPORTE*/}

      {/*dialogo captura*/}
      <Dialog
        open={dialogoCaptura}
        maxWidth={"lg"}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Captura de ID</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Camera
                  //onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                  onTakePhotoAnimationDone={(dataUri) => {
                    handleTakePhoto(dataUri);
                  }}
                  isImageMirror={false}
                />
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogoCaptura(false);
            }}
            color="success"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo capura*/}

      {/*DIALOGO INSTRUCCIONES TOMA FOTO*/}
      <Dialog
        open={dialogoInstruccion}
        maxWidth={"md"}
        fullWidth={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {isMobile ? (
                  <React.Fragment>
                    <img width="250" src={takepicture} />
                    <font color="black">
                      <br />
                      Toma la fotografía de tu ID con la cámara en{" "}
                      <b>posición horizontal</b> como se muestra en la figura.
                      Asegúrate que la imagen abarque todo el contenido del
                      documento
                    </font>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <font color="black">
                      Por seguridad, <b>el ID debe ser una fotografía.</b>{" "}
                      <br />
                      Cargar una imagen obtenida a través de scanner u otro
                      medio de reproducción, <br />
                      podría afectar el proceso de validación.
                    </font>
                  </React.Fragment>
                )}
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setDialogoInstrucion(false);
            }}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo de confirmacion de guardado de ine como predeterminada */}
      <DialogComp
        open={openDialog}
        // onClose={() => setopenDialog(false)}
        title={typeId == "1" ? "Guardar Identificación" : "Guardar Pasaporte"}
        content={
          typeId == "1"
            ? "¿Desea guardar esta identificación (Credencial para votar) como predeterminada?"
            : "¿Desea guardar esta identificación (Pasaporte) como predeterminada?"
        }
        buttons={[
          {
            buttonEvent:
              typeId == "1"
                ? () => handleConfirmarGuardarINE(2)
                : () => handleConfirmarGuardarPasaporte(2),
            buttonName: "No guardar",
            buttonColor: "secondary",
          },
          {
            buttonEvent:
              typeId == "1"
                ? () => handleConfirmarGuardarINE(1)
                : () => handleConfirmarGuardarPasaporte(1),
            buttonName: "Si, guardar",
            buttonColor: "primary",
          },
        ]}
      />

      <Backdrop className={classes.backdrop} open={progrees}>
        <CircularProgress size={44} className={classes.buttonProgress} />
      </Backdrop>

      {/*DIALOGO INSTRUCCIONES TOMA FOTO*/}
    </React.Fragment>
  );
}
