import Http from './HttpProvider';

const CdoExpedientes = {
    obtenerDashboard() {
        return Http.Fetch('GET', 'api/expedientes/count');
    },

    obtenerMisPendientes() {
        return Http.Fetch('GET', `api/expedientes/misnodos/pendientes`);
    },

    obtenerExpediente($id) {
        return Http.Fetch('GET', `api/expedientes/show/${$id}`);
    },

    /**
     * /api/documentos
     * Obtiene los documentos del backend.
     * @param {Number} page
     * @param {Number} limit
     */
    async obtenerDocumentos(keyword = '', page = 1, limit = 20, orderBy = '', orderDirection = '') {
        return Http.Fetch('GET', `api/expedientes?page=${page}&limit=${limit}&keyword=${keyword}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
    },
    async obtenerDocumentosStatus(keyword = '', status = 0, page = 1, limit = 20, orderBy = '', orderDirection = '') {
        return Http.Fetch('GET', `api/expedientes/status/${status}?page=${page}&limit=${limit}&keyword=${keyword}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
    },
}

export default CdoExpedientes;
