/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Button from "@material-ui/core/Button";
// import ButtonC from "components/CustomButtons/Button.js";
// import AccessTime from "@material-ui/icons/AccessTime";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import GestureIcon from "@material-ui/icons/Gesture";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PostAddIcon from "@material-ui/icons/PostAdd";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BackupIcon from "@material-ui/icons/Backup";
import FolderIcon from "@material-ui/icons/Folder";
import NavigationIcon from "@material-ui/icons/Navigation";
import firmasimple from "assets/img/firmaSimple2.png";
import firmacerti from "assets/img/NEWFIRMACONIDE.png";
import firmaAvanzada from "assets/img/firmaFiel.png";
import AUTOFIRMA from "assets/img/logoAutoFirma.png";
import FIRMARAPIDA from "assets/img/firmasincodigo.png";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import StoreLog from "containers/Logs/StoreLog.js";
//log
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import ControlPanel from "components/controlPanel/ControlPanel";

import TextField from "@material-ui/core/TextField";
import localization from "components/localization/localization";
import axios from "axios";
import moment from "moment";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditOutlined from "@material-ui/icons/EditOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory, useLocation } from "react-router-dom";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import PauseIcon from "@material-ui/icons/Pause";
import CancelIcon from "@material-ui/icons/Cancel";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
//import NoteAddIcon from "@material-ui/icons/NoteAdd";
import GetAppIcon from "@material-ui/icons/GetApp";
import StopIcon from "@material-ui/icons/Stop";
import EmailIcon from "@material-ui/icons/Email";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import { green } from "@material-ui/core/colors";
import Table from "components/Table/Table.js";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";
import Archivos from "containers/archivo/archivo";
import VistaArchivos from "containers/archivo/vistaArchivos";
import Backdrop from "@material-ui/core/Backdrop";
import Adjuntos from "containers/Adjuntos/Adjuntos"; //LEMP ADJUNTOS
import FechaCierta from "containers/FechaCierta/FechaCierta"; //FECHA CIERTA
//
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CopiaCertificada from "views/CopiaCertificada/CopiaCertificada";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Components from "containers/CheckoutNew";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import { useSelector, useDispatch } from "react-redux";
import EditFlow from "containers/editFlow/editFlowContainer";
import { CsvBuilder } from "filefy";

//table material

import MaterialTable from "material-table";
import IMaterialTable from "./../../components/IMaterialTable";

// import { dailySalesChart, emailsSubscriptionChart } from "variables/charts.js";
import Firmantes from "containers/FirmaAvanzada/Participantes";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Visibility } from "@material-ui/icons";
import { DialogComp } from "components/Dialog/DialogComp";
import CardHeader from "components/Card/CardHeader";

import MuiAlert from "@material-ui/lab/Alert";
import EditarMedioNotificacion from "containers/Firmante/EditarMedioNotificacion";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Cdo from "services/Cdo";
import GavelIcon from "@material-ui/icons/Gavel";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import FileCopyIcon from "@material-ui/icons/FileCopy";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let cdo_synced = false;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fab: {
    display: "scroll",
    position: "fixed",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    zIndex: -1,
  },
}));
const useStylestable = makeStyles(styles2);

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  root: {
    background:
      "  linear-gradient(to right top, #eee404, #f1e405, #f3e407, #f6e308, #f8e30a)",
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    backgroundColor: grayColor[0],
    float: "left",
  },

  iconGrayColor: {
    backgroundColor: grayColor[0],
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  botoninfo: {
    backgroundColor: "#00acc1",
    color: "white",
  },

  botondanger: {
    backgroundColor: "red",
    color: "white",
  },

  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
}));

// TODO: Global refs were required, because current is always null
// if declared inside the render function.
// See: https://github.com/mbrn/material-table/issues/2432

const docsTableRef = React.createRef();
const docsFirmadosTableRef = React.createRef();
const docsEsperaTableRef = React.createRef();
const docsRechazadosTableRef = React.createRef();
const docsRegistradosTableRef = React.createRef();

export default function Dashboard(props) {
  const { mainTab } = props;
  //*****************************************************
  //    LLAMADAS A LA API DE CDO
  //******************************************************
  //credenciales API CDO
  const dispatch = useDispatch();
  const username = "ce2af9cab947ed15288e058ecd5350f5";
  const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );
  const [openCambiarEmisor, setOpenCambiarEmisor] = useState(false); //LEMP CAMBIAR EMISOR
  const [openEmisorCambiado, setOpenEmisorCambiado] = useState(false); //LEMP CAMBIAR EMISOR
  const [documentoCambiarEmisor, setDocumentoCambiarEmisor] = useState(""); //LEMP CAMBIAR EMISOR
  const [newEmisor, setNewEmisor] = useState(""); //LEMP CAMBIAR EMISOR
  const [openConfirmarEnlace, setOpenConfirmarEnlace] = useState(false);
  const [openEnlaceEnviado, setOpenEnlaceEnviado] = useState(false);
  const [archivoAlmacenar, setArchivoAlmacenar] = useState("");
  const [mostrarNotifFirmante, setMostrarNotifFirmante] = useState(false);
  const [mostrarAlertaCopiadoUrl, setMostrarAlertaCopiadoUrl] = useState(false);
  const [frmMedioNotificacion, setFrmMedioNotificacion] = useState({
    idFirmante: "",
    email: "",
    phone_country_code: "",
    phone: "",
    name: "",
    last_name: "",
    notification_types_id: 0,
    speech_required: false,
    isPhone: false,
    old_phone: "",
    old_email: "",
    date_notified: null,
  });

  const PROXY_URL = "";
  const { permissions } = useSelector((state) => state.auth);
  //const { dashboard } = useSelector((state) => state.app);
  const [idDeleteCDO, setidDeleteCDO] = useState("");
  const [modalFirmante, setModalFirmante] = useState(false);
  const [modalFirmantes, setModalFirmantes] = useState(false);
  const [modalFirmantesCC, setModalFirmantesCC] = useState(false);
  const [listaFirmantes, setListaFirmantes] = useState("");
  const [listaFirmantesCC, setListaFirmantesCC] = useState("");
  const [listaNotificados, setListaNotificados] = useState("");
  const [contratosID, setContratosID] = useState("");
  const [scale, setScale] = useState(1.3);
  const [enviar, setEnviar] = useState(true);
  const [authUser, setAuthUser] = useState(null);
  const [progrees, setProgrees] = useState(false);

  const [openDialogEliminarFirmante, setOpenDialogEliminarFirmante] = useState(
    false
  );
  const [disabledDetenerText, setDisabledDetenerText] = useState(false);

  // Modificar Metadata States

  const [valueDeleteMeta, setValueDeleteMeta] = useState({
    atributoDelete: "",
    openConfirmarDeleteMeta: false,
  });
  const { atributoDelete, openConfirmarDeleteMeta } = valueDeleteMeta;

  const [rowDataFirmante, setrowDataFirmante] = useState({});
  const [dataNewMeta, setDataNewMeta] = useState({
    openDialogNewMeta: false,
    nombreNewMeta: "",
    valorNewMeta: "",
  });
  const { nombreNewMeta, valorNewMeta, openDialogNewMeta } = dataNewMeta;
  const [openModificar, setOpenModificar] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [infoMetadata, setInfoMetadata] = useState({});

  // Fin Metadata States
  // Asignar nuevo tipo de proceso de firma
  const [currentStep, setCurrentStep] = useState("");

  const [newAssignProcess, setNewAssign] = useState({
    openNewAssignProcess: false,
    rowDataAssign: "",
    valueAssignNew: 0,
    disabledAssign: true,
  });

  const {
    openNewAssignProcess,
    rowDataAssign,
    disabledAssign,
    valueAssignNew,
  } = newAssignProcess;

  const [docuDataPerson, setDocuDataPerson] = useState([]);
  const [docuDataLoading, setDocuDataLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState("");

  const [diasAlert, setDiasAlert] = useState(0);
  const [desde, setDesde] = useState("");
  const [hasta, setHasta] = useState("");
  const [alerta, setAlerta] = useState("");
  const [diasAlerta, setDiasAlerta] = useState(0);
  const [checked, setChecked] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [openAlert, setOpenAlert] = useState("");
  const [idprocces, setIdprocces] = useState("");
  const [idStatus, setIdStatus] = useState("");
  const [sendFolder, setSendFolder] = useState(false);
  const [openCarpetas, setOpenCarpetas] = useState(false);
  const [openAlmacenar, setOpenAlmacenar] = useState(false);
  const [openCopiaCer, setOpenCopiaCer] = useState(false);
  const [
    showEditarMedioNotificacion,
    setShowEditarMedioNotificacion,
  ] = useState(false);
  const [dashboard, setDashboard] = useState({
    total: 0,
    registrado: 0,
    en_proceso: 0,
    firmados: 0,
    rechazados: 0,
  });

  function handleEnviarEnlace() {
    setOpenConfirmarEnlace(false);
    let tokenCC = sessionStorage.getItem("Token");
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/notificados/enviarEnlaceDescarga/${idDocumento}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    setidDocumento("");

    axios(config)
      .then(function (response) {
        console.log("Respuesta enviado:", response.data);
        setOpenEnlaceEnviado(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0 || diasAlerta === null) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };

  const handleCloseAssign = () => {
    setNewAssign({
      openNewAssignProcess: false,
      rowDataAssign: "",
      valueAssignNew: 0,
      disabledAssign: true,
    });
    // setCurrentStep("");
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setNewAssign({
      ...newAssignProcess,
      valueAssignNew: value,
      disabledAssign: false,
    });
  };

  useEffect(() => {
    if (mainTab) {
      setValue(mainTab);
      setHeaderColor("primary");
    }
  }, []);

  useEffect(() => {
    if (docuDataPerson && docuDataPerson.meta && docuDataPerson.meta.info) {
      if (typeof docuDataPerson.meta.info === "string") {
        setInfoMetadata(JSON.parse(docuDataPerson.meta.info));
      } else {
        setInfoMetadata(docuDataPerson.meta.info);
      }
    }
  }, [docuDataPerson]);

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handleCancelarNuevoMeta = () => {
    setDataNewMeta({
      openDialogNewMeta: false,
      nombreNewMeta: "",
      valorNewMeta: "",
    });
  };

  const handleEliminarMetadato = () => {
    let myObject = {};
    let result = Object.keys(infoMetadata).filter(
      (item) => item != atributoDelete
    );
    result.map((item) => {
      myObject = {
        ...myObject,
        [item]: infoMetadata[item],
      };
    });
    setInfoMetadata(myObject);
    setValueDeleteMeta({
      atributoDelete: "",
      openConfirmarDeleteMeta: false,
    });
  };

  const handleConfirmaEliminarMeta = (atributo) => {
    setValueDeleteMeta({
      atributoDelete: atributo,
      openConfirmarDeleteMeta: true,
    });
  };

  const handleCancelarEliminarMeta = () => {
    setValueDeleteMeta({
      atributoDelete: "",
      openConfirmarDeleteMeta: false,
    });
  };

  const handleAgregarNuevoMetadato = () => {
    setInfoMetadata({
      ...infoMetadata,
      [nombreNewMeta]: valorNewMeta,
    });
    setDataNewMeta({
      openDialogNewMeta: false,
      nombreNewMeta: "",
      valorNewMeta: "",
    });
  };

  const handleChangeNewMetadata = (event) => {
    setDataNewMeta({
      ...dataNewMeta,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeProcess = () => {
    handleOpenForm(rowDataAssign, valueAssignNew);
    setOpenCarpetas(false);
  };

  function handleModificar() {
    console.log("handleModificar NOT ARROW FUNCTION");
    let tokenCC = sessionStorage.getItem("Token");
    //let tokenCC = `${process.env.REACT_APP_TOKEN}`;
    let urlmodificar = "";
    let desc = "";
    console.log("desde:", desde);
    console.log("hasta:", hasta);
    console.log("dias:", diasAlerta);

    if (descripcion != "" && descripcion != undefined) {
      desc = descripcion;
    }
    urlmodificar = "?description=" + desc;

    if (desde !== "" && desde !== undefined && desde !== "Invalid date") {
      urlmodificar =
        urlmodificar + "&meta[vigencia_start]=" + desde + "00:00:00";
    }
    if (desde === "" || desde === "Invalid date") {
      urlmodificar = urlmodificar + "&meta[vigencia_start]=";
      urlmodificar = urlmodificar + "&meta[vigencia_end]=";
    } else {
      if (hasta !== "" && hasta !== undefined && hasta !== "Invalid date") {
        urlmodificar =
          urlmodificar + "&meta[vigencia_end]=" + hasta + "23:59:59";
      }
      if (hasta === "" || hasta === "Invalid date") {
        urlmodificar = urlmodificar + "&meta[vigencia_end]=";
      }
    }

    if (checked) {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=" + diasAlerta;
    } else {
      urlmodificar = urlmodificar + "&meta[dias_alerta]=";
    }

    urlmodificar = urlmodificar + "&meta[info]=" + JSON.stringify(infoMetadata);

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${docuDataPerson._id}${urlmodificar}`,
      //url : `${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    setProgrees(true);
    setInfoMetadata({});
    setDocuDataPerson([]);
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);

    axios(config)
      .then(function (response) {
        refreshTables();
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //reload
  }

  //logs
  const [logStored, setlogStored] = useState(false);

  //fin logs

  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    rfc: "",
    compañia: "",
  });
  const [stopCDO, setStopCDO] = useState(false);
  const [idStopCDO, setIdstopCDO] = useState("");
  const [recordatorioCDO, setRecordatorioCDO] = useState(false);

  //FIRMANTES cdo
  const [listaFirmantesCDO, setListaFirmantesCDO] = useState([{}]);
  const actualizarFirmantes = (idcontrato) => {
    if (contratosID) {
      const URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        idcontrato +
        "/firmante/lista";
      var config = {
        method: "get",
        url: PROXY_URL + URL,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Basic " + token,
        },
      };
      axios(config)
        .then(function (response) {
          console.log("Firmantes:", response.data);
          setListaFirmantesCDO(response.data);
          //        console.log("Participantes ",response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //FIN FIRMANTES cdo

  //SINCRONIZAR CDO / CON CERTEZA

  const actualizarFirmante = (idfirmantecc, date_signed, date_notified) => {
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idfirmantecc}?date_signed=${date_signed}&date_notified=${date_notified}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmante CC Actualizado Olv:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarStatusFirmantes = (idcontratocc, firmantescdo) => {
    let token = sessionStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/${idcontratocc}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        var firmantescc = resp.data.firmantes;
        for (var i = 0; i < firmantescdo.length; i++) {
          for (var j = 0; j < firmantescc.length; j++) {
            if (firmantescdo[i].email === firmantescc[j].email) {
              var date_signed = moment(firmantescdo[i].fecha_firma).format(
                "YYYYMMDD HH:mm:ss"
              );
              var date_notified = moment(firmantescdo[i].fecha_inicio).format(
                "YYYYMMDD HH:mm:ss"
              );
              actualizarFirmante(
                firmantescc[j]._id,
                date_signed,
                date_notified
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  const actualizarContrato = (idcontratocc, idcontratocdo) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontratocdo +
      "/get";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contratos:", response.data);
        if (response.data.status.codigo === 2) {
          // modificarStatusCCNorel(idcontratocc, 3);
          //modificar estatus firmantes
          actualizarStatusFirmantes(idcontratocc, response.data.firmantes);
        } else {
          if (response.data.status.codigo === 3) {
            // modificarStatusCCNorel(idcontratocc, 4);
            //modificar estatus firmantes
            actualizarStatusFirmantes(idcontratocc, response.data.firmantes);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarStatusCC = (data) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].cdo_contratos_id) {
        console.log("Encontre a:", data[i]);
        actualizarContrato(data[i]._id, data[i].cdo_contratos_id);
      }
    }
  };
  //FIN SINCRONIZAR

  //mostrar compañia que no se almacena en la plataforma CDO
  const companyCDO = (email) => {
    console.log("Firmantes CC", listaFirmantesCC);
    for (var i = 0; i < listaFirmantesCC.length; i++) {
      if (listaFirmantesCC[i].email === email) {
        return listaFirmantesCC[i].company;
      }
    }
    return null;
  };

  //para mostrar el estatus cuando un documento es rechazado
  const estatusCDO = (data) => {
    //console.log("estatus:",data);
    if (data.rechazo) {
      //return "Rechazó: " + data.motivo_rechazo;
      return "Rechazó";
    } else {
      if (data.firmo) {
        return "Firmó";
      } else {
        return "Pendiente de Firma";
      }
    }
  };

  const handleInputFirmante = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  //habilitar guardar firmante si los campos estan llenos
  let disableformfirmante = true;
  if (
    datos.nombre !== "" &&
    datos.apellidos !== "" &&
    datos.correo !== "" &&
    datos.rfc !== "" &&
    datos.compañia !== ""
  ) {
    disableformfirmante = false;
  }

  const activarProcesoCDO = (idContratoCDO) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idContratoCDO +
      "/enable";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO activado:", response.data);
        modificarStatusCC(contratosID.cc, 2);
      })
      .catch(function (error) {
        console.log(error);
        alert("EL CONTRATO DEBE TENER AL MENOS UN FIRMANTE");
      });
  };

  function modificarStatusCC(idContratoCC, newStatus) {
    //let tokenCC = sessionStorage.getItem("Token");
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
        console.log(newStatus);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function modificarStatusCCNorel(idContratoCC, newStatus) {
    let tokenCC = sessionStorage.getItem("Token");
    // let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
        console.log(newStatus);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleStopApiCDO = () => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idStopCDO +
      "/stop/" +
      comment;
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO detenido:", response.data);
        modificarStatusCC(idStop, 1);
        setopenStop(false);
        refreshTables();
      })
      .catch(function (error) {
        console.log(error);
        alert("NO ES POSIBLE DETENER EL PROCESO");
      });
  };

  const eliminarFirmanteCDO = (idfirmante) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/firmante/" +
      idfirmante +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Eliminado Firmante CDO:", response.data);
        bucarFirmantesCDO(contratosID.cdo);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function eliminarFCC(id) {
    console.log("entro a eliminarFCC");
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantes/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleOpenCopiaCertificada = (data) => {
    console.log("datacopiacer", data);
    setidDocumento(data._id);
    setOpenCopiaCer(true);
  };
  //const eliminarFirmanteCC = (contratoCC, emailfirmante) => {
  const eliminarFirmanteCC = (contratoCC, rfc) => {
    console.log("entro a eliminarFirmanteCC");
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${contratoCC}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        var firmantesCC = response.data.firmantes;
        const found = firmantesCC.find((element) => element.rfc === rfc);
        console.log("found firmante:", found);
        if (found) {
          eliminarFCC(found._id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //GUARDAR FIRMANTES CDO
  function makePostRequestFirmantes(idContratoCDO) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idContratoCDO +
      "/firmante/save";

    const data = new FormData();
    data.append("nombre", datos.nombre + " " + datos.apellidos);
    data.append("rfc", datos.rfc);
    data.append("email", datos.correo);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  //GUARDAR FIRMANTES CON CERTEZA
  function makePostRequestFirmantesCC(idContratoCC) {
    let tokenCC = sessionStorage.getItem("Token");
    console.log("idContratoCC", idContratoCC);
    const data = new FormData();
    data.append("documentos_id", idContratoCC);
    data.append("name", datos.nombre);
    data.append("last_name", datos.apellidos);
    data.append("notification_types_id", "1");
    data.append("email", datos.correo);
    data.append("role", "No Aplica");
    data.append("firmante_involvements_id", "0");
    data.append("company", datos.compañia);

    //var  urlDocumento= PROXY_URL+URL;
    var urlDocumento = `${process.env.REACT_APP_URL}api/firmantes`;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async function registraFirmantes(idContratoCDO, idContratoCC) {
    //  console.log("registraFirmantes",idContratoCDO,idContratoCC);

    var result = await makePostRequestFirmantes(idContratoCDO);
    console.log(result);
    console.log("Firmante CDO OK");

    //    var idContratoCC = await buscarContratoCC(idContratoCDO);
    //    console.log("contratoCC:",idContratoCC);

    var resultCC = await makePostRequestFirmantesCC(idContratoCC);
    console.log(resultCC);
    console.log("Firmante CC OK");

    setDatos({
      nombre: "",
      apellidos: "",
      correo: "",
      //celular: "",
      rfc: "",
      compañia: "",
    });
  }

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
        if (response.data.length === 0) {
          setEnviar(true);
        } else {
          setEnviar(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function bucarNotificadosCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/notificador/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados CDO:", response.data);
        setListaNotificados(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const eliminarContratoCDO = (idcontrato) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      idcontrato +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Contrato CDO eliminado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDescargarConstanciaCDO = (nombre, idConstancia) => {
    var nombre_archivo = nombre + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      idConstancia +
      "/descargar";

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDescargarCDO = (nombre, status, id) => {
    var nombre_archivo = nombre;
    var URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      id +
      "/descargar-certificado";
    if (status === 3) {
      nombre_archivo = nombre + ".zip";
    } else {
      URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        id +
        "/descargar";
    }

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
  };

  const aplicaFirmantes = (data) => {
    if (data.type.id === 5) {
      return <span>No Aplica</span>;
    }
    return <span>{data.firmantes_names}</span>;
  };

  const recortarName = (data) => {
    // se chequea el regex de que el string no tenga espacio
    if (data.filename.length >= 28) {
      let arr = Array.from(data.filename)
      let tercera = Math.trunc(arr.length / 3)
      let eliminar = arr.length - 15
      console.log("ARRAYNAME", arr)
      // console.log("ARRAYmitad", mitad)
      arr.splice(tercera, tercera + tercera / 2, "...");
      let result = arr.toString();

      return <span>{result.replace(/,/g, "")}</span>;
    } else return <span>{data.filename}</span>;
  };

  const handleCloseCDO = () => {
    setStopCDO(false);
    setopenStop(false);
    setComment("");
    setDisabledDetenerText(false);
  };

  const handleFrmMedioNotificacion = async (form) => {
    try {
      setProgrees(true);
      setShowEditarMedioNotificacion(false);
      setMostrarNotifFirmante(false);
      const frm = {
        notification_types_id: parseInt(form.notification_types_id, 10),
        speech_required: form.speech_required === true || form == 1 ? 1 : 0,
        email: form.email,
        rfc: form.rfc,
        rfc_empresa:
          form.rfc_empresa && form.rfc_empresa.length > 0
            ? form.rfc_empresa
            : null,
      };

      if (frm.notification_types_id == 2) {
        delete frm.email;
        frm.phone = form.phone;
        frm.phone_country_code = form.phone_country_code;
      }

      if (frm.cert_required === false) {
        delete frm.rfc;
        delete frm.rfc_empresa;
      }

      if (frm.notification_types_id == 2 && frm.phone.length < 10) {
        alert("Teléfono no válido");
        throw "Telefono no valido";
      } else if (frm.notification_types_id == 1) {
        const tokenSG =
          "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";
        const resp = await axios({
          async: true,
          crossDomain: true,
          method: "post",
          url: `https://api.sendgrid.com/v3/validations/email`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenSG,
          },
          data: `{\"email\":\"${frm.email}\",\"source\":\"signup\"}`,
        });
        if (resp.data.result.verdict === "Invalid") {
          alert("Correo no valido");
        }
      }
      const payloadFirmantes = JSON.stringify(frm);
      const selfToken = sessionStorage.getItem("Token");
      const resp = await axios({
        method: "put",
        url: `${process.env.REACT_APP_URL}api/firmantes/${form.idFirmante}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${selfToken}`,
        },
        data: payloadFirmantes,
      });
      // Si se cambio el firmante y hay cambios en correo/phone, hacer push
      if (resp.status === 201) {
        form._id = form.idFirmante;
        // No enviar recordatorio si no es turno del firmante!
        setNotificadoFirmante(false);
        if (form.date_notified && form.date_notified.length > 0) {
          await enviarRecordatorioFirmante(form, contratosID.cc);
        }
        // actualizar listaFirmantes
        const firmante = listaFirmantes.find((e) => e._id === form.idFirmante);
        frm.date_notified = form.date_notified;
        for (const [k, v] of Object.entries(frm)) {
          firmante[k] = v;
        }
        setListaFirmantes(listaFirmantes);
      } else {
        throw "No se ha podido guardar la informacion del firmante.";
      }
      setMostrarNotifFirmante(true);
    } catch (e) {
      alert(
        "Ocurrió un problema al modificar el medio de notificación del firmante."
      );
      console.error(e);
    }
    setProgrees(false);
  };

  const enviarRecordatorioFirmante = async function (firmante, idDocumento) {
    const idFirmante = firmante._id;
    const selfToken = sessionStorage.getItem("Token");
    const resp = await axios({
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/pushSingle/${idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${selfToken}`,
      },
      validateStatus: function () {
        return true;
      },
    });
    if (resp.status === 500) {
      showNotificacion("Espera un minuto para volver a realizar esta acción.");
      throw "Error 500!";
    }
    if (resp.status !== 201) {
      showNotificacion(
        "Ha ocurrido un problema al enviar la notificación al firmante."
      );
      throw "Ha ocurrido un problema al enviar la notificación al firmante.";
    }

    // Mejora TS2207-1265: Actualizar fecha de envio
    firmante.date_notified = resp.data.date_notified;
    setNotificadoFirmante(true);
    return true;
  };
  //FIN LLAMADAS

  const classespro = useStylesprogrees();

  const classes2 = useStylestable();


  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [idDocumento, setidDocumento] = useState("");
  const [idDocuDetenido, setidDocuDetenido] = useState("");
  const [comment, setComment] = useState("");
  const [disabledDetener, setdisabledDetener] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionDetele, setpermissionDelete] = useState(false);
  const [notificadoFirmante, setNotificadoFirmante] = useState(false);
  const [openForm, setOpenForm] = useState(0);

  const [docuData, setDocuData] = useState([]);
  const [openDialogInfo, setOpenDialogInfo] = useState(false);
  const [openDialogAdjuntos, setOpenDialogAdjuntos] = useState(false); //LEMP Adjuntos
  const [openDialogFechaCierta, setOpenDialogFechaCierta] = useState(false); //LEMP Fecha Cierta
  const [dataRegister, setDataRegister] = useState([]);
  const [openDialogFirmantes, setOpenDialogFirmantes] = useState(false);
  const [openFirmantesRechazados, setopenFirmantesRechazados] = useState(false);
  const [firmantes, setFirmantes] = useState([]);
  const [namefirmantes, setNameFirmantes] = useState([]);
  const [base64, setbase64] = useState("");
  const [tabState, setabState] = useState("");
  const [open, setOpen] = useState(true);
  const [openDelete, setopenDelete] = useState(false);
  const [openStop, setopenStop] = useState(false);
  const [idDelete, setidDelete] = useState("");
  const [openReminder, setopenReminder] = useState(false);
  const [idSend, setIdSend] = useState("");
  const [idStop, setIdstop] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeigth, setpdfHeigth] = useState(575);
  const [pdfWidth, setpdfWidth] = useState(660);
  const [tipoDoc, setTipoDoc] = useState("");
  const [openZip, setOpenZip] = useState(false);
  //componente Customtabs
  const [valueState, setValue] = useState(0);
  const [headerColor, setHeaderColor] = useState("info");
  const [dataCarpetas, setDataCarpetas] = useState([]);
  const [succesCarpetas, setSuccesCarpetas] = useState(false);
  const [arrayPatch, setArraypatch] = useState([]);

  const getCarpetas = () => {
    setProgrees(true);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDataCarpetas(res.data);
        setProgrees(false);
      })
      .catch((error) => {
        setProgrees(false);
        console.log("errorcatergorias servidor", error.data);
      });
  };

  const handleChange = (event, value) => {
    setValue(value);
    getDashboard();
    if (value == 0) {
      setHeaderColor("info");
    } else if (value == 1) {
      setHeaderColor("success");
    } else if (value == 2) {
      setHeaderColor("warning");
    } else if (value == 3) {
      setHeaderColor("danger");
    } else if (value == 4) {
      setHeaderColor("primary");
    }
  };

  function ShowMeta(props) {
    let mainData;
    if (typeof props.json === "string") {
      mainData = JSON.parse(props.json);
    } else {
      mainData = props.json;
    }
    return (
      <>
        {Object.keys(mainData).map((key, i) => (
          <p key={i}>
            <b>{key}:</b> {mainData[key]}
          </p>
        ))}
      </>
    );
  }

  useEffect(() => {
    (async () => {
      getUsuarios();
      /**
       * Necesario esperar a terminar la ejecución de la función get dashboard,
       * para sincronizar documentos pendientes de firmar entre CDO y Con-certeza.
       * Local variable fue necesario, redux y react state no funcionan correctamente
       * dentro del callback de material-table.
       */
      cdo_synced = false;
      await getDashboard();
      cdo_synced = true;
      /* docuRegister();
      docuProceso();
      docuFirmados();
      docuRechazados();
      datatable(); */

      const permissionDelete = permissions.includes("documento.delete");
      if (permissionDelete == true) {
        console.log("si tiene delete");
        setpermissionDelete(true);
      } else if (permissionDelete == false) {
        setpermissionDelete(false);
        console.log("no tiene delete");
      }
      //actualizarStatusCC();
      // dataChart();
      if (isMobile()) {
        setpdfHeigth(500);
        setpdfWidth(290);
      }
      //Guardar log
      if (logStored === false && authUser) {
        let token = sessionStorage.getItem("Token");
        saveLog(token, "Dashboard", authUser);
        saveLogCapture(token, "Dashboard", authUser);
        setlogStored(true);
      }
      //fin log
    })();
  }, []);

  const Pointer = { cursor: "pointer" };

  const datatable = async () => {
    let token = sessionStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        // console.log("repuesta table", resp.data);

        const data = resp.data;
        data.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
          return item;
        });
        setDocuData(data);
        setProgrees(false);
      })
      .catch((err) => {
        setProgrees(false);
        console.log("Error Axios", err);
      });
  };

  const handleCloseFirmante = () => {
    setOpenDialogFirmantes(false);
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const handleOpenFirmantesRechazados = (data) => {
    setopenFirmantesRechazados(true);
    const ok = itemFirmanteRechazados(data.firmantes);
    setFirmantes(ok);
  };

  const handleOpenFirmantes = (data) => {
    setOpenDialogFirmantes(true);

    const ok = itemFirmanteFirmados(data.firmantes);
    setFirmantes(ok);
  };
  const itemFirmante = (data) => {
    console.log("data firmante");
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      item.date_signed,
    ]);

    return values;
  };

  const itemFirmanteFirmados = (data) => {
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      moment(item.date_signed).format("DD-MM-YYYY LT "),
      "MX",
    ]);

    return values;
  };

  const itemFirmanteRechazados = (data) => {
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      item.refused_comment,
    ]);

    return values;
  };

  //LEMP ADJUNTOS
  const handleOpenAdjuntos = (rowData) => {
    setDocuDataPerson([]);
    setDocuDataPerson(rowData);
    setOpenDialogAdjuntos(true);
  };
  const handleCloseAdjuntos = () => {
    setDocuDataPerson([]);
    setOpenDialogAdjuntos(false);
  };
  //FECHA CIERTA
  const handleOpenFechaCierta = (rowData) => {
    setDocuDataPerson([]);
    setDocuDataPerson(rowData);
    setOpenDialogFechaCierta(true);
  };
  const handleCloseFechaCierta = () => {
    setDocuDataPerson([]);
    setOpenDialogFechaCierta(false);
  };
  const handleCloseCopiaCer = () => {
    setOpenCopiaCer(false);
  };

  const handleOpenInfo = (rowData) => {
    setDocuDataPerson([]);
    setDocuDataPerson(rowData);
    setOpenDialogInfo(true);
  };
  const handleCloseInfo = () => {
    setDocuDataPerson([]);
    setOpenDialogInfo(false);
  };

  //obtener la lista de usuarios
  const getUsuarios = () => {
    //setDataRegister([]);
    let token = sessionStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/users`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        // console.log("usuarios ", resp);
        setDataUsuarios(resp.data);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  //regresar nombre del usuario
  function findUser(userid) {
    const resultado = dataUsuarios.find((user) => user.id === userid);
    if (resultado) {
      return resultado.name;
    } else {
      return null;
    }
  }

  const docuRegister = () => {
    return;
  };

  const refreshTables = function () {
    const refs = {
      0: docsTableRef,
      1: docsFirmadosTableRef,
      2: docsEsperaTableRef,
      3: docsRechazadosTableRef,
      4: docsRegistradosTableRef,
    };
    const ref = refs[valueState];
    ref.current.onQueryChange();
    getDashboard();
  };

  const getDashboard = async () => {
    setDashboard(await Cdo.CdoDocumentos.obtenerDashboard());
  };
  /**
   * Esta funcion devuelve el Id de status del backend,
   * dependiendo del indice dado en front.
   * @param {Number} idx
   * @returns
   */

  var getStatusByIdx = function getStatusByIdx() {
    var statusIdx = [0, 3, 2, 4, 1];
    return statusIdx[valueState];
  };

  /**
   * Esta funcion obtiene un resultset dad
   * @param {Object} query
   * @returns
   */

  var fetchDocumentos = function (query) {
    return new Promise(async (resolve, reject) => {
      //   setDocuDataLoading(true);

      /**
       * Validar estado de carga del dashboard por local variable.
       * Por alguna razon, Redux y State de React fallan, cuando
       * se llaman desde material-table (los estados no se actualizan)
       */
      const maxAttempts = 60;
      for (let i = 1; i <= maxAttempts; i++) {
        if (cdo_synced === true) break;
        console.log(
          `Esperando a que se sincronicen documetos con CDO... isSynced=${cdo_synced} ${i}/${maxAttempts}`
        );
        await new Promise((solve) => setTimeout(solve, 500));
      }
      const status = getStatusByIdx();

      const orderBy =
        query.orderBy && query.orderBy.field.length > 0
          ? query.orderBy.field
          : "";
      const orderDirection =
        query.orderDirection && query.orderDirection.length > 0
          ? query.orderDirection
          : "";

      const documentos =
        status === null || status <= 0
          ? await Cdo.CdoDocumentos.obtenerDocumentos(
            query.search,
            query.page + 1,
            query.pageSize,
            orderBy,
            orderDirection
          )
          : await Cdo.CdoDocumentos.obtenerDocumentosStatus(
            query.search,
            status,
            query.page + 1,
            query.pageSize,
            orderBy,
            orderDirection
          );

      // Procesar resultset de documentos.
      processDocumentos(documentos);

      setLastSearch(query.search); // Actualizar stats de dashboard asincronamente, en caso de que algun contrato haya sido firmado o removido, etc.

      //      setDocuDataLoading(false);
      setProgrees(false);

      resolve({
        data: documentos.items,
        page: documentos.current_page - 1,
        pageSize: query.pageSize,
        totalCount: documentos.total_items,
      }); // Actualizar criterio de busqueda, en caso de requerirse en un futuro.
    });
  };

  var exportCsvDocumentos = function (columns, data) {
    return new Promise(async (resolve, reject) => {
      setDocuDataLoading(true);
      const status = getStatusByIdx();

      const documentos =
        status === null || status <= 0
          ? await Cdo.CdoDocumentos.obtenerDocumentos(lastSearch, 1, 9999999)
          : await Cdo.CdoDocumentos.obtenerDocumentosStatus(
            lastSearch,
            status,
            1,
            9999999
          );

      // Procesar resultset de documentos.
      processDocumentos(documentos);

      const rows = [];
      try {
        for (const item of documentos.items) {
          const row = [];
          for (const col of columns) {
            const value = item[col.field];

            if (col.field === "uid") {
              value = findUser(value);
            } else if (col.field === "status.name") {
              value =
                item.reason && item.documento_status_id !== 4
                  ? "detenido"
                  : item.status.name;
            } else if (col.field === "type.name") {
              value = item.type.name;
            }

            row.push(value);
          }
          rows.push(row);
        }

        // Renderizar CSV
        // Codigo basado en el por defecto de material-table.
        // URL: https://github.com/mbrn/material-table/blob/master/src/components/m-table-toolbar.js#L58
        const fileName = this.title || "data";
        const builder = new CsvBuilder(fileName + ".csv");

        builder
          .setDelimeter(this.exportDelimiter)
          .setColumns(
            columns.map(function (columnDef) {
              return columnDef.title;
            })
          )
          .addRows(rows)
          .exportFile();
      } catch (e) { }
      setDocuDataLoading(false);
    });
  };

  const processDocumentos = function (documentos) {
    documentos.items.map(function (item) {
      const { _id, cdo_contratos_id, cdo_contrato } = item;

      item.created_at = moment(item.created_at).format("DD-MM-YYYY");
      item.firmantes_names = item.firmantes_names.replace(/,/g, " , ");
      if (cdo_contrato === null) return item;

      if (
        cdo_contrato.status.codigo === 2 ||
        cdo_contrato.status.codigo === 3
      ) {
        actualizarStatusFirmantes(_id, cdo_contrato.firmantes);
      }

      return item;
    });
  };

  const docuProceso = () => {
    return;
  };

  const docuFirmados = () => {
    return;
  };

  // funcion de peticion de documentos rechazados

  const docuRechazados = () => {
    return;
  };

  const getTotalDownloadPdf = (data) => {
    console.log("DATA TYPE:", data.type.id);
    if (data.type.id === 3) {
      handleDescargarCDO(data.filename, data.status.id, data.cdo_contratos_id);
    } else {
      if (data.status.name === "firmado" && data.type.id !== 11) {
        getZipDocument(data._id, data.filename);
      } else {
        //handleOpenPDF(data._id);
        handleDescargarPDF(data._id, data.filename);
      }
      //decargo anexos
      getZipAnexos(data._id, data.filename);
    }
  };

  //funcion para solo ver los pdf firmados y no firmados
  const getTotalVisualizarPdf = (data) => {
    if (data.status.name === "firmado" && data.type.id !== 11) {
      handleOpenPDFFirmado(data._id, data.filename);
    } else {
      handleOpenPDF(data._id);
    }
  };

  //funcion para ver el pdf firmado
  const handleOpenPDFFirmado = async (idDocu) => {
    setOpenDialog(true);
    const token = sessionStorage.getItem("Token");

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocu}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //funcion para descargar adjuntos
  const getZipAnexos = (idDocu, fileName) => {
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);

    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/downloadbundle/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${nameFormat}-ADJUNTOS.zip`);
        document.body.appendChild(link);
        link.click();
        const timeout = setTimeout(() => {
          setOpenZip(false);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        const timeout = setTimeout(() => {
          setOpenZip(false);
        }, 5000);
      });
  };

  //funcion para descargar el paquete de constancia del documentos
  const getZipDocument = (idDocu, fileName) => {
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);

    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      const timeout = setTimeout(() => {
        setOpenZip(false);
      }, 5000);
    });
  };

  const handleDescargarPDF = async (idDocu, nombre) => {
    const token = sessionStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = nombre;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  // funcion de abrir el pdf no firmado
  const handleOpenPDF = async (idDocu) => {
    setOpenDialog(true);
    const token = sessionStorage.getItem("Token");
    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocu}/preview/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleclosepdf = () => {
    setOpenDialog(false);
    setbase64("");
    setScale(1.3);
  };

  const handleOpenNuevoMetadato = () => {
    // setNewDataMeta
    setDataNewMeta({
      ...dataNewMeta,
      openDialogNewMeta: true,
    });
  };

  const handleOpenForm = async (data, newId) => {
    console.log("datadocm", data);
    setCurrentStep("");
    const documentId = data._id ? data._id : data.id;
    const documentStatusId = data.status.id;
    if (newId) {
      const parseId = Number(newId); // Valor seleccionado para actualizacion de tipo de proceso de firma
      let token = sessionStorage.getItem("Token");
      let endpoint = `${process.env.REACT_APP_URL}api/documentos/${documentId}?documento_types_id=${parseId}`;
      var config = {
        method: "PUT",
        url: endpoint,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };
      setProgrees(true);
      axios(config)
        .then((response) => {
          // receive two parameter endpoint url ,form data
          // refreshTables();
          // Respuesta del servidor
          setProgrees(false);
        })
        .catch((error) => {
          // Error 😨
          console.log(error, "Error =>");
          setProgrees(false);
          if (error.response.data.errors) {
            //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
          } else if (error.response.data.message) {
            openAlert(error.response.data.message);
          } else {
            openAlert("Su documento tiene algun problema");
          }
        });

      if (parseId == 7) {
        //Se eligio Auto Firma
        setidDocumento(documentId);
        setIdStatus(documentStatusId);
        setCurrentStep(1);
        setabState(1);
        setOpenForm(2);
      } else {
        setIdprocces(parseId);
        setidDocumento(documentId);
        setIdStatus(documentStatusId);
        setabState(1);
        await setOpenForm(1);
      }
      handleCloseAssign();
    } else {
      let tipoDocumento = data.type.id;
      if (tipoDocumento == 7) {
        const dataFirma = data.firmantes[0];
        console.log(dataFirma, "dataFirma");
        if (dataFirma.firmas[0] == 0) {
          showNotificacion("No se pueden editar firmantes en autofirma");
        } else {
          setidDocumento(documentId);
          setIdStatus(documentStatusId);
          setIdprocces(tipoDocumento);
          setCurrentStep(2);
          setabState(1);
          setOpenForm(2);
        }
      } else {
        setIdprocces(tipoDocumento);
        if (data.stopped_at) {
          setidDocuDetenido(data._id);
          setabState(1);
          await setOpenForm(3);
        } else {
          setIdStatus(documentStatusId);
          setidDocumento(data._id);
          setabState(1);
          if (tipoDocumento == 7) {
            console.log("auto-firma");
            setOpenForm(2);
          } else {
            await setOpenForm(1);
          }
        }
      }
    }
  };
  const classes = useStyles();
  const handleChangeTabs = (value) => {
    handleChange("eventCard", value);
  };

  const handleOpenDelete = (data) => {
    setidDeleteCDO(data.cdo_contratos_id);
    setidDelete(data._id);
    setopenDelete(true);
  };

  const handleOpenCambiarEmisor = (data) => {
    setNewEmisor(authUser.user.name);
    setDocumentoCambiarEmisor(data);
    setOpenCambiarEmisor(true);
  };

  //LEMP CAMBIAR EMISOR
  const handleCambiarEmisor = () => {
    setProgrees(true);
    console.log(documentoCambiarEmisor);
    let token = sessionStorage.getItem("Token");
    let url = `${process.env.REACT_APP_URL}api/documentos/chown/${documentoCambiarEmisor._id}`;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/documentos/chown/${documentoCambiarEmisor._id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setOpenEmisorCambiado(true);
        docuRegister();
        refreshTables();
      })
      .catch(function (error) {
        alert(error);
      });
    setOpenCambiarEmisor(false);
    setProgrees(false);
  };

  const handleDelete = () => {
    let token = sessionStorage.getItem("Token");
    saveLog(token, "Eliminar " + idDelete);
    saveLogCapture(token, "Eliminar " + idDelete);
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDelete}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("res Delete", response);
        //eliminar contrato CDO
        if (idDeleteCDO) {
          eliminarContratoCDO(idDeleteCDO);
        }
        docuRegister();

        setopenDelete(false);
        refreshTables();
      })
      .catch((error) => {
        // Error 😨

        alert(error.data.message);
      });
    //eliminar contrato CDO
    if (idDeleteCDO) {
      eliminarContratoCDO(idDeleteCDO);
    }
  };

  const handleOpenStop = (Id) => {
    setIdstop(Id);
    setopenStop(true);
  };

  const handleStopApi = () => {
    setDisabledDetenerText(true);
    setdisabledDetener(true);
    console.log("entro", stopCDO);
    if (stopCDO) {
      handleStopApiCDO();
    } else {
      const token = sessionStorage.getItem("Token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: " application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const urlStop = `${process.env.REACT_APP_URL}api/documentos/${idStop}/stop?comment=${comment}`;
      axios
        .put(urlStop, {}, config)
        .then(async (response) => {
          console.log("responseStop", response);
          setopenStop(false);
          docuProceso();
          docuRegister();
          setComment("");
          setDisabledDetenerText(false);
          refreshTables();
        })
        .catch((error) => {
          // Error 😨
          alert(error.message);
          if (error.response) {
            setComment("");
            setDisabledDetenerText(false);
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log(error.config);
        });
    }
  };

  const handleInputComment = (event) => {
    setComment(event.target.value);
  };
  const handleOpenReminder = (Id) => {
    setopenReminder(true);
    setIdSend(Id);
  };

  const handleSendReminder = () => {
    let token = sessionStorage.getItem("Token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlSend = `${process.env.REACT_APP_URL}api/documentos/${idSend}/push`;
    axios
      .put(urlSend, {}, config)
      .then(async (response) => {
        console.log("REMINDER", response);
        setopenReminder(false);
      })
      .catch((error) => {
        // Error 😨
        setopenReminder(false);
        if (error.response) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const handleChangeDataDocuPerson = () => {
    setOpenDialogInfo(false);
    if (docuDataPerson.description !== "") {
      setDescripcion(docuDataPerson.description);
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_start !== "" &&
      docuDataPerson.meta.vigencia_start !== undefined
    ) {
      setDesde(moment(docuDataPerson.meta.vigencia_start).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.vigencia_end !== "" &&
      docuDataPerson.meta.vigencia_end !== undefined
    ) {
      setHasta(moment(docuDataPerson.meta.vigencia_end).format("YYYY-MM-DD"));
    }
    if (
      docuDataPerson.meta &&
      docuDataPerson.meta.dias_alerta !== "" &&
      docuDataPerson.meta.dias_alerta !== undefined
    ) {
      setDiasAlerta(docuDataPerson.meta.dias_alerta);
    }
    setOpenModificar(true);
  };
  const handleCloseArchivo = () => {
    setOpenCarpetas(false);
    docuRegister();
    docuProceso();
    docuFirmados();
    setArraypatch([]);
    docuRechazados();
    datatable();
  };
  const handleCancelarActualizacion = () => {
    setInfoMetadata({});
    setDocuDataPerson([]);
    setOpenModificar(false);
    setDescripcion("");
    setDesde("");
    setHasta("");
    setDiasAlerta(30);
  };
  const handleInserFolder = (dataDocu) => {
    console.log("dataDocu", dataDocu);
    setidDocumento(dataDocu._id);
    setSendFolder(true);
  };

  const handleEliminarFirmante = () => {
    console.log("eliminar CDO:", rowDataFirmante);
    console.log("eliminar CC:", contratosID.cc);
    eliminarFirmanteCDO(rowDataFirmante._id);
    //eliminarFirmanteCC(contratosID.cc, rowDataFirmante.email);
    eliminarFirmanteCC(contratosID.cc, rowDataFirmante.rfc);
    setOpenDialogEliminarFirmante(false);
  };

  const handleCancelarEliminarFirmante = () => {
    setOpenDialogEliminarFirmante(false);
    setrowDataFirmante({});
  };

  const handleConfirmarEliminarFirmante = (rowData) => {
    setOpenDialogEliminarFirmante(true);
    setrowDataFirmante(rowData);
  };
  useEffect(() => {
    if (comment.length >= 5) {
      setdisabledDetener(false);
    } else {
      setdisabledDetener(true);
    }
  }, [comment]);

  useEffect(() => {
    handleInfoUser();
  }, []);

  const handleInfoUser = () => {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/auth/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setAuthUser(data);
        // setContador(res.data.NumDocuments);
        const Roles = res.data.roles;

        dispatch({
          type: "SET_USER",
          payload: res.data.user,
        });
        // dispatch({
        //   type: "SET_DATA_USER",
        //   payload: res.data,
        // });
        dispatch({
          type: "SET_PERMISSIONS",
          payload: res.data.permissions,
        });
        const custom = res.data.user.custom_assets;
        if (custom != null && custom.logo) {
          dispatch({
            type: "SET_LOGO",
            payload: custom.logo,
          });
        } else {
          dispatch({
            type: "SET_LOGO",
            payload: null,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function onChangeAlmacenar() {
    setArchivoAlmacenar(document.querySelector('input[type="file"]').files[0]);
    setOpenAlmacenar(true);
  }
  const handleChangeMetadatos = (atributo, event) => {
    event.preventDefault();
    setInfoMetadata({
      ...infoMetadata,
      [atributo]: event.target.value,
    });
  };

  const isProduction =
    process.env.REACT_APP_PRODUCTION === true ||
    process.env.REACT_APP_PRODUCTION === "true";

  return (
    <div>
      {openForm == 0 ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                position: "relative",
                marginTop: -30,
              }}
            >
              <Fab
                variant="extended"
                color="primary"
                onClick={() => {
                  setOpenCarpetas(true);
                  getCarpetas();
                }}
              >
                <FolderIcon style={{ marginRight: 2 }} />
                Carpetas
              </Fab>
            </div>

            <CustomTabs
              handleChange={handleChange}
              valueState={valueState}
              headerColor={headerColor}
              title=""
              tabs={[
                {
                  tabName: `Documentos (${dashboard.total})`,
                  tabIcon: DescriptionIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Todos los documentos ({dashboard.total})
                      </Typography>
                      <IMaterialTable
                        tableRef={docsTableRef}
                        localization={localization}
                        title=""
                        columns={[
                          {
                            title: "Nombre",
                            field: "filename",
                            render: (row) => (
                              <Tooltip
                                title={row.filename}
                                aria-label={row.filename}
                              >
                                {recortarName(row)}
                              </Tooltip>
                            ),
                          },
                          { title: "Fecha", field: "created_at" },
                          {
                            title: "Estatus del Documento",
                            field: "status.name",
                            render: (row) => (
                              <span>
                                {row.reason && row.documento_status_id !== 4
                                  ? "detenido"
                                  : row.status.name}
                              </span>
                            ),
                          },
                          { title: "Tipo", field: "type.name" },
                          { title: "Clase", field: "clase.name" },

                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },

                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                            render: (row) => aplicaFirmantes(row),
                          },
                          {
                            title: "Fecha Cierta",
                            field: "fcstatus.name",
                            hidden: isProduction,
                          },
                          { title: "ID", field: "_id" },
                        ]}
                        isLoading={docuDataLoading}
                        data={fetchDocumentos}
                        actions={[
                          {
                            icon: () => (
                              <GetAppIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Descargar documento",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = sessionStorage.getItem("Token");
                              saveLog(token, "Descargar " + rowData._id);
                              saveLogCapture(token, "Descargar " + rowData._id);
                              //fin log
                              if (
                                rowData.type.id == 5 ||
                                rowData.status.id === 5
                              ) {
                                console.log("Descargar constanciaCDO");
                                console.log(rowData);
                                console.log(rowData.constancia);

                                //var obj = JSON.parse(rowData.constancia);
                                var obj;
                                if (typeof rowData.constancia === "string") {
                                  obj = JSON.parse(rowData.constancia);
                                } else {
                                  obj = rowData.constancia;
                                }
                                getTotalDownloadPdf(rowData);
                                handleDescargarConstanciaCDO(
                                  obj.descripcion,
                                  obj.id
                                );
                              } else {
                                getTotalDownloadPdf(rowData);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <FolderIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Guardar en Carpeta",
                            onClick: (event, rowData) => {
                              handleInserFolder(rowData);
                              getCarpetas();
                            },
                          },
                          {
                            icon: () => (
                              <VisibilityIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) => {
                              if (rowData.cdo_contratos_id) {
                                handleOpenPDF(rowData._id);
                              } else {
                                getTotalVisualizarPdf(rowData);
                              }
                            },
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmante", rowData);
                              setContratosID({
                                cc: rowData._id,
                                cdo: rowData.cdo_contratos_id,
                              });
                              if (rowData.type.id === 3) {
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                // !ANTERIOR SALVADOR handleOpenTodosFirmantes(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            // disabled: rowData.type.id === 5,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) => {
                              handleOpenInfo(rowData);
                            },
                          }),
                          //permissions.includes("documento.delete") === true
                          (rowData) => ({
                            //LEMP ADJUNTOS
                            icon: () =>
                              rowData.adjuntos.length > 0 ? (
                                <AttachFileIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ) : (
                                <BackupIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                            tooltip: "Adjuntos",
                            //hidden: isProduction,
                            onClick: (event, rowData) => {
                              handleOpenAdjuntos(rowData);
                            },
                          }),
                          //FECHA CIERTA
                          (rowData) => ({
                            icon: () =>
                              rowData.fcstatus == null ? (
                                rowData.documento_status_id == 3 ||
                                  rowData.documento_status_id == 5 ||
                                  rowData.documento_types_id == 11 ? (
                                  <GavelIcon
                                    className={
                                      classes2.tableActionButtonIcon +
                                      " " +
                                      classes2.edit
                                    }
                                  />
                                ) : null
                              ) : rowData.fcstatus.id == 4 ? (
                                <VerifiedUserIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ) : (
                                <WatchLaterIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                            tooltip: "Fecha Cierta",
                            onClick: (event, rowData) => {
                              handleOpenFechaCierta(rowData);
                            },
                            hidden:
                              (rowData.fcstatus == null &&
                                !(
                                  rowData.documento_status_id == 3 ||
                                  rowData.documento_status_id == 5 ||
                                  rowData.documento_types_id == 11
                                )) ||
                              isProduction,
                          }),
                          //Copia Certificada
                          (rowData) => ({
                            icon: () => (
                              <FileCopyIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Copia certificada",
                            onClick: (event, rowData) => {
                              handleOpenCopiaCertificada(rowData);
                            },
                            hidden: rowData.constancia == null,
                          }),
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100],
                          actionsColumnIndex: -1,
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportCsv: exportCsvDocumentos,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `firmados (${dashboard.firmados})`,
                  tabIcon: GestureIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos firmados ({dashboard.firmados})
                      </Typography>
                      <IMaterialTable
                        tableRef={docsFirmadosTableRef}
                        localization={localization}
                        title=""
                        columns={[
                          {
                            title: "Nombre",
                            field: "filename",
                            render: (row) => (
                              <Tooltip
                                title={row.filename}
                                aria-label={row.filename}
                              >
                                {recortarName(row)}
                              </Tooltip>
                            ),
                          },
                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },
                          { title: "ID", field: "_id" },
                        ]}
                        isLoading={docuDataLoading}
                        data={fetchDocumentos}
                        actions={[
                          {
                            icon: () => (
                              <EmailIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Enviar enlace de descarga",
                            onClick: (event, rowData) => {
                              setidDocumento(rowData._id);
                              setOpenConfirmarEnlace(true);
                            },
                          },
                          {
                            icon: () => (
                              <GetAppIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Descargar entregables finales",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = sessionStorage.getItem("Token");
                              saveLog(token, "Descargar " + rowData._id);
                              saveLogCapture(token, "Descargar " + rowData._id);
                              //fin log
                              if (rowData.type.id === 3) {
                                getTotalDownloadPdf(rowData);
                              } else {
                                if (rowData.type.id === 11) {
                                  console.log(rowData);
                                } else {
                                  getZipDocument(rowData._id, rowData.filename);
                                }
                                getZipAnexos(rowData._id, rowData.filename);
                              }
                            },
                          },

                          {
                            icon: () => (
                              <VisibilityIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) => {
                              if (rowData.cdo_contratos_id) {
                                handleOpenPDF(rowData._id);
                              } else {
                                if (rowData.type.id === 11) {
                                  handleOpenPDF(rowData._id);
                                } else {
                                  handleOpenPDFFirmado(rowData._id);
                                }
                              }
                            },
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmante CDO");
                              setContratosID({
                                cc: rowData._id,
                                cdo: rowData.cdo_contratos_id,
                              });
                              if (rowData.type.id === 3) {
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                //anterior salvador handleOpenFirmantes(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                          //permissions.includes("documento.delete") === true
                          false
                            ? (rowData) => ({
                              icon: () => (
                                <DeleteIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                              tooltip: "Eliminar documento",
                              onClick: (event, rowData) => {
                                handleOpenDelete(rowData);
                              },
                            })
                            : "",
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100],
                          actionsColumnIndex: -1,
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportCsv: exportCsvDocumentos,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `En espera (${dashboard.en_proceso})`,
                  tabIcon: PauseIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos en espera ({dashboard.en_proceso})
                      </Typography>
                      <IMaterialTable
                        tableRef={docsEsperaTableRef}
                        localization={localization}
                        title=""
                        columns={[
                          {
                            title: "Nombre",
                            field: "filename",
                            render: (row) => (
                              <Tooltip
                                title={row.filename}
                                aria-label={row.filename}
                              >
                                {recortarName(row)}
                              </Tooltip>
                            ),
                          },
                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },

                          { title: "ID", field: "_id" },
                        ]}
                        isLoading={docuDataLoading}
                        data={fetchDocumentos}
                        actions={[
                          {
                            icon: () => (
                              <EmailIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            hidden: true, // !isProduction,
                            tooltip: "Enviar recordatorio",
                            onClick: (event, rowData) => {
                              if (rowData.type.id === 3) {
                                setRecordatorioCDO(true);
                              } else {
                                handleOpenReminder(rowData._id);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <StopIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Detener proceso",
                            onClick: (event, rowData) => {
                              //Guardar log
                              let token = sessionStorage.getItem("Token");
                              saveLog(token, "Detener " + rowData._id);
                              saveLogCapture(token, "Detener " + rowData._id);
                              //setActividadLog("Detener "+rowData._id);
                              //setlogStored(false);
                              //fin log
                              if (rowData.type.id === 3) {
                                setStopCDO(true);
                                setIdstopCDO(rowData.cdo_contratos_id);
                                handleOpenStop(rowData._id);
                              } else {
                                handleOpenStop(rowData._id);
                              }
                            },
                          },
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              console.log("mostrar firmantes", rowData);
                              setContratosID({
                                cc: rowData._id,
                                cdo: rowData.cdo_contratos_id,
                              });
                              if (rowData.type.id === 3) {
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                                //ANTERIOR SALVADOR openFirmantesProcess(rowData)
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                          //permissions.includes("documento.delete") === true
                          false
                            ? (rowData) => ({
                              icon: () => (
                                <DeleteIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                              tooltip: "Eliminar documento",
                              onClick: (event, rowData) => {
                                handleOpenDelete(rowData);
                              },
                            })
                            : "",
                        ]}
                        options={{
                          pageSize: 20, // make initial page size
                          actionsColumnIndex: -1,
                          pageSizeOptions: [20, 50, 100],
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportCsv: exportCsvDocumentos,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `Rechazados (${dashboard.rechazados})`,
                  tabIcon: CancelIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos rechazados ({dashboard.rechazados})
                      </Typography>
                      <IMaterialTable
                        tableRef={docsRechazadosTableRef}
                        localization={localization}
                        title=""
                        columns={[
                          {
                            title: "Nombre",
                            field: "filename",
                            render: (row) => (
                              <Tooltip
                                title={row.filename}
                                aria-label={row.filename}
                              >
                                {recortarName(row)}
                              </Tooltip>
                            ),
                          },
                          { title: "Fecha", field: "created_at" },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          {
                            title: "Firmantes",
                            field: "firmantes_names",
                          },
                          { title: "ID", field: "_id" },
                        ]}
                        isLoading={docuDataLoading}
                        data={fetchDocumentos}
                        actions={[
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          (rowData) => ({
                            icon: () => (
                              <PeopleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Participantes",
                            onClick: (event, rowData) => {
                              setContratosID({
                                cc: rowData._id,
                                cdo: rowData.cdo_contratos_id,
                              });
                              if (rowData.type.id === 3) {
                                setListaFirmantesCC(rowData.firmantes);
                                bucarFirmantesCDO(rowData.cdo_contratos_id);
                                bucarNotificadosCDO(rowData.cdo_contratos_id);
                                setModalFirmantes(true);
                              } else {
                                //handleOpenFirmantesRechazados(rowData);
                                setTipoDoc(rowData.type.id);
                                setListaFirmantes(rowData.firmantes);
                                setListaNotificados(rowData.notificados);
                                setModalFirmantesCC(true);
                              }
                            },
                            disabled: rowData.birthYear < 2000,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                            disabled: rowData.birthYear < 2000,
                          }),
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100],
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportCsv: exportCsvDocumentos,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  tabName: `Registrados (${dashboard.registrado})`,
                  tabIcon: OpenInBrowserIcon,
                  tabContent: (
                    <div>
                      <Typography
                        variant="h5"
                        style={{ textTransform: "none" }}
                      >
                        Documentos registrados ({dashboard.registrado})
                      </Typography>
                      <IMaterialTable
                        tableRef={docsRegistradosTableRef}
                        localization={localization}
                        title=""
                        columns={[
                          {
                            title: "Nombre",
                            field: "filename",
                            render: (row) => (
                              <Tooltip
                                title={row.filename}
                                aria-label={row.filename}
                              >
                                {recortarName(row)}
                              </Tooltip>
                            ),
                          },
                          {
                            title: "Estatus del Documento",
                            field: "status.name",
                            render: (row) => (
                              <span>
                                {row.reason ? "detenido" : row.status.name}
                              </span>
                            ),
                          },
                          { title: "Tipo", field: "type.name" },
                          {
                            title: "Emisor",
                            field: "uid",
                            render: (row) => findUser(row.uid),
                          },
                          { title: "Fecha", field: "created_at" },
                          { title: "ID", field: "_id" },
                        ]}
                        isLoading={docuDataLoading}
                        data={fetchDocumentos}
                        actions={[
                          {
                            icon: () => (
                              <Visibility
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Visualizar PDF",
                            onClick: (event, rowData) =>
                              handleOpenPDF(rowData._id),
                          },
                          //LEMP CAMBIAR EMISOR
                          {
                            icon: () => (
                              <AccountCircleIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Cambiar emisor ",
                            onClick: (event, rowData) =>
                              handleOpenCambiarEmisor(rowData),
                          },
                          (rowData) => ({
                            icon: () =>
                              rowData.type.id == 11 ? (
                                <PostAddIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ) : (
                                <PeopleIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                />
                              ),
                            tooltip:
                              rowData.type.id == 11
                                ? "Configurar proceso de firma"
                                : rowData.stopped_at
                                  ? "Editar Firmantes"
                                  : "Agregar firmantes",
                            onClick: (event, rowData) => {
                              if (rowData.type.id == 11) {
                                setNewAssign({
                                  ...newAssignProcess,
                                  openNewAssignProcess: true,
                                  rowDataAssign: rowData,
                                });
                              } else {
                                if (rowData.type.id === 3) {
                                  setContratosID({
                                    cc: rowData._id,
                                    cdo: rowData.cdo_contratos_id,
                                  });
                                  //actualizarFirmantes(rowData.cdo_contratos_id);
                                  //setListaFirmantesCC(rowData.firmantes);
                                  bucarFirmantesCDO(rowData.cdo_contratos_id);
                                  //bucarNotificadosCDO(rowData.cdo_contratos_id);
                                  setModalFirmante(true);
                                } else {
                                  handleOpenForm(rowData);
                                }
                              }
                            },
                            disabled: rowData.type.id === 10,
                          }),
                          (rowData) => ({
                            icon: () => (
                              <InfoIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Información del documento",
                            onClick: (event, rowData) =>
                              handleOpenInfo(rowData),
                          }),
                          (rowData) => ({
                            icon: () => (
                              <DeleteIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Eliminar documento",
                            onClick: (event, rowData) => {
                              handleOpenDelete(rowData);
                            },
                            disabled: rowData.type.id === 10,
                          }),
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100],
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportCsv: exportCsvDocumentos,
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      ) : openForm == 1 ? (
        <GridContainer>
          <Components
            setProgrees={setProgrees}
            tabs={tabState}
            idDocumentotable={idDocumento}
            setOpenForm={setOpenForm}
            idprocces={idprocces}
            idStatus={idStatus}
          />
        </GridContainer>
      ) : openForm == 2 ? (
        <ContainerAutoSign
          setProgrees={setProgrees}
          tabs={tabState}
          idprocces={idprocces}
          idDocumentotable={idDocumento}
          setOpenForm={setOpenForm}
          idStatus={idStatus}
          currentStep={currentStep}
        />
      ) : openForm == 3 ? (
        <Components
          setProgrees={setProgrees}
          tabs={tabState}
          idDocumentoDetenido={idDocuDetenido}
          setOpenForm={setOpenForm}
          idprocces={idprocces}
          idStatus={idStatus}
        />
      ) : (
        ""
      )}
      {/* Dialogo de espera de descarga de paquete de documentos firmados */}
      <DialogComp
        open={openZip}
        title="Descarga entregables finales"
        content=" La descarga se realizará en el lugar habitual de descargas. Es posible que esta acción tarde unos segundos."
        grid={true}
      />

      {/* Dialogo de ver datos de los firmantes */}
      <DialogComp
        open={openDialogFirmantes}
        title="Informacion de los firmantes"
        content={
          <div>
            <Table
              tableHeaderColor="success"
              tableHead={["Nombre", "Apellidos", "Actuación", "Firma"]}
              tableData={firmantes}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseFirmante,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* Dialogo de ver datos de los firmantes rechazados */}
      <DialogComp
        open={openFirmantesRechazados}
        onClose={() => setopenFirmantesRechazados(false)}
        title="Informacion de los firmantes"
        content={
          <div>
            <Table
              tableHeaderColor="success"
              tableHead={["Nombre", "Apellidos", "Actuación", "Comentario"]}
              tableData={firmantes}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setopenFirmantesRechazados(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* Dialogo de ver datos del documento info */}

      <DialogComp
        open={openDialogInfo}
        onClose={() => setopenFirmantesRechazados(false)}
        title="INFORMACIÓN DEL DOCUMENTO"
        content={
          <div>
            <b>Nombre del documento:</b> {docuDataPerson.filename}
            <br></br>
            <hr></hr>
            <b>Compañía emisora:</b>{" "}
            {docuDataPerson.corporacion != null
              ? docuDataPerson.corporacion.name
              : ""}
            <br></br>
            <hr></hr>
            {docuDataPerson.description ? (
              <React.Fragment>
                <b>Descripción:</b> {docuDataPerson.description}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Descripción:</b> {""}
                <br></br>
                <hr></hr>
              </React.Fragment>
            )}
            {docuDataPerson.reason ? (
              <React.Fragment>
                <b>Fecha de detención:</b>{" "}
                {moment(docuDataPerson.stopped_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
                <b>Motivo:</b> {docuDataPerson.reason}
                <br></br>
                <hr></hr>
              </React.Fragment>
            ) : null}
            <b>Hash:</b> {docuDataPerson.hash}
            <br></br>
            <hr></hr>
            <b>ID el documento:</b> {docuDataPerson._id}
            <br></br>
            <hr></hr>
            <b>Carpeta:</b> {docuDataPerson.folder}
            <br></br>
            <hr></hr>
            <b>Tamaño de documento:</b> {docuDataPerson.size}
            <br></br>
            <hr></hr>
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_start ? (
              <React.Fragment>
                <b>Inicio de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_start).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Inicio de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.vigencia_end ? (
              <React.Fragment>
                <b>Término de Vigencia:</b>{" "}
                {moment(docuDataPerson.meta.vigencia_end).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Término de Vigencia:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.dias_alerta ? (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> {docuDataPerson.meta.dias_alerta}{" "}
                días
                <br></br>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b>Alerta de Vencimiento:</b> <br></br>
              </React.Fragment>
            )}
            {docuDataPerson.meta && docuDataPerson.meta.info ? (
              <React.Fragment>
                <hr />
                <font color="black">
                  <b>Campos adicionales:</b>
                </font>
                <hr />
                <ShowMeta json={docuDataPerson.meta.info} />
              </React.Fragment>
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: handleCloseInfo,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleChangeDataDocuPerson,
            buttonName: "Modificar",
            buttonColor: "primary",
          },
        ]}
      />
      {/* Dialogo de para ver los pdf */}
      <DialogComp
        open={openDialog}
        onClose={handleclosepdf}
        title="Tu documento"
        maxWidth={"md"}
        content={
          base64.length === 0 ? (
            <Backdrop className={classespro.backdrop} open={open}>
              <CircularProgress
                size={44}
                className={classespro.buttonProgress}
              />
            </Backdrop>
          ) : (
            <div align="center">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                file={`data: application / pdf; base64, ${base64} `}
              />
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data: application / pdf; base64, ${base64} `}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>

              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          )
        }
        buttons={[
          {
            buttonEvent: handleclosepdf,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* Dialogo para eliminar el documento  */}
      <DialogComp
        open={openDelete}
        onClose={() => setopenDelete(false)}
        title="Eliminar documento"
        content="¿Está seguro de eliminar este documento?"
        buttons={[
          {
            buttonEvent: () => setopenDelete(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleDelete,
            buttonName: "Eliminar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* Dialogo para LEMP CAMBIAR EMISOR  */}
      <DialogComp
        open={openCambiarEmisor}
        onClose={() => setOpenCambiarEmisor(false)}
        title="Cambiar emisor"
        content={
          <>
            <p align="center">
              ¿Está seguro de cambiar el emisor de este documento?
            </p>
            <hr />
            <p align="center">
              <b>Emisor actual:</b> {findUser(documentoCambiarEmisor.uid)}
            </p>
            <p align="center">
              <b>Nuevo emisor:</b> {newEmisor}
            </p>
            <hr />
          </>
        }
        buttons={[
          {
            buttonEvent: () => setOpenCambiarEmisor(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleCambiarEmisor,
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* Dialogo para LEMP CAMBIAR EMISOR  */}
      <DialogComp
        open={openEmisorCambiado}
        onClose={() => setOpenEmisorCambiado(false)}
        title="El emisor ha sido cambiado"
        content={
          <>
            <p align="center">
              ¿Desea proceder con la configuración proceso de firma?
            </p>
          </>
        }
        buttons={[
          {
            buttonEvent: () => setOpenEmisorCambiado(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => {
              if (documentoCambiarEmisor.type.id == 11) {
                setNewAssign({
                  ...newAssignProcess,
                  openNewAssignProcess: true,
                  rowDataAssign: documentoCambiarEmisor,
                });
              } else {
                handleOpenForm(documentoCambiarEmisor);
              }
              setOpenEmisorCambiado(false);
            },
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* Dialogo para modificar metadata  */}
      <DialogComp
        open={openModificar}
        onClose={() => setopenDelete(false)}
        title="Modificar datos"
        content={
          <>
            <Container maxWidth="xs">
              <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                <Paper elevation={3} style={{ padding: 20 }}>
                  <Grid container>
                    <Grid item xs={6} sm={12}>
                      <p align="center">
                        <b>Información general del documento</b>
                      </p>
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      Descripción:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        inputProps={{
                          maxlength: 360,
                        }}
                        placeholder=""
                        helperText={descripcion && `${descripcion.length}/360`}
                        value={descripcion}
                        //style={{ width: "100%" }}
                        onChange={(e) => {
                          setDescripcion(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Inicio vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateDesde"
                        type="date"
                        value={desde}
                        onChange={(e) => {
                          //setInicio(e.target.value);
                          setDesde(e.target.value);

                          if (e.target.value > hasta) {
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          // var f = new Date();

                          // var hoy = new Date("Y-m-d");
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de inicio es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      Término vigencia:
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="dateHasta"
                        type="date"
                        value={hasta}
                        onChange={(e) => {
                          setHasta(e.target.value);

                          if (e.target.value < desde) {
                            //alert("La fecha de término debe ser superior a la fecha de inicio")
                            setAlerta(
                              "La fecha de término debe ser superior a la fecha de inicio"
                            );
                          } else {
                            setAlerta("");
                          }
                          var date = new Date(e.target.value);
                          if (date.getFullYear() > 2300) {
                            showNotificacion("La fecha de término es inválida");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {alerta ? (
                      <Grid item xs={12} sm={12}>
                        <font size="2" color="red">
                          <b>{alerta}</b>
                        </font>
                      </Grid>
                    ) : null}

                    {hasta ? (
                      <React.Fragment>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                        // className={classes.textodiaslabel}
                        >
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeBox}
                            color="default"
                          //inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <font size="2" color="green">
                            Alerta de vencimiento:{" "}
                          </font>{" "}
                          <TextField
                            //variant="outlined"
                            id="standard-number"
                            type="number"
                            value={diasAlerta}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: 1,
                              max: 90,
                            }}
                            size="small"
                            onChange={(e) => {
                              setDiasAlerta(e.target.value);
                              setDiasAlert(e.target.value);
                              if (e.target.value < 2 || e.target.value > 90) {
                                showNotificacion(
                                  "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                                );
                                setDiasAlerta(30);
                                setDiasAlert(30);
                              }
                            }}
                          />{" "}
                          <font
                            size="2"
                            color="green"
                            styles={{ padding: "10%" }}
                          >
                            {" "}
                            días
                          </font>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                    {infoMetadata ? (
                      <>
                        <br />
                        {/* <br /> */}
                        <Grid item xs={6} sm={12}>
                          <p align="center">
                            <b>Campos adicionales</b>
                          </p>
                          <hr />
                        </Grid>
                        <Grid item xs={6} sm={12}>
                          <div align="center">
                            <Tooltip
                              title="Agregar campo adicional"
                              placement="bottom"
                            >
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={handleOpenNuevoMetadato}
                              >
                                <AddIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                          <br />
                        </Grid>

                        <Grid item xs={6} sm={12}>
                          {Object.keys(infoMetadata).map((atributo, index) => (
                            <>
                              <TextField
                                // fullWidth
                                size="small"
                                margin="dense"
                                key={index}
                                label={atributo}
                                value={infoMetadata[atributo]}
                                style={{
                                  marginLeft: "8%",
                                }}
                                onChange={(e) => {
                                  handleChangeMetadatos(atributo, e);
                                }}
                                name="name"
                                id={index.toString()}
                                variant="outlined"
                              />
                              <Tooltip
                                title="Eliminar campo adicional"
                                placement="top"
                              >
                                <IconButton
                                  style={{
                                    marginTop: "4%",
                                    marginLeft: "8%",
                                  }}
                                  color="secondary"
                                  aria-label="Eliminar"
                                  size="small"
                                  onClick={() => {
                                    handleConfirmaEliminarMeta(atributo);
                                  }}
                                >
                                  <HighlightOffIcon
                                    fontSize="medium"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          ))}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Paper>
              </Box>
            </Container>
          </>
        }
        buttons={[
          {
            buttonEvent: handleCancelarActualizacion,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: handleModificar,
            buttonName: "Guardar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* Dialog agregar metadata */}
      <DialogComp
        open={openDialogNewMeta}
        maxWidth={"md"}
        title="Agregar campo"
        content={
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="key"
                name="nombreNewMeta"
                label="Nombre del campo"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                value={nombreNewMeta}
                onChange={(e) => {
                  handleChangeNewMetadata(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="value"
                label="Contenido"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxlength: 128,
                }}
                name="valorNewMeta"
                value={valorNewMeta}
                onChange={(e) => {
                  handleChangeNewMetadata(e);
                }}
              />
            </Grid>
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleAgregarNuevoMetadato,
            buttonDisabled: nombreNewMeta && valorNewMeta ? false : true,
            buttonName: "Agregar",
            buttonColor: "primary",
          },
          {
            buttonEvent: handleCancelarNuevoMeta,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      <DialogComp
        open={openStop}
        title="Escriba el motivo por el cual quiere detener el proceso"
        // onClose={handleCloseCDO}
        content={
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              id="comment"
              disabled={disabledDetenerText}
              label="Comentario"
              multiline
              rows={6}
              value={comment}
              variant="outlined"
              onChange={handleInputComment}
              inputProps={{ maxLength: 128 }}
            />
          </Grid>
        }
        buttons={[
          {
            buttonEvent: handleCloseCDO,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonDisabled: disabledDetener,
            buttonEvent: () => handleStopApi(),
            buttonName: "Detener",
            buttonColor: "primary",
          },
        ]}
      />

      {/* Dialogo para reenviar notificacion*/}

      <DialogComp
        open={openReminder}
        title="Reenviar notificacion"
        onClose={() => setopenDelete(false)}
        content=" El sistema reenviara un correo con la solicitud para firmar o
                confirmar segun sea el caso, al firmante en turno"
        buttons={[
          {
            buttonEvent: () => handleSendReminder(),
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />

      {/*MODAL AGREGAR FIRMANTE*/}
      <DialogComp
        open={modalFirmante}
        fullScreen={false}
        fullWidth={true}
        onClose={() => {
          setModalFirmante(false);
        }}
        title="AGREGAR FIRMANTE"
        content={
          <>
            <Firmantes
              bucarFirmantesCDO={bucarFirmantesCDO}
              setEnviar={setEnviar}
              contrato={contratosID.cdo}
              contratoCC={contratosID.cc}
            />
            <hr />
            {enviar === false ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<PlayCircleFilledWhiteIcon />}
                onClick={() => {
                  console.log(contratosID);
                  activarProcesoCDO(contratosID.cdo);
                  setModalFirmante(false);
                }}
              >
                Mandar a Firmar
              </Button>
            ) : null}

            {/* ESTO ES UNA PRUEBA*/}
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  exportButton: false,
                  search: false,
                  headerStyle: {
                    backgroundColor: "#009cde",
                    color: "#FFF",
                  },
                  pageSize: 20, // make initial page size
                  pageSizeOptions: [20, 50, 100],
                  actionsColumnIndex: -1,
                }}
                columns={[
                  { title: "NOMBRE Y APELLIDOS", field: "nombre" },
                  { title: "CORREO", field: "email" },
                  { title: "RFC", field: "rfc" },
                ]}
                //data={listaFirmantesCDO}
                data={listaFirmantes}
                title={"Firmantes"}
                actions={[
                  {
                    icon: () => (
                      <DeleteIcon
                        className={
                          classes2.tableActionButtonIcon + " " + classes2.edit
                        }
                      />
                    ),
                    tooltip: "Eliminar firmante",
                    onClick: (event, rowData) => {
                      handleConfirmarEliminarFirmante(rowData);
                    },
                  },
                ]}
              />
            ) : null}
          </>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmante(false),
            buttonName: "Aceptar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/*FIN DIALOG AGREGAR FIRMANTE*/}
      {/*MODAL MOSTRAR FIRMANTES CDO*/}

      <DialogComp
        open={modalFirmantes}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantes(false);
        }}
        title="INFORMACIÓN DE PARTICIPANTES"
        content={
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre y Apellidos", field: "nombre" },
                  { title: "RFC", field: "rfc" },
                  {
                    title: "Compañía",
                    field: "email",
                    render: (row) => <span>{companyCDO(row["email"])}</span>,
                  },
                  {
                    title: "Medio de Notificación",
                    field: "email",
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "firmo",
                    render: (row) => <span>{estatusCDO(row)}</span>,
                  },
                  {
                    title: "Registrado",
                    field: "fecha_registro",
                    render: (row) => (
                      <span>
                        {row["fecha_registro"]
                          ? moment(row["fecha_registro"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "fecha_inicio",
                    render: (row) => (
                      <span>
                        {row["fecha_inicio"]
                          ? moment(row["fecha_inicio"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "fecha_firma",
                    render: (row) => (
                      <span>
                        {row["fecha_firma"]
                          ? moment(row["fecha_firma"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "firmo",
                    render: (row) => <span>{row.motivo_rechazo}</span>,
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "nombre" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmantes(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* <Dialog
        open={modalFirmantes}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantes(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre y Apellidos", field: "nombre" },
                  { title: "RFC", field: "rfc" },
                  {
                    title: "Compañía",
                    field: "email",
                    render: (row) => <span>{companyCDO(row["email"])}</span>,
                  },
                  {
                    title: "Medio de Notificación",
                    field: "email",
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "firmo",
                    render: (row) => <span>{estatusCDO(row)}</span>,
                  },
                  {
                    title: "Registrado",
                    field: "fecha_registro",
                    render: (row) => (
                      <span>
                        {row["fecha_registro"]
                          ? moment(row["fecha_registro"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "fecha_inicio",
                    render: (row) => (
                      <span>
                        {row["fecha_inicio"]
                          ? moment(row["fecha_inicio"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "fecha_aceptacion",
                    render: (row) => (
                      <span>
                        {row["fecha_aceptacion"]
                          ? moment(row["fecha_aceptacion"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "fecha_firma",
                    render: (row) => (
                      <span>
                        {row["fecha_firma"]
                          ? moment(row["fecha_firma"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "firmo",
                    render: (row) => <span>{row.motivo_rechazo}</span>,
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "nombre" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {"Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setModalFirmantes(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*FIN DIALOG MOSTRAR FIRMANTES CDO*/}
      {/*MODAL MOSTRAR FIRMANTES CC*/}

      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      <DialogComp
        open={modalFirmantesCC}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantesCC(false);
        }}
        title="INFORMACIÓN DE PARTICIPANTES"
        content={
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  {
                    title: "Nombres",
                    field: "name",
                    render: (row) => (
                      <span>{row["name"] + " " + row["last_name"]}</span>
                    ),
                  },
                  { title: "RFC firmante", field: "rfc" },
                  { title: "RFC empresa", field: "rfc_empresa" },
                  {
                    title: "Prueba de vida",
                    field: "speech_required",
                    render: (row) => (
                      <span>
                        {row["speech_required"] == true ? (
                          <Tooltip
                            id="tooltip-top"
                            title="Se solicito prueba de vida"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <RecordVoiceOverIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          "No se solicitó"
                        )}
                      </span>
                    ),
                  },
                  { title: "Compañía", field: "company" },
                  {
                    title: "Medio de Notificación",
                    field: "notification_types_id",
                    render: (row) => (
                      <span>
                        {row["notification_types_id"] === 1
                          ? row["email"]
                          : row["phone_country_code"] + row["phone"]}
                      </span>
                    ),
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["refused_comment"] && row["refused_comment"] !== ""
                          ? "Rechazó"
                          : row["role"] === "Notificado"
                            ? "Notificado"
                            : row["date_signed"]
                              ? "Firmó"
                              : row["firmas"] && row["firmas"].length > 0
                                ? "Pendiente de confirmar código"
                                : "Pendiente de Firma"}
                      </span>
                    ),
                  },
                  {
                    title: "Registrado",
                    field: "created_at",
                    render: (row) => (
                      <span>
                        {row["created_at"]
                          ? moment(row["created_at"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "date_notified",
                    render: (row) => (
                      <span>
                        {row["date_notified"]
                          ? moment(row["date_notified"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["date_signed"]
                          ? moment(row["date_signed"]).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Comentarios",
                    field: "refused_comment",
                  },

                  {
                    title: "",
                    field: "",
                    render: (row) => {
                      const hideShare =
                        ((row.date_signed && row.date_signed.length > 0) ||
                          contratosID.documento_types_id == 1
                          ? true
                          : false) ||
                        !row.date_notified ||
                        row.date_notified.length <= 0;
                      const hideEdit =
                        (row.date_signed && row.date_signed.length > 0);
                      const isEmail = row["notification_types_id"] === 1;
                      const medioNotificacionTxt = isEmail
                        ? row["email"]
                        : row["phone_country_code"] + row["phone"];
                      return (
                        <div className="d-flex-center">
                          <div style={{ display: hideEdit ? "none" : "block" }}>
                            <Tooltip
                              id="tooltip-top"
                              title="Editar medio de notificación"
                              placement="top"
                              classes={{
                                tooltip: classes2.tooltip,
                              }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes2.tableActionButton}
                                onClick={() => {
                                  const phone = row.phone ? row.phone : "";
                                  const phone_country_code = row.phone_country_code
                                    ? row.phone_country_code
                                    : "52";
                                  setFrmMedioNotificacion({
                                    idFirmante: row._id,
                                    email: row["email"],
                                    old_email: row["email"],
                                    old_phone: phone,
                                    phone_country_code,
                                    phone,
                                    name: row.name,
                                    last_name: row.last_name,
                                    notification_types_id: row.notification_types_id.toString(),
                                    speech_required: row.speech_required,
                                    cert_required: row.cert_required,
                                    rfc: row.rfc,
                                    rfc_empresa: row.rfc_empresa,
                                    isPhone: isEmail === false,
                                    date_notified: row.date_notified,
                                  });
                                  setShowEditarMedioNotificacion(true);
                                }}
                              >
                                <EditOutlined
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                ></EditOutlined>
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div
                            style={{
                              display: hideShare ? "none" : "block",
                              marginLeft: "1rem",
                            }}
                          >
                            <Tooltip
                              id="tooltip-top"
                              title="Enviar recordatorio"
                              placement="top"
                              classes={{
                                tooltip: classes2.tooltip,
                              }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes2.tableActionButton}
                                onClick={async () => {
                                  setProgrees(true);
                                  try {
                                    setMostrarNotifFirmante(false);
                                    await enviarRecordatorioFirmante(
                                      row,
                                      contratosID.cc
                                    );
                                    // actualizar listaFirmantes
                                    setListaFirmantes(listaFirmantes);
                                    setMostrarNotifFirmante(true);
                                  } catch (e) {
                                  } finally {
                                    setProgrees(false);
                                  }
                                }}
                              >
                                <EmailIcon
                                  className={
                                    classes2.tableActionButtonIcon +
                                    " " +
                                    classes2.edit
                                  }
                                ></EmailIcon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
                data={listaFirmantes}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Notificados" : "Firmantes"}
                  </Typography>
                }
              />
            ) : null}
            <hr />
            {listaNotificados ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportAllData: true,
                }}
                columns={[
                  { title: "Nombre", field: "name" },
                  { title: "Apellido", field: "last_name" },
                  { title: "Email", field: "email" },
                ]}
                data={listaNotificados}
                title={
                  <Typography variant="h5" style={{ textTransform: "none" }}>
                    {tipoDoc === 6 ? "Observadores" : "Notificados"}
                  </Typography>
                }
              />
            ) : null}
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setModalFirmantesCC(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* DIALOGO PARA CONFIRMACION DE ELIMINACION DE FIRMANTE*/}
      <DialogComp
        open={openDialogEliminarFirmante}
        title={"¿Eliminar Firmante?"}
        content="Se eliminará al firmante de la lista"
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => handleEliminarFirmante(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
          {
            buttonEvent: () => handleCancelarEliminarFirmante(),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/*DIALOGO RECORDTORIO PARA CDO*/}
      <DialogComp
        open={recordatorioCDO}
        title="Reenviar notificacion"
        content=" El sistema reenviará un correo con la solicitud para firmar el
              contrato dentro de las próximas 24Hrs."
        buttons={[
          {
            buttonEvent: () => setRecordatorioCDO(false),
            buttonName: "Ceptar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA ABRIR LA RUTAS DEL ARCHIVO */}
      <DialogComp
        open={sendFolder}
        fullWidth={true}
        maxWidth={"md"}
        title="Selecciona donde quieres ubicar el documento"
        content={
          <VistaArchivos
            setSendFolder={setSendFolder}
            idDocumento={idDocumento}
            dataCarpetas={dataCarpetas}
          />
        }
        buttons={[
          {
            buttonEvent: () => {
              setSendFolder(false);
              setArraypatch([]);
            },

            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA ABRIR EL COMPONENTE DE LOS ARCHIVOS*/}
      <DialogComp
        open={openCarpetas}
        title={""}
        fullScreen={true}
        fullWidth={true}
        maxWidth={"lg"}
        content={
          <div>
            <Archivos
              setidDocuDetenido={setidDocuDetenido}
              handleInserFolder={handleInserFolder}
              setOpenForm={setOpenForm}
              setIdStatus={setIdStatus}
              setidDocumento={setidDocumento}
              setabState={setabState}
              setIdprocces={setIdprocces}
              refreshTables={refreshTables}
              handleCloseArchivo={handleCloseArchivo}
              setNewAssign={setNewAssign}
              openNewAssignProcess={openNewAssignProcess}
              newAssignProcess={newAssignProcess}
              cerrarCarpetas={setOpenCarpetas}
              sendFolder2={sendFolder}
              dataCarpetas={dataCarpetas}
              arrayPatch={arrayPatch}
              setArraypatch={setArraypatch}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => handleCloseArchivo(),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* DIALOGO PARA CONFIRMACION DE ELIMINACION DE FIRMANTE*/}
      <DialogComp
        open={openDialogEliminarFirmante}
        title={"¿Eliminar Firmante?"}
        content="Se eliminará al firmante de la lista"
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => handleEliminarFirmante(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
          {
            buttonEvent: () => handleCancelarEliminarFirmante(),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* DIALOGO PARA CONFIRMACION DE ELIMINACION DE METADATO*/}
      <DialogComp
        open={openConfirmarDeleteMeta}
        title={"¿Eliminar campo adicional?"}
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => handleCancelarEliminarMeta(),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => handleEliminarMetadato(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* // Dialogo para asiganr nuevo proceso de firmaa un documento almacenado */}

      <DialogComp
        open={openNewAssignProcess}
        title={"Seleccione el proceso de firma "}
        content={
          <>
            <List className={classes.root}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={valueAssignNew}
                  onChange={handleRadioChange}
                >
                  <ListItem>
                    <FormControlLabel value="1" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={firmasimple}
                          alt="Con-Certeza"
                          width="65"
                          height="65"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <Tooltip
                      placement="top-start"
                      title="Solicita que el firmante confirme el proceso con un código de validación."
                    >
                      <ListItemText primary="FIRMA SIMPLE CON CONFIRMACIÓN POR CÓDIGO" />
                    </Tooltip>
                  </ListItem>
                  <ListItem>
                    <FormControlLabel value="2" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={firmacerti}
                          alt="Con-Certeza"
                          width="65"
                          height="63"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <Tooltip
                      placement="top-start"
                      title="Solicita que el firmante adjunte su identificación oficial durante el proceso de firma."
                    >
                      <ListItemText primary="FIRMA SIMPLE CON IDENTIFICACIÓN" />
                    </Tooltip>
                  </ListItem>
                  <ListItem>
                    <FormControlLabel value="7" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={AUTOFIRMA}
                          alt="Con-Certeza"
                          width="65"
                          height="63"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <Tooltip
                      placement="top-start"
                      title="Emite y firma tus propios documentos."
                    >
                      <ListItemText primary="AUTOFIRMA" />
                    </Tooltip>
                  </ListItem>
                  <ListItem>
                    <FormControlLabel value="8" control={<Radio />} />
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={FIRMARAPIDA}
                          alt="Con-Certeza"
                          width="67"
                          height="65"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <Tooltip
                      placement="top-start"
                      title="Solicita que el firmante confirme el proceso completando una casilla de verificación."
                    >
                      <ListItemText primary="FIRMA SIMPLE CONFIRMACIÓN CON CASILLA DE VERIFICACIÓN" />
                    </Tooltip>
                  </ListItem>
                </RadioGroup>
              </FormControl>
            </List>
          </>
        }
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: handleCloseAssign,
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonDisabled: disabledAssign,
            buttonEvent: handleChangeProcess,
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
        ]}
      />

      <DialogComp
        open={progrees}
        // title={"¿Eliminar metadato?"}
        bground={true}
        maxWidth={"sm"}
        content={
          <Backdrop className={classes.backdrop} open={progrees}>
            <CircularProgress size={44} className={classes.buttonProgress} />
          </Backdrop>
        }
      />
      {/* <Dialog
        open={recordatorioCDO}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Reenviar notificacion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="subtitle1" style={{ textTransform: "none" }}>
              El sistema reenviará un correo con la solicitud para firmar el
              contrato dentro de las próximas 24Hrs.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRecordatorioCDO(false)} color="succes">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*
        (logStored) ? null : (
          <StoreLog
            actividad={actividadLog}
            setlogStored={setlogStored}
            logStored={logStored}
          />
        )
        */}

      {/*DIALOGO PARA ABRIR EL COMPONENTE DE LEMP ADJUNTOS*/}
      <DialogComp
        open={openDialogAdjuntos}
        title={""}
        fullWidth={true}
        maxWidth={"lg"}
        content={
          <Adjuntos documento={docuDataPerson} refreshTables={refreshTables} />
        }
        buttons={[
          {
            buttonEvent: () => handleCloseAdjuntos(),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/*DIALOGO PARA ABRIR EL COMPONENTE DE FECHA CIERTA*/}
      <DialogComp
        open={openDialogFechaCierta}
        title={""}
        fullWidth={true}
        maxWidth={"sm"}
        content={
          <FechaCierta
            documento={docuDataPerson}
            refreshTables={refreshTables}
            handleCloseFechaCierta={handleCloseFechaCierta}
          />
        }
        buttons={[
          {
            buttonEvent: () => handleCloseFechaCierta(),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      {/* DIALOGO PARA CONFIRMACION DE ENVÍO DE ENLACE*/}
      <DialogComp
        open={openConfirmarEnlace}
        title={"¿Reenviar enlace de descarga?"}
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => setOpenConfirmarEnlace(false),
            buttonName: "Cancelar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => handleEnviarEnlace(),
            buttonName: "Confirmar",
            buttonColor: "primary",
          },
        ]}
      />

      {/* DIALOGO INFORMAR ENLACE ENVIADO*/}
      <DialogComp
        open={openEnlaceEnviado}
        title={"Enlace de descarga enviado con éxito."}
        maxWidth={"sm"}
        buttons={[
          {
            buttonEvent: () => setOpenEnlaceEnviado(false),
            buttonName: "Aceptar",
            buttonColor: "primary",
          },
        ]}
      />

      <EditarMedioNotificacion
        show={showEditarMedioNotificacion}
        form={frmMedioNotificacion}
        onHide={() => {
          setShowEditarMedioNotificacion(false);
        }}
        onSubmit={handleFrmMedioNotificacion}
      />

      {/* copiaCertificada */}
      <DialogComp
        open={openCopiaCer}
        title={"Solicitar copia certificada"}
        fullWidth={true}
        maxWidth={"sm"}
        content={
          <CopiaCertificada
            idDocumento={idDocumento}
            handleCloseCopiaCer={handleCloseCopiaCer}
          />
        }
        buttons={[
          {
            buttonEvent: () => handleCloseCopiaCer(),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />

      <Snackbar
        key="alertClipboardFirmado"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        open={mostrarAlertaCopiadoUrl === true}
        onClose={() => {
          setMostrarAlertaCopiadoUrl(false);
        }}
      >
        <Alert
          onClose={() => setMostrarAlertaCopiadoUrl(false)}
          severity="success"
        >
          Se ha copiado la liga de firmado al portapapeles.
        </Alert>
      </Snackbar>
      <Snackbar
        key="alerNotifFirmante"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        open={mostrarNotifFirmante === true}
        onClose={() => {
          setMostrarNotifFirmante(false);
        }}
      >
        <Alert
          onClose={() => setMostrarNotifFirmante(false)}
          severity="success"
        >
          {notificadoFirmante === true
            ? "Se ha notificado al firmante"
            : "Se ha modificado los datos del firmante con éxito"}
        </Alert>
      </Snackbar>
    </div>
  );
}
